import { legacy_createStore as createStore, applyMiddleware } from "redux";

import rootReducer from "../state/reducers/rootReducer";
import thunk from "redux-thunk";

import { composeWithDevTools } from "@redux-devtools/extension";

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsDenylist, actionsCreators and other options
});
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
