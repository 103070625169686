import TASK_ACTION_TYPES from "../actionTypes/taskActionTypes";

const initialState = {
  isLoading: false,
  taskState: [],
  taskErrorState: "",
};

const TASK_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case TASK_ACTION_TYPES.CREATE_TASK_SUCCESS:
      return {
        ...state,
      };
    // case TASK_ACTION_TYPES.CREATE_TASK_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case TASK_ACTION_TYPES.CREATE_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case TASK_ACTION_TYPES.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        taskState: state.taskState.map((tsk) =>
          tsk.id === action.payload ? action.payload : tsk
        ),
      };
    case TASK_ACTION_TYPES.UPDATE_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case TASK_ACTION_TYPES.DELETE_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.DELETE_TASK_SUCCESS:
      return {
        ...state,
      };
    case TASK_ACTION_TYPES.DELETE_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case TASK_ACTION_TYPES.GET_ALL_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.GET_ALL_TASK_SUCCESS:
      return { ...state, taskState: [...action.payload] };
    case TASK_ACTION_TYPES.GET_ALL_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case TASK_ACTION_TYPES.GET_BY_ID_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.GET_BY_ID_TASK_SUCCESS:
      return {
        ...state,
        taskState: action.payload,
      };
    case TASK_ACTION_TYPES.GET_BY_ID_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case TASK_ACTION_TYPES.COMPLETE_DELETE_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.COMPLETE_DELETE_TASK_SUCCESS:
      return {
        ...state,
      };
    case TASK_ACTION_TYPES.COMPLETE_DELETE_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case TASK_ACTION_TYPES.EMPTY_TRASH_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.EMPTY_TRASH_TASK_SUCCESS:
      return {
        ...state,
      };
    case TASK_ACTION_TYPES.EMPTY_TRASH_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case TASK_ACTION_TYPES.EXPORT_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.EXPORT_TASK_SUCCESS:
      return {
        ...state,
        // taskState: action.payload,
      };
    case TASK_ACTION_TYPES.EXPORT_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case TASK_ACTION_TYPES.RECOVER_ALL_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.RECOVER_ALL_TASK_SUCCESS:
      return {
        ...state,
      };
    case TASK_ACTION_TYPES.RECOVER_ALL_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_TASK_BY_ID***********************************************/
    // case TASK_ACTION_TYPES.RECOVER_TASK_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.RECOVER_TASK_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case TASK_ACTION_TYPES.RECOVER_TASK_BY_ID_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************RECOVER_TASK_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case TASK_ACTION_TYPES.TRASH_ALL_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.TRASH_ALL_TASK_SUCCESS:
      return { ...state };
    case TASK_ACTION_TYPES.TRASH_ALL_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case TASK_ACTION_TYPES.GET_ALL_TRASHED_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.GET_ALL_TRASHED_TASK_SUCCESS:
      return {
        ...state,
        taskState: [...action.payload],
      };
    case TASK_ACTION_TYPES.GET_ALL_TRASHED_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TASK_ACTION_TYPES.ARCHIVE_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.ARCHIVE_TASK_SUCCESS:
      return {
        ...state,
      };
    case TASK_ACTION_TYPES.ARCHIVE_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TASK_ACTION_TYPES.GET_ALL_ARCHIVED_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.GET_ALL_ARCHIVED_TASK_SUCCESS:
      return {
        ...state,
        taskState: [...action.payload],
      };
    case TASK_ACTION_TYPES.GET_ALL_ARCHIVED_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case TASK_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TASK_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TASK_SUCCESS:
      return {
        ...state,
        // taskState: [...action.payload],
      };
    case TASK_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TASK_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case TASK_ACTION_TYPES.RECOVER_ARCHIVED_TASK_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TASK_ACTION_TYPES.RECOVER_ARCHIVED_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        taskState: [...action.payload],
      };
    case TASK_ACTION_TYPES.RECOVER_ARCHIVED_TASK_BY_ID_FAILURE:
      return {
        taskErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default TASK_REDUCER;
