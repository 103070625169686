const REGION_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_REGION_REQUEST: "GET_ALL_REGION_REQUEST",
  GET_ALL_REGION_SUCCESS: "GET_ALL_REGION_SUCCESS",
  GET_ALL_REGION_FAILURE: "GET_ALL_REGION_FAILURE",

  /************************************************************************* */
  GET_BY_ID_REGION_REQUEST: "GET_BY_ID_REGION_REQUEST",
  GET_BY_ID_REGION_SUCCESS: "GET_BY_ID_REGION_SUCCESS",
  GET_BY_ID_REGION_FAILURE: "GET_BY_ID_REGION_FAILURE",

  /************************************************************************* */
  CREATE_REGION_REQUEST: "CREATE_REGION_REQUEST",
  CREATE_REGION_SUCCESS: "CREATE_REGION_SUCCESS",
  CREATE_REGION_FAILURE: "CREATE_REGION_FAILURE",

  /************************************************************************* */
  UPDATE_REGION_REQUEST: "UPDATE_REGION_REQUEST",
  UPDATE_REGION_SUCCESS: "UPDATE_REGION_SUCCESS",
  UPDATE_REGION_FAILURE: "UPDATE_REGION_FAILURE",

  /************************************************************************* */
  DELETE_REGION_REQUEST: "DELETE_REGION_REQUEST",
  DELETE_REGION_SUCCESS: "DELETE_REGION_SUCCESS",
  DELETE_REGION_FAILURE: "DELETE_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_REGION_BY_ID_REQUEST: "RECOVER_BY_ID_REGION_BY_ID_REQUEST",
  RECOVER_REGION_BY_ID_SUCCESS: "RECOVER_REGION_BY_ID_SUCCESS",
  RECOVER_REGION_BY_ID_FAILURE: "RECOVER_REGION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_REGION_REQUEST: "RECOVER_ALL_REGION_REQUEST",
  RECOVER_ALL_REGION_SUCCESS: "RECOVER_ALL_REGION_SUCCESS",
  RECOVER_ALL_REGION_FAILURE: "RECOVER_ALL_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_REGION_REQUEST: "COMPLETE_DELETE_REGION_REQUEST",
  COMPLETE_DELETE_REGION_SUCCESS: "COMPLETE_DELETE_REGION_SUCCESS",
  COMPLETE_DELETE_REGION_FAILURE: "COMPLETE_DELETE_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_REGION_REQUEST: "TRASH_ALL_REGION_REQUEST",
  TRASH_ALL_REGION_SUCCESS: "TRASH_ALL_REGION_SUCCESS",
  TRASH_ALL_REGION_FAILURE: "TRASH_ALL_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_REGION_REQUEST: "EMPTY_TRASH_REGION_REQUEST",
  EMPTY_TRASH_REGION_SUCCESS: "EMPTY_TRASH_REGION_SUCCESS",
  EMPTY_TRASH_REGION_FAILURE: "EMPTY_TRASH_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_REGION_REQUEST: "EXPORT_REGION_REQUEST",
  EXPORT_REGION_SUCCESS: "EXPORT_REGION_SUCCESS",
  EXPORT_REGION_FAILURE: "EXPORT_REGION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_REGION_REQUEST: "GET_ALL_TRASHED_REGION_REQUEST",
  GET_ALL_TRASHED_REGION_SUCCESS: "GET_ALL_TRASHED_REGION_SUCCESS",
  GET_ALL_TRASHED_REGION_FAILURE: "GET_ALL_TRASHED_REGION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_REGION_REQUEST: "ARCHIVE_REGION_REQUEST",
  ARCHIVE_REGION_SUCCESS: "ARCHIVE_REGION_SUCCESS",
  ARCHIVE_REGION_FAILURE: "ARCHIVE_REGION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_REGION_REQUEST: "GET_ALL_ARCHIVED_REGION_REQUEST",
  GET_ALL_ARCHIVED_REGION_SUCCESS: "GET_ALL_ARCHIVED_REGION_SUCCESS",
  GET_ALL_ARCHIVED_REGION_FAILURE: "GET_ALL_ARCHIVED_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_REGION_REQUEST: "RECOVER_ALL_ARCHIVED_REGION_REQUEST",
  RECOVER_ALL_ARCHIVED_REGION_SUCCESS: "RECOVER_ALL_ARCHIVED_REGION_SUCCESS",
  RECOVER_ALL_ARCHIVED_REGION_FAILURE: "RECOVER_ALL_ARCHIVED_REGION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_REGION_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_REGION_BY_ID_REQUEST",
  RECOVER_ARCHIVED_REGION_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_REGION_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_REGION_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_REGION_BY_ID_FAILURE",
  /************************************************************************* */
};

export default REGION_ACTION_TYPES;
