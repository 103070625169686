const CONTROLLER_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_CONTROLLER_REQUEST: "GET_ALL_CONTROLLER_REQUEST",
  GET_ALL_CONTROLLER_SUCCESS: "GET_ALL_CONTROLLER_SUCCESS",
  GET_ALL_CONTROLLER_FAILURE: "GET_ALL_CONTROLLER_FAILURE",

  /************************************************************************* */
  GET_BY_ID_CONTROLLER_REQUEST: "GET_BY_ID_CONTROLLER_REQUEST",
  GET_BY_ID_CONTROLLER_SUCCESS: "GET_BY_ID_CONTROLLER_SUCCESS",
  GET_BY_ID_CONTROLLER_FAILURE: "GET_BY_ID_CONTROLLER_FAILURE",

  /************************************************************************* */
  CREATE_CONTROLLER_REQUEST: "CREATE_CONTROLLER_REQUEST",
  CREATE_CONTROLLER_SUCCESS: "CREATE_CONTROLLER_SUCCESS",
  CREATE_CONTROLLER_FAILURE: "CREATE_CONTROLLER_FAILURE",

  /************************************************************************* */
  UPDATE_CONTROLLER_REQUEST: "UPDATE_CONTROLLER_REQUEST",
  UPDATE_CONTROLLER_SUCCESS: "UPDATE_CONTROLLER_SUCCESS",
  UPDATE_CONTROLLER_FAILURE: "UPDATE_CONTROLLER_FAILURE",

  /************************************************************************* */
  DELETE_CONTROLLER_REQUEST: "DELETE_CONTROLLER_REQUEST",
  DELETE_CONTROLLER_SUCCESS: "DELETE_CONTROLLER_SUCCESS",
  DELETE_CONTROLLER_FAILURE: "DELETE_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_CONTROLLER_BY_ID_REQUEST: "RECOVER_BY_ID_CONTROLLER_BY_ID_REQUEST",
  RECOVER_CONTROLLER_BY_ID_SUCCESS: "RECOVER_CONTROLLER_BY_ID_SUCCESS",
  RECOVER_CONTROLLER_BY_ID_FAILURE: "RECOVER_CONTROLLER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_CONTROLLER_REQUEST: "RECOVER_ALL_CONTROLLER_REQUEST",
  RECOVER_ALL_CONTROLLER_SUCCESS: "RECOVER_ALL_CONTROLLER_SUCCESS",
  RECOVER_ALL_CONTROLLER_FAILURE: "RECOVER_ALL_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_CONTROLLER_REQUEST: "COMPLETE_DELETE_CONTROLLER_REQUEST",
  COMPLETE_DELETE_CONTROLLER_SUCCESS: "COMPLETE_DELETE_CONTROLLER_SUCCESS",
  COMPLETE_DELETE_CONTROLLER_FAILURE: "COMPLETE_DELETE_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_CONTROLLER_REQUEST: "TRASH_ALL_CONTROLLER_REQUEST",
  TRASH_ALL_CONTROLLER_SUCCESS: "TRASH_ALL_CONTROLLER_SUCCESS",
  TRASH_ALL_CONTROLLER_FAILURE: "TRASH_ALL_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_CONTROLLER_REQUEST: "EMPTY_TRASH_CONTROLLER_REQUEST",
  EMPTY_TRASH_CONTROLLER_SUCCESS: "EMPTY_TRASH_CONTROLLER_SUCCESS",
  EMPTY_TRASH_CONTROLLER_FAILURE: "EMPTY_TRASH_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_CONTROLLER_REQUEST: "EXPORT_CONTROLLER_REQUEST",
  EXPORT_CONTROLLER_SUCCESS: "EXPORT_CONTROLLER_SUCCESS",
  EXPORT_CONTROLLER_FAILURE: "EXPORT_CONTROLLER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_CONTROLLER_REQUEST: "GET_ALL_TRASHED_CONTROLLER_REQUEST",
  GET_ALL_TRASHED_CONTROLLER_SUCCESS: "GET_ALL_TRASHED_CONTROLLER_SUCCESS",
  GET_ALL_TRASHED_CONTROLLER_FAILURE: "GET_ALL_TRASHED_CONTROLLER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_CONTROLLER_REQUEST: "ARCHIVE_CONTROLLER_REQUEST",
  ARCHIVE_CONTROLLER_SUCCESS: "ARCHIVE_CONTROLLER_SUCCESS",
  ARCHIVE_CONTROLLER_FAILURE: "ARCHIVE_CONTROLLER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_CONTROLLER_REQUEST: "GET_ALL_ARCHIVED_CONTROLLER_REQUEST",
  GET_ALL_ARCHIVED_CONTROLLER_SUCCESS: "GET_ALL_ARCHIVED_CONTROLLER_SUCCESS",
  GET_ALL_ARCHIVED_CONTROLLER_FAILURE: "GET_ALL_ARCHIVED_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_CONTROLLER_REQUEST:
    "RECOVER_ALL_ARCHIVED_CONTROLLER_REQUEST",
  RECOVER_ALL_ARCHIVED_CONTROLLER_SUCCESS:
    "RECOVER_ALL_ARCHIVED_CONTROLLER_SUCCESS",
  RECOVER_ALL_ARCHIVED_CONTROLLER_FAILURE:
    "RECOVER_ALL_ARCHIVED_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_CONTROLLER_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_CONTROLLER_BY_ID_REQUEST",
  RECOVER_ARCHIVED_CONTROLLER_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_CONTROLLER_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_CONTROLLER_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_CONTROLLER_BY_ID_FAILURE",
  /************************************************************************* */
};

export default CONTROLLER_ACTION_TYPES;
