const ACTIVITY_TARGET_TYPE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ACTIVITY_TARGET_TYPE_REQUEST: "GET_ALL_ACTIVITY_TARGET_TYPE_REQUEST",
  GET_ALL_ACTIVITY_TARGET_TYPE_SUCCESS: "GET_ALL_ACTIVITY_TARGET_TYPE_SUCCESS",
  GET_ALL_ACTIVITY_TARGET_TYPE_FAILURE: "GET_ALL_ACTIVITY_TARGET_TYPE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ACTIVITY_TARGET_TYPE_REQUEST:
    "GET_BY_ID_ACTIVITY_TARGET_TYPE_REQUEST",
  GET_BY_ID_ACTIVITY_TARGET_TYPE_SUCCESS:
    "GET_BY_ID_ACTIVITY_TARGET_TYPE_SUCCESS",
  GET_BY_ID_ACTIVITY_TARGET_TYPE_FAILURE:
    "GET_BY_ID_ACTIVITY_TARGET_TYPE_FAILURE",

  /************************************************************************* */
  CREATE_ACTIVITY_TARGET_TYPE_REQUEST: "CREATE_ACTIVITY_TARGET_TYPE_REQUEST",
  CREATE_ACTIVITY_TARGET_TYPE_SUCCESS: "CREATE_ACTIVITY_TARGET_TYPE_SUCCESS",
  CREATE_ACTIVITY_TARGET_TYPE_FAILURE: "CREATE_ACTIVITY_TARGET_TYPE_FAILURE",

  /************************************************************************* */
  UPDATE_ACTIVITY_TARGET_TYPE_REQUEST: "UPDATE_ACTIVITY_TARGET_TYPE_REQUEST",
  UPDATE_ACTIVITY_TARGET_TYPE_SUCCESS: "UPDATE_ACTIVITY_TARGET_TYPE_SUCCESS",
  UPDATE_ACTIVITY_TARGET_TYPE_FAILURE: "UPDATE_ACTIVITY_TARGET_TYPE_FAILURE",

  /************************************************************************* */
  DELETE_ACTIVITY_TARGET_TYPE_REQUEST: "DELETE_ACTIVITY_TARGET_TYPE_REQUEST",
  DELETE_ACTIVITY_TARGET_TYPE_SUCCESS: "DELETE_ACTIVITY_TARGET_TYPE_SUCCESS",
  DELETE_ACTIVITY_TARGET_TYPE_FAILURE: "DELETE_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ACTIVITY_TARGET_TYPE_BY_ID_REQUEST:
    "RECOVER_BY_ID_ACTIVITY_TARGET_TYPE_BY_ID_REQUEST",
  RECOVER_ACTIVITY_TARGET_TYPE_BY_ID_SUCCESS:
    "RECOVER_ACTIVITY_TARGET_TYPE_BY_ID_SUCCESS",
  RECOVER_ACTIVITY_TARGET_TYPE_BY_ID_FAILURE:
    "RECOVER_ACTIVITY_TARGET_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ACTIVITY_TARGET_TYPE_REQUEST:
    "RECOVER_ALL_ACTIVITY_TARGET_TYPE_REQUEST",
  RECOVER_ALL_ACTIVITY_TARGET_TYPE_SUCCESS:
    "RECOVER_ALL_ACTIVITY_TARGET_TYPE_SUCCESS",
  RECOVER_ALL_ACTIVITY_TARGET_TYPE_FAILURE:
    "RECOVER_ALL_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ACTIVITY_TARGET_TYPE_REQUEST:
    "COMPLETE_DELETE_ACTIVITY_TARGET_TYPE_REQUEST",
  COMPLETE_DELETE_ACTIVITY_TARGET_TYPE_SUCCESS:
    "COMPLETE_DELETE_ACTIVITY_TARGET_TYPE_SUCCESS",
  COMPLETE_DELETE_ACTIVITY_TARGET_TYPE_FAILURE:
    "COMPLETE_DELETE_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ACTIVITY_TARGET_TYPE_REQUEST:
    "TRASH_ALL_ACTIVITY_TARGET_TYPE_REQUEST",
  TRASH_ALL_ACTIVITY_TARGET_TYPE_SUCCESS:
    "TRASH_ALL_ACTIVITY_TARGET_TYPE_SUCCESS",
  TRASH_ALL_ACTIVITY_TARGET_TYPE_FAILURE:
    "TRASH_ALL_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ACTIVITY_TARGET_TYPE_REQUEST:
    "EMPTY_TRASH_ACTIVITY_TARGET_TYPE_REQUEST",
  EMPTY_TRASH_ACTIVITY_TARGET_TYPE_SUCCESS:
    "EMPTY_TRASH_ACTIVITY_TARGET_TYPE_SUCCESS",
  EMPTY_TRASH_ACTIVITY_TARGET_TYPE_FAILURE:
    "EMPTY_TRASH_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ACTIVITY_TARGET_TYPE_REQUEST: "EXPORT_ACTIVITY_TARGET_TYPE_REQUEST",
  EXPORT_ACTIVITY_TARGET_TYPE_SUCCESS: "EXPORT_ACTIVITY_TARGET_TYPE_SUCCESS",
  EXPORT_ACTIVITY_TARGET_TYPE_FAILURE: "EXPORT_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ACTIVITY_TARGET_TYPE_REQUEST:
    "GET_ALL_TRASHED_ACTIVITY_TARGET_TYPE_REQUEST",
  GET_ALL_TRASHED_ACTIVITY_TARGET_TYPE_SUCCESS:
    "GET_ALL_TRASHED_ACTIVITY_TARGET_TYPE_SUCCESS",
  GET_ALL_TRASHED_ACTIVITY_TARGET_TYPE_FAILURE:
    "GET_ALL_TRASHED_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ACTIVITY_TARGET_TYPE_REQUEST: "ARCHIVE_ACTIVITY_TARGET_TYPE_REQUEST",
  ARCHIVE_ACTIVITY_TARGET_TYPE_SUCCESS: "ARCHIVE_ACTIVITY_TARGET_TYPE_SUCCESS",
  ARCHIVE_ACTIVITY_TARGET_TYPE_FAILURE: "ARCHIVE_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_REQUEST:
    "GET_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_REQUEST",
  GET_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_SUCCESS:
    "GET_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_SUCCESS",
  GET_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_FAILURE:
    "GET_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_REQUEST:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_REQUEST",
  RECOVER_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_SUCCESS:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_SUCCESS",
  RECOVER_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_FAILURE:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_TARGET_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ACTIVITY_TARGET_TYPE_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ACTIVITY_TARGET_TYPE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ACTIVITY_TARGET_TYPE_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ACTIVITY_TARGET_TYPE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ACTIVITY_TARGET_TYPE_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ACTIVITY_TARGET_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ACTIVITY_TARGET_TYPE_ACTION_TYPES;
