import React, { lazy,Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import store from "./store/store";
import { Provider } from "react-redux";
import { Box, Button, CircularProgress } from "@mui/material";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const App=lazy(()=>import("./App"))
// const isNonMobile = useMediaQuery("(min-width:900px)");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
         
          <Suspense fallback={<Box >
      <Button
                  sx={{
                    allign: "center",


                    backgroundColor: "inherit",
                    
                    width:"600px",
                    margin: "0px",
                    padding: "10px 20px",
                  
                    borderRadius: "10px",
                    
                  }}
                  type="button"
                 
                  startIcon={
                  
                      <CircularProgress size="4rem" />
                    
                  }
                >
                  {"IPMS is loading.First time may take a few mins. Please wait..."}
                </Button>
                 </Box>}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
      {/* </ErrorBoundary> */}
    </Provider>
  </React.StrictMode>
);
