const EMPLOYEE_GUARANTOR_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_EMPLOYEE_GUARANTOR_REQUEST: "GET_ALL_EMPLOYEE_GUARANTOR_REQUEST",
  GET_ALL_EMPLOYEE_GUARANTOR_SUCCESS: "GET_ALL_EMPLOYEE_GUARANTOR_SUCCESS",
  GET_ALL_EMPLOYEE_GUARANTOR_FAILURE: "GET_ALL_EMPLOYEE_GUARANTOR_FAILURE",

  /************************************************************************* */
  GET_BY_ID_EMPLOYEE_GUARANTOR_REQUEST: "GET_BY_ID_EMPLOYEE_GUARANTOR_REQUEST",
  GET_BY_ID_EMPLOYEE_GUARANTOR_SUCCESS: "GET_BY_ID_EMPLOYEE_GUARANTOR_SUCCESS",
  GET_BY_ID_EMPLOYEE_GUARANTOR_FAILURE: "GET_BY_ID_EMPLOYEE_GUARANTOR_FAILURE",

  /************************************************************************* */
  CREATE_EMPLOYEE_GUARANTOR_REQUEST: "CREATE_EMPLOYEE_GUARANTOR_REQUEST",
  CREATE_EMPLOYEE_GUARANTOR_SUCCESS: "CREATE_EMPLOYEE_GUARANTOR_SUCCESS",
  CREATE_EMPLOYEE_GUARANTOR_FAILURE: "CREATE_EMPLOYEE_GUARANTOR_FAILURE",

  /************************************************************************* */
  UPDATE_EMPLOYEE_GUARANTOR_REQUEST: "UPDATE_EMPLOYEE_GUARANTOR_REQUEST",
  UPDATE_EMPLOYEE_GUARANTOR_SUCCESS: "UPDATE_EMPLOYEE_GUARANTOR_SUCCESS",
  UPDATE_EMPLOYEE_GUARANTOR_FAILURE: "UPDATE_EMPLOYEE_GUARANTOR_FAILURE",

  /************************************************************************* */
  DELETE_EMPLOYEE_GUARANTOR_REQUEST: "DELETE_EMPLOYEE_GUARANTOR_REQUEST",
  DELETE_EMPLOYEE_GUARANTOR_SUCCESS: "DELETE_EMPLOYEE_GUARANTOR_SUCCESS",
  DELETE_EMPLOYEE_GUARANTOR_FAILURE: "DELETE_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_EMPLOYEE_GUARANTOR_BY_ID_REQUEST:
    "RECOVER_BY_ID_EMPLOYEE_GUARANTOR_BY_ID_REQUEST",
  RECOVER_EMPLOYEE_GUARANTOR_BY_ID_SUCCESS:
    "RECOVER_EMPLOYEE_GUARANTOR_BY_ID_SUCCESS",
  RECOVER_EMPLOYEE_GUARANTOR_BY_ID_FAILURE:
    "RECOVER_EMPLOYEE_GUARANTOR_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_EMPLOYEE_GUARANTOR_REQUEST:
    "RECOVER_ALL_EMPLOYEE_GUARANTOR_REQUEST",
  RECOVER_ALL_EMPLOYEE_GUARANTOR_SUCCESS:
    "RECOVER_ALL_EMPLOYEE_GUARANTOR_SUCCESS",
  RECOVER_ALL_EMPLOYEE_GUARANTOR_FAILURE:
    "RECOVER_ALL_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_EMPLOYEE_GUARANTOR_REQUEST:
    "COMPLETE_DELETE_EMPLOYEE_GUARANTOR_REQUEST",
  COMPLETE_DELETE_EMPLOYEE_GUARANTOR_SUCCESS:
    "COMPLETE_DELETE_EMPLOYEE_GUARANTOR_SUCCESS",
  COMPLETE_DELETE_EMPLOYEE_GUARANTOR_FAILURE:
    "COMPLETE_DELETE_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_EMPLOYEE_GUARANTOR_REQUEST: "TRASH_ALL_EMPLOYEE_GUARANTOR_REQUEST",
  TRASH_ALL_EMPLOYEE_GUARANTOR_SUCCESS: "TRASH_ALL_EMPLOYEE_GUARANTOR_SUCCESS",
  TRASH_ALL_EMPLOYEE_GUARANTOR_FAILURE: "TRASH_ALL_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_EMPLOYEE_GUARANTOR_REQUEST:
    "EMPTY_TRASH_EMPLOYEE_GUARANTOR_REQUEST",
  EMPTY_TRASH_EMPLOYEE_GUARANTOR_SUCCESS:
    "EMPTY_TRASH_EMPLOYEE_GUARANTOR_SUCCESS",
  EMPTY_TRASH_EMPLOYEE_GUARANTOR_FAILURE:
    "EMPTY_TRASH_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_EMPLOYEE_GUARANTOR_REQUEST: "EXPORT_EMPLOYEE_GUARANTOR_REQUEST",
  EXPORT_EMPLOYEE_GUARANTOR_SUCCESS: "EXPORT_EMPLOYEE_GUARANTOR_SUCCESS",
  EXPORT_EMPLOYEE_GUARANTOR_FAILURE: "EXPORT_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_REQUEST:
    "GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_REQUEST",
  GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_SUCCESS:
    "GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_SUCCESS",
  GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_FAILURE:
    "GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_EMPLOYEE_GUARANTOR_REQUEST: "ARCHIVE_EMPLOYEE_GUARANTOR_REQUEST",
  ARCHIVE_EMPLOYEE_GUARANTOR_SUCCESS: "ARCHIVE_EMPLOYEE_GUARANTOR_SUCCESS",
  ARCHIVE_EMPLOYEE_GUARANTOR_FAILURE: "ARCHIVE_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_REQUEST:
    "GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_REQUEST",
  GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_SUCCESS:
    "GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_SUCCESS",
  GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_FAILURE:
    "GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_REQUEST:
    "RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_REQUEST",
  RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_SUCCESS:
    "RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_SUCCESS",
  RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_FAILURE:
    "RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_REQUEST",
  RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_FAILURE",
  /************************************************************************* */
};

export default EMPLOYEE_GUARANTOR_ACTION_TYPES;
