const DEPARTMENT_CATEGORY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_DEPARTMENT_CATEGORY_REQUEST: "GET_ALL_DEPARTMENT_CATEGORY_REQUEST",
  GET_ALL_DEPARTMENT_CATEGORY_SUCCESS: "GET_ALL_DEPARTMENT_CATEGORY_SUCCESS",
  GET_ALL_DEPARTMENT_CATEGORY_FAILURE: "GET_ALL_DEPARTMENT_CATEGORY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_DEPARTMENT_CATEGORY_REQUEST:
    "GET_BY_ID_DEPARTMENT_CATEGORY_REQUEST",
  GET_BY_ID_DEPARTMENT_CATEGORY_SUCCESS:
    "GET_BY_ID_DEPARTMENT_CATEGORY_SUCCESS",
  GET_BY_ID_DEPARTMENT_CATEGORY_FAILURE:
    "GET_BY_ID_DEPARTMENT_CATEGORY_FAILURE",

  /************************************************************************* */
  CREATE_DEPARTMENT_CATEGORY_REQUEST: "CREATE_DEPARTMENT_CATEGORY_REQUEST",
  CREATE_DEPARTMENT_CATEGORY_SUCCESS: "CREATE_DEPARTMENT_CATEGORY_SUCCESS",
  CREATE_DEPARTMENT_CATEGORY_FAILURE: "CREATE_DEPARTMENT_CATEGORY_FAILURE",

  /************************************************************************* */
  UPDATE_DEPARTMENT_CATEGORY_REQUEST: "UPDATE_DEPARTMENT_CATEGORY_REQUEST",
  UPDATE_DEPARTMENT_CATEGORY_SUCCESS: "UPDATE_DEPARTMENT_CATEGORY_SUCCESS",
  UPDATE_DEPARTMENT_CATEGORY_FAILURE: "UPDATE_DEPARTMENT_CATEGORY_FAILURE",

  /************************************************************************* */
  DELETE_DEPARTMENT_CATEGORY_REQUEST: "DELETE_DEPARTMENT_CATEGORY_REQUEST",
  DELETE_DEPARTMENT_CATEGORY_SUCCESS: "DELETE_DEPARTMENT_CATEGORY_SUCCESS",
  DELETE_DEPARTMENT_CATEGORY_FAILURE: "DELETE_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_DEPARTMENT_CATEGORY_BY_ID_REQUEST:
    "RECOVER_BY_ID_DEPARTMENT_CATEGORY_BY_ID_REQUEST",
  RECOVER_DEPARTMENT_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_DEPARTMENT_CATEGORY_BY_ID_SUCCESS",
  RECOVER_DEPARTMENT_CATEGORY_BY_ID_FAILURE:
    "RECOVER_DEPARTMENT_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_DEPARTMENT_CATEGORY_REQUEST:
    "RECOVER_ALL_DEPARTMENT_CATEGORY_REQUEST",
  RECOVER_ALL_DEPARTMENT_CATEGORY_SUCCESS:
    "RECOVER_ALL_DEPARTMENT_CATEGORY_SUCCESS",
  RECOVER_ALL_DEPARTMENT_CATEGORY_FAILURE:
    "RECOVER_ALL_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_DEPARTMENT_CATEGORY_REQUEST:
    "COMPLETE_DELETE_DEPARTMENT_CATEGORY_REQUEST",
  COMPLETE_DELETE_DEPARTMENT_CATEGORY_SUCCESS:
    "COMPLETE_DELETE_DEPARTMENT_CATEGORY_SUCCESS",
  COMPLETE_DELETE_DEPARTMENT_CATEGORY_FAILURE:
    "COMPLETE_DELETE_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_DEPARTMENT_CATEGORY_REQUEST:
    "TRASH_ALL_DEPARTMENT_CATEGORY_REQUEST",
  TRASH_ALL_DEPARTMENT_CATEGORY_SUCCESS:
    "TRASH_ALL_DEPARTMENT_CATEGORY_SUCCESS",
  TRASH_ALL_DEPARTMENT_CATEGORY_FAILURE:
    "TRASH_ALL_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_DEPARTMENT_CATEGORY_REQUEST:
    "EMPTY_TRASH_DEPARTMENT_CATEGORY_REQUEST",
  EMPTY_TRASH_DEPARTMENT_CATEGORY_SUCCESS:
    "EMPTY_TRASH_DEPARTMENT_CATEGORY_SUCCESS",
  EMPTY_TRASH_DEPARTMENT_CATEGORY_FAILURE:
    "EMPTY_TRASH_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_DEPARTMENT_CATEGORY_REQUEST: "EXPORT_DEPARTMENT_CATEGORY_REQUEST",
  EXPORT_DEPARTMENT_CATEGORY_SUCCESS: "EXPORT_DEPARTMENT_CATEGORY_SUCCESS",
  EXPORT_DEPARTMENT_CATEGORY_FAILURE: "EXPORT_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_DEPARTMENT_CATEGORY_REQUEST:
    "GET_ALL_TRASHED_DEPARTMENT_CATEGORY_REQUEST",
  GET_ALL_TRASHED_DEPARTMENT_CATEGORY_SUCCESS:
    "GET_ALL_TRASHED_DEPARTMENT_CATEGORY_SUCCESS",
  GET_ALL_TRASHED_DEPARTMENT_CATEGORY_FAILURE:
    "GET_ALL_TRASHED_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_DEPARTMENT_CATEGORY_REQUEST: "ARCHIVE_DEPARTMENT_CATEGORY_REQUEST",
  ARCHIVE_DEPARTMENT_CATEGORY_SUCCESS: "ARCHIVE_DEPARTMENT_CATEGORY_SUCCESS",
  ARCHIVE_DEPARTMENT_CATEGORY_FAILURE: "ARCHIVE_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_DEPARTMENT_CATEGORY_REQUEST:
    "GET_ALL_ARCHIVED_DEPARTMENT_CATEGORY_REQUEST",
  GET_ALL_ARCHIVED_DEPARTMENT_CATEGORY_SUCCESS:
    "GET_ALL_ARCHIVED_DEPARTMENT_CATEGORY_SUCCESS",
  GET_ALL_ARCHIVED_DEPARTMENT_CATEGORY_FAILURE:
    "GET_ALL_ARCHIVED_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_DEPARTMENT_CATEGORY_REQUEST:
    "RECOVER_ALL_ARCHIVED_DEPARTMENT_CATEGORY_REQUEST",
  RECOVER_ALL_ARCHIVED_DEPARTMENT_CATEGORY_SUCCESS:
    "RECOVER_ALL_ARCHIVED_DEPARTMENT_CATEGORY_SUCCESS",
  RECOVER_ALL_ARCHIVED_DEPARTMENT_CATEGORY_FAILURE:
    "RECOVER_ALL_ARCHIVED_DEPARTMENT_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_DEPARTMENT_CATEGORY_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_DEPARTMENT_CATEGORY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_DEPARTMENT_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_DEPARTMENT_CATEGORY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_DEPARTMENT_CATEGORY_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_DEPARTMENT_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
};

export default DEPARTMENT_CATEGORY_ACTION_TYPES;
