import FIELD_VISIT_ACTION_TYPES from "../actionTypes/fieldVisitActionTypes";

const initialState = {
  isLoading: false,
  fieldVisitState: [],
  fieldVisitErrorState: "",
  getNewFieldVisitState: [],
  getNewFieldVisitErrorState: "",
  getNewFieldVisitByIdState: [],
  getNewFieldVisitByIdForEditState: [],
  sendNewFieldVisitForSupervisorState: [],
  getFieldVisitApprovalRequestState: [],
  getFieldVisitRejectedApprovalRequestState: [],
  getFieldVisitRejectedAltogetherApprovalRequestState: [],
  getAllApprovedFieldVisitRequestState: [],
  getFieldVisitApprovalByProjectByUserIdRequestState: [],
  getFieldVisitRejectedApprovalByProjectByUserIdRequestState: [],
  getFieldVisitRejectedAltogetherApprovalByProjectByUserIdRequestState: [],
  getAllApprovedFieldVisitByProjectByUserIdRequestState: [],
  rejectForModification: [],
  getApprovedFieldVisitState: [],
};

const FIELD_VISIT_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case FIELD_VISIT_ACTION_TYPES.CREATE_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    // case FIELD_VISIT_ACTION_TYPES.CREATE_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case FIELD_VISIT_ACTION_TYPES.CREATE_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case FIELD_VISIT_ACTION_TYPES.UPDATE_FIELD_VISIT_SUCCESS:
      return {
        ...state,
        fieldVisitState: state.fieldVisitState.map((dept) =>
          dept.id === action.payload ? action.payload : dept
        ),
      };
    case FIELD_VISIT_ACTION_TYPES.UPDATE_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //********************************UPDATE******************************************** */
    case FIELD_VISIT_ACTION_TYPES.UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        fieldVisitState: state.fieldVisitState.map((dept) =>
          dept.id === action.payload ? action.payload : dept
        ),
      };
    case FIELD_VISIT_ACTION_TYPES.UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case FIELD_VISIT_ACTION_TYPES.DELETE_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.DELETE_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.DELETE_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_FIELD_VISIT_SUCCESS:
      return { ...state, fieldVisitState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS:
      return { ...state, fieldVisitState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return { ...state, getFieldVisitApprovalRequestState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return { ...state, getFieldVisitApprovalRequestState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return {
        ...state,
        getFieldVisitRejectedApprovalRequestState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return {
        ...state,
        getFieldVisitRejectedAltogetherApprovalRequestState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return {
        ...state,
        getAllApprovedFieldVisitRequestState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getFieldVisitApprovalByProjectByUserIdRequestState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getFieldVisitRejectedApprovalByProjectByUserIdRequestState: [
          ...action.payload,
        ],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getFieldVisitRejectedAltogetherApprovalByProjectByUserIdRequestState: [
          ...action.payload,
        ],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getAllApprovedFieldVisitByProjectByUserIdRequestState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS:
      return { ...state, getNewFieldVisitState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS:
      return { ...state, getApprovedFieldVisitState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case FIELD_VISIT_ACTION_TYPES.SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_SUCCESS:
      return { ...state, sendNewFieldVisitForSupervisorState: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case FIELD_VISIT_ACTION_TYPES.SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case FIELD_VISIT_ACTION_TYPES.PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case FIELD_VISIT_ACTION_TYPES.FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case FIELD_VISIT_ACTION_TYPES.CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case FIELD_VISIT_ACTION_TYPES.CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.GET_BY_ID_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_BY_ID_FIELD_VISIT_SUCCESS:
      return {
        ...state,
        getNewFieldVisitByIdState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_BY_ID_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* GET_BY_ID***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.GET_BY_ID_FIELD_VISIT_FOR_EDIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_BY_ID_FIELD_VISIT_FOR_EDIT_SUCCESS:
      return {
        ...state,
        getNewFieldVisitByIdForEditState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_BY_ID_FIELD_VISIT_FOR_EDIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.COMPLETE_DELETE_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.COMPLETE_DELETE_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.COMPLETE_DELETE_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.EMPTY_TRASH_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.EMPTY_TRASH_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.EMPTY_TRASH_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.EXPORT_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.EXPORT_FIELD_VISIT_SUCCESS:
      return {
        ...state,
        // fieldVisitState: action.payload,
      };
    case FIELD_VISIT_ACTION_TYPES.EXPORT_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.RECOVER_ALL_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_ALL_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_ALL_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_FIELD_VISIT_BY_ID***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.RECOVER_FIELD_VISIT_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_FIELD_VISIT_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_FIELD_VISIT_BY_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************RECOVER_FIELD_VISIT_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.TRASH_ALL_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.TRASH_ALL_FIELD_VISIT_SUCCESS:
      return { ...state };
    case FIELD_VISIT_ACTION_TYPES.TRASH_ALL_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_TRASHED_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_TRASHED_FIELD_VISIT_SUCCESS:
      return {
        ...state,
        fieldVisitState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_TRASHED_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.ARCHIVE_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.ARCHIVE_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.ARCHIVE_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.ARCHIVE_SELECTED_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.ARCHIVE_SELECTED_FIELD_VISIT_SUCCESS:
      return {
        ...state,
      };
    case FIELD_VISIT_ACTION_TYPES.ARCHIVE_SELECTED_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.GET_ALL_ARCHIVED_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_ARCHIVED_FIELD_VISIT_SUCCESS:
      return {
        ...state,
        fieldVisitState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.GET_ALL_ARCHIVED_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_FIELD_VISIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_FIELD_VISIT_SUCCESS:
      return {
        ...state,
        // fieldVisitState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_FIELD_VISIT_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case FIELD_VISIT_ACTION_TYPES.RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_SUCCESS:
      return {
        ...state,
        // fieldVisitState: [...action.payload],
      };
    case FIELD_VISIT_ACTION_TYPES.RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_FAILURE:
      return {
        fieldVisitErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default FIELD_VISIT_REDUCER;
