const FIELD_VISIT_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_FIELD_VISIT_REQUEST: "GET_ALL_FIELD_VISIT_REQUEST",
  GET_ALL_FIELD_VISIT_SUCCESS: "GET_ALL_FIELD_VISIT_SUCCESS",
  GET_ALL_FIELD_VISIT_FAILURE: "GET_ALL_FIELD_VISIT_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST:
    "GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST",
  GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS:
    "GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS",
  GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE:
    "GET_ALL_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST:
    "GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST",
  GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS:
    "GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS",
  GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE:
    "GET_ALL_NEW_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST:
    "GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_REQUEST",
  GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS:
    "GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_SUCCESS",
  GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE:
    "GET_ALL_APPROVED_PROJECT_FIELD_VISIT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_REQUEST:
    "SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_REQUEST",
  SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_SUCCESS:
    "SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_SUCCESS",
  SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_FAILURE:
    "SEND_NEW_FIELD_VISIT_FOR_SUPERRVISOR_FAILURE",

  /************************************************************************* */
  GET_BY_ID_FIELD_VISIT_REQUEST: "GET_BY_ID_FIELD_VISIT_REQUEST",
  GET_BY_ID_FIELD_VISIT_SUCCESS: "GET_BY_ID_FIELD_VISIT_SUCCESS",
  GET_BY_ID_FIELD_VISIT_FAILURE: "GET_BY_ID_FIELD_VISIT_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_BY_ID_FIELD_VISIT_FOR_EDIT_REQUEST:
    "GET_BY_ID_FIELD_VISIT_FOR_EDIT_REQUEST",
  GET_BY_ID_FIELD_VISIT_FOR_EDIT_SUCCESS:
    "GET_BY_ID_FIELD_VISIT_FOR_EDIT_SUCCESS",
  GET_BY_ID_FIELD_VISIT_FOR_EDIT_FAILURE:
    "GET_BY_ID_FIELD_VISIT_FOR_EDIT_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST:
    "SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST",
  SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
    "SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS",
  SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
    "SUPERVISOR_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST:
    "PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST",
  PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
    "PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS",
  PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
    "PROGRAM_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST:
    "FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST",
  FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
    "FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS",
  FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
    "FINANCE_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST:
    "CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_REQUEST",
  CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS:
    "CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_SUCCESS",
  CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE:
    "CEO_REJECT_FIELD_VISIT_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SUPERVISOR_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST:
    "SUPERVISOR_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST",
  SUPERVISOR_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS:
    "SUPERVISOR_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS",
  SUPERVISOR_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE:
    "SUPERVISOR_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  PROGRAM_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST:
    "PROGRAM_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST",
  PROGRAM_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS:
    "PROGRAM_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS",
  PROGRAM_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE:
    "PROGRAM_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  FINANCE_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST:
    "FINANCE_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST",
  FINANCE_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS:
    "FINANCE_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS",
  FINANCE_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE:
    "FINANCE_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CEO_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST:
    "CEO_REJECT_FIELD_VISIT_ALTOGETHER_REQUEST",
  CEO_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS:
    "CEO_REJECT_FIELD_VISIT_ALTOGETHER_SUCCESS",
  CEO_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE:
    "CEO_REJECT_FIELD_VISIT_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SUPERVISOR_APPROVE_REQUEST: "SUPERVISOR_APPROVE_REQUEST",
  SUPERVISOR_APPROVE_SUCCESS: "SUPERVISOR_APPROVE_SUCCESS",
  SUPERVISOR_APPROVE_FAILURE: "SUPERVISOR_APPROVE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  PROGRAM_APPROVE_REQUEST: "PROGRAM_APPROVE_REQUEST",
  PROGRAM_APPROVE_SUCCESS: "PROGRAM_APPROVE_SUCCESS",
  PROGRAM_APPROVE_FAILURE: "PROGRAM_APPROVE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  FINANCE_APPROVE_REQUEST: "FINANCE_APPROVE_REQUEST",
  FINANCE_APPROVE_SUCCESS: "FINANCE_APPROVE_SUCCESS",
  FINANCE_APPROVE_FAILURE: "FINANCE_APPROVE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CEO_APPROVE_REQUEST: "CEO_APPROVE_REQUEST",
  CEO_APPROVE_SUCCESS: "CEO_APPROVE_SUCCESS",
  CEO_APPROVE_FAILURE: "CEO_APPROVE_FAILURE",

  /************************************************************************* */
  CREATE_FIELD_VISIT_REQUEST: "CREATE_FIELD_VISIT_REQUEST",
  CREATE_FIELD_VISIT_SUCCESS: "CREATE_FIELD_VISIT_SUCCESS",
  CREATE_FIELD_VISIT_FAILURE: "CREATE_FIELD_VISIT_FAILURE",

  /************************************************************************* */
  UPDATE_FIELD_VISIT_REQUEST: "UPDATE_FIELD_VISIT_REQUEST",
  UPDATE_FIELD_VISIT_SUCCESS: "UPDATE_FIELD_VISIT_SUCCESS",
  UPDATE_FIELD_VISIT_FAILURE: "UPDATE_FIELD_VISIT_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_REQUEST:
    "UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_REQUEST",
  UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_SUCCESS:
    "UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_SUCCESS",
  UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_FAILURE:
    "UPDATE_FIELD_VISIT_BASED_ON_MODIFICATION_REQUEST_FAILURE",

  /************************************************************************* */
  DELETE_FIELD_VISIT_REQUEST: "DELETE_FIELD_VISIT_REQUEST",
  DELETE_FIELD_VISIT_SUCCESS: "DELETE_FIELD_VISIT_SUCCESS",
  DELETE_FIELD_VISIT_FAILURE: "DELETE_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_FIELD_VISIT_BY_ID_REQUEST: "RECOVER_BY_ID_FIELD_VISIT_BY_ID_REQUEST",
  RECOVER_FIELD_VISIT_BY_ID_SUCCESS: "RECOVER_FIELD_VISIT_BY_ID_SUCCESS",
  RECOVER_FIELD_VISIT_BY_ID_FAILURE: "RECOVER_FIELD_VISIT_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_FIELD_VISIT_REQUEST: "RECOVER_ALL_FIELD_VISIT_REQUEST",
  RECOVER_ALL_FIELD_VISIT_SUCCESS: "RECOVER_ALL_FIELD_VISIT_SUCCESS",
  RECOVER_ALL_FIELD_VISIT_FAILURE: "RECOVER_ALL_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_FIELD_VISIT_REQUEST: "COMPLETE_DELETE_FIELD_VISIT_REQUEST",
  COMPLETE_DELETE_FIELD_VISIT_SUCCESS: "COMPLETE_DELETE_FIELD_VISIT_SUCCESS",
  COMPLETE_DELETE_FIELD_VISIT_FAILURE: "COMPLETE_DELETE_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_FIELD_VISIT_REQUEST: "TRASH_ALL_FIELD_VISIT_REQUEST",
  TRASH_ALL_FIELD_VISIT_SUCCESS: "TRASH_ALL_FIELD_VISIT_SUCCESS",
  TRASH_ALL_FIELD_VISIT_FAILURE: "TRASH_ALL_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_FIELD_VISIT_REQUEST: "EMPTY_TRASH_FIELD_VISIT_REQUEST",
  EMPTY_TRASH_FIELD_VISIT_SUCCESS: "EMPTY_TRASH_FIELD_VISIT_SUCCESS",
  EMPTY_TRASH_FIELD_VISIT_FAILURE: "EMPTY_TRASH_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_FIELD_VISIT_REQUEST: "EXPORT_FIELD_VISIT_REQUEST",
  EXPORT_FIELD_VISIT_SUCCESS: "EXPORT_FIELD_VISIT_SUCCESS",
  EXPORT_FIELD_VISIT_FAILURE: "EXPORT_FIELD_VISIT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_FIELD_VISIT_REQUEST: "GET_ALL_TRASHED_FIELD_VISIT_REQUEST",
  GET_ALL_TRASHED_FIELD_VISIT_SUCCESS: "GET_ALL_TRASHED_FIELD_VISIT_SUCCESS",
  GET_ALL_TRASHED_FIELD_VISIT_FAILURE: "GET_ALL_TRASHED_FIELD_VISIT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_FIELD_VISIT_REQUEST: "ARCHIVE_FIELD_VISIT_REQUEST",
  ARCHIVE_FIELD_VISIT_SUCCESS: "ARCHIVE_FIELD_VISIT_SUCCESS",
  ARCHIVE_FIELD_VISIT_FAILURE: "ARCHIVE_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  ARCHIVE_SELECTED_FIELD_VISIT_REQUEST: "ARCHIVE_SELECTED_FIELD_VISIT_REQUEST",
  ARCHIVE_SELECTED_FIELD_VISIT_SUCCESS: "ARCHIVE_SELECTED_FIELD_VISIT_SUCCESS",
  ARCHIVE_SELECTED_FIELD_VISIT_FAILURE: "ARCHIVE_SELECTED_FIELD_VISIT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_FIELD_VISIT_REQUEST: "GET_ALL_ARCHIVED_FIELD_VISIT_REQUEST",
  GET_ALL_ARCHIVED_FIELD_VISIT_SUCCESS: "GET_ALL_ARCHIVED_FIELD_VISIT_SUCCESS",
  GET_ALL_ARCHIVED_FIELD_VISIT_FAILURE: "GET_ALL_ARCHIVED_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_FIELD_VISIT_REQUEST:
    "RECOVER_ALL_ARCHIVED_FIELD_VISIT_REQUEST",
  RECOVER_ALL_ARCHIVED_FIELD_VISIT_SUCCESS:
    "RECOVER_ALL_ARCHIVED_FIELD_VISIT_SUCCESS",
  RECOVER_ALL_ARCHIVED_FIELD_VISIT_FAILURE:
    "RECOVER_ALL_ARCHIVED_FIELD_VISIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_REQUEST",
  RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_FIELD_VISIT_BY_ID_FAILURE",
  /************************************************************************* */
};

export default FIELD_VISIT_ACTION_TYPES;
