import { combineReducers } from "redux";

import DEPARTMENT_REDUCER from "./departmentReducers";
import USER_REDUCER from "./userReducers";
import PROGRAM_REDUCER from "./programReducers";
import ACTIVITY_REDUCER from "./activityReducers";
import CITY_REDUCER from "./cityReducers";
import CONTROLLER_REDUCER from "./controllerReducers";
import ACTION_REDUCER from "./actionReducers";
import COUNTRY_REDUCER from "./countryReducers";
import DONNER_REDUCER from "./donnerReducers";
import EMPLOYEE_GUARANTOR_REDUCER from "./employeeGuarantorReducers";
import EMPLOYEE_REDUCER from "./employeeReducers";
import FIELD_VISIT_REDUCER from "./fieldVisitReducers";
import ITEM_REDUCER from "./itemReducers";
import KEBELE_REDUCER from "./kebeleReducers";
import KIFLE_KETEMA_REDUCER from "./kifleKetemaReducers";

// import NEWUSER_REDUCER from "./newUserReducers";
import ORGANIZATION_REDUCER from "./organizationReducers";
import PERMISSION_REDUCER from "./permissionReducers";
import REGION_REDUCER from "./regionReducers";

import ROLE_REDUCER from "./roleReducers";
import STORE_REDUCER from "./storeReducers";
import TASK_REDUCER from "./taskReducers";
import USERTYPE_REDUCER from "./userTypeReducers";
import WOREDA_REDUCER from "./woredaReducers";
import ZONE_REDUCER from "./zoneReducers";
import DEPARTMENT_CATEGORY_REDUCER from "./departmentCategoryReducers";

import BID_LOT_REDUCER from "./bidLotReducers";
import BID_REDUCER from "./bidReducers";
import BID_TYPE from "./bidTypeReducers";
import CONTACT_PERSON from "./contactPersonReducers";

import EDUCATIONAL_STATUS_TYPE_REDUCER from "./educationalStatusTypeReducers";
import EMPLOYEE_POSITION_TYPE_REDUCER from "./employeePositionTypeReducers";
import EMPLOYEE_PROJECT_REDUCER from "./employeeProjectReducers";
import ITEM_CATEGORY_REDUCER from "./itemCategoryReducers";
import ITEM_DELIVERY_REDUCER from "./itemDeliveryReducers";
import ITEM_TYPE_REDUCER from "./itemTypeReducers";
import LOCATION_REDUCER from "./locationReducers";
import MANAGER_REDUCER from "./managerReducers";
import MATERIAL_REDUCER from "./materialReducers";
import MESSAGE_REDUCER from "./messageReducers";
import NOTIFICATION_REDUCER from "./notificationReducers";
import ORGANIZATION_TYPE_REDUCER from "./organizationTypeReducers";
import PERMISSION_CATEGORY_REDUCER from "./permissionCategoryReducers";
import PROJECT_REDUCER from "./projectReducers";

import RIGHT_HOLDER_REDUCER from "./rightHolderReducers";

import SHELF_REDUCER from "./shelfReducers";

import SUPPLIER_CATEGORY_REDUCER from "./supplierCategoryReducers";
import SUPPLIER_REDUCER from "./supplierReducers";
import UTILITY_REDUCER from "./taskReducers";
import TRADE_CATEGORY_REDUCER from "./tradeCategoryReducers";
import TRADE_COMPANY_REDUCER from "./tradeCompnayReducers";
import USER_PROJECT_REDUCER from "./userProjectReducers";
import USER_TYPE_REDUCER from "./userTypeReducers";
import ACTIVITY_TYPE_REDUCER from "./activityTypeReducers";
import RIGHT_HOLDER_CONTACT_PERSON_REDUCER from "./rightHolderContactPersonReducers";
import CONTACT_PERSON_REDUCER from "./contactPersonReducers";
import RELATIONSHIP_TYPE_REDUCER from "./relationshipTypeReducers";
import ACTIVITY_MEASUREMENT_UNIT_REDUCER from "./activityMeasurementUnitReducers";
import ACTIVITY_TARGET_TYPE_REDUCER from "./activityTargetTypeReducers";
import REPORT_REDUCER from "./reportReducers";
import PROJECT_TASK_TYPE_REDUCER from "./projectTaskTypeReducers";
import TOR_REDUCER from "./torReducers";

import TRAINING_REDUCER from "./trainingReducers";
import TRAINING_TYPE_REDUCER from "./trainingTypeReducers";

// import PROGRAM_REDUCER from "./programReducers";

const rootReducer = combineReducers({
  DEPARTMENT_REDUCER,
  USER_REDUCER,
  PROGRAM_REDUCER,
  ACTIVITY_REDUCER,
  ACTIVITY_TYPE_REDUCER,
  CITY_REDUCER,
  CONTROLLER_REDUCER,
  COUNTRY_REDUCER,
  DONNER_REDUCER,
  EMPLOYEE_GUARANTOR_REDUCER,
  EMPLOYEE_REDUCER,
  FIELD_VISIT_REDUCER,
  ITEM_REDUCER,
  KEBELE_REDUCER,
  KIFLE_KETEMA_REDUCER,
  ORGANIZATION_REDUCER,
  PERMISSION_REDUCER,
  REGION_REDUCER,

  ROLE_REDUCER,
  STORE_REDUCER,
  TASK_REDUCER,
  USERTYPE_REDUCER,
  WOREDA_REDUCER,
  ZONE_REDUCER,
  DEPARTMENT_CATEGORY_REDUCER,
  UTILITY_REDUCER,
  BID_LOT_REDUCER,
  BID_REDUCER,
  BID_TYPE,
  CONTACT_PERSON,

  EDUCATIONAL_STATUS_TYPE_REDUCER,
  EMPLOYEE_POSITION_TYPE_REDUCER,
  EMPLOYEE_PROJECT_REDUCER,
  ITEM_CATEGORY_REDUCER,
  ITEM_TYPE_REDUCER,
  ITEM_DELIVERY_REDUCER,
  LOCATION_REDUCER,
  MANAGER_REDUCER,
  MATERIAL_REDUCER,
  MESSAGE_REDUCER,
  NOTIFICATION_REDUCER,
  ORGANIZATION_TYPE_REDUCER,
  PERMISSION_CATEGORY_REDUCER,
  RIGHT_HOLDER_REDUCER,

  SHELF_REDUCER,
  SUPPLIER_CATEGORY_REDUCER,
  SUPPLIER_REDUCER,

  TRADE_CATEGORY_REDUCER,
  TRADE_COMPANY_REDUCER,
  USER_PROJECT_REDUCER,
  USER_TYPE_REDUCER,
  PROJECT_REDUCER,
  RIGHT_HOLDER_CONTACT_PERSON_REDUCER,
  CONTACT_PERSON_REDUCER,
  RELATIONSHIP_TYPE_REDUCER,
  ACTION_REDUCER,
  ACTIVITY_TARGET_TYPE_REDUCER,
  ACTIVITY_MEASUREMENT_UNIT_REDUCER,
  REPORT_REDUCER,
  PROJECT_TASK_TYPE_REDUCER,
  TOR_REDUCER,
  TRAINING_REDUCER,
  TRAINING_TYPE_REDUCER,
});

export default rootReducer;
