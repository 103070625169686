const REPORT_ACTION_TYPES = {
  /************************************************************************* */
  GENERATE_REPORT_REQUEST: "GENERATE_REPORT_REQUEST",
  GENERATE_REPORT_SUCCESS: "GENERATE_REPORT_SUCCESS",
  GENERATE_REPORT_FAILURE: "GENERATE_REPORT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GENERATE_PROJECT_REPORT_BY_ID_REQUEST:
    "GENERATE_PROJECT_REPORT_BY_ID_REQUEST",
  GENERATE_PROJECT_REPORT_BY_ID_SUCCESS:
    "GENERATE_PROJECT_REPORT_BY_ID_SUCCESS",
  GENERATE_PROJECT_REPORT_BY_ID_FAILURE:
    "GENERATE_PROJECT_REPORT_BY_ID_FAILURE",
  /************************************************************************* */
};

export default REPORT_ACTION_TYPES;
