import USER_PROJECT_ACTION_TYPES from "../actionTypes/userProjectActionTypes";

const initialState = {
  isLoading: false,
  userProjectState: [],
  userProjectErrorState: "",
};

const USER_PROJECT_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case USER_PROJECT_ACTION_TYPES.CREATE_USER_PROJECT_SUCCESS:
      return {
        ...state,
      };
    // case USER_PROJECT_ACTION_TYPES.CREATE_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case USER_PROJECT_ACTION_TYPES.CREATE_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case USER_PROJECT_ACTION_TYPES.UPDATE_USER_PROJECT_SUCCESS:
      return {
        ...state,
        userProjectState: state.userProjectState.map((uPro) =>
          uPro.id === action.payload ? action.payload : uPro
        ),
      };
    case USER_PROJECT_ACTION_TYPES.UPDATE_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case USER_PROJECT_ACTION_TYPES.DELETE_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.DELETE_USER_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case USER_PROJECT_ACTION_TYPES.DELETE_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case USER_PROJECT_ACTION_TYPES.GET_ALL_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.GET_ALL_USER_PROJECT_SUCCESS:
      return { ...state, userProjectState: [...action.payload] };
    case USER_PROJECT_ACTION_TYPES.GET_ALL_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case USER_PROJECT_ACTION_TYPES.GET_BY_ID_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.GET_BY_ID_USER_PROJECT_SUCCESS:
      return {
        ...state,
        userProjectState: action.payload,
      };
    case USER_PROJECT_ACTION_TYPES.GET_BY_ID_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case USER_PROJECT_ACTION_TYPES.COMPLETE_DELETE_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.COMPLETE_DELETE_USER_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case USER_PROJECT_ACTION_TYPES.COMPLETE_DELETE_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case USER_PROJECT_ACTION_TYPES.EMPTY_TRASH_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.EMPTY_TRASH_USER_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case USER_PROJECT_ACTION_TYPES.EMPTY_TRASH_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case USER_PROJECT_ACTION_TYPES.EXPORT_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.EXPORT_USER_PROJECT_SUCCESS:
      return {
        ...state,
        // userProjectState: action.payload,
      };
    case USER_PROJECT_ACTION_TYPES.EXPORT_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case USER_PROJECT_ACTION_TYPES.RECOVER_ALL_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.RECOVER_ALL_USER_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case USER_PROJECT_ACTION_TYPES.RECOVER_ALL_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_USER_PROJECT_BY_ID***********************************************/
    // case USER_PROJECT_ACTION_TYPES.RECOVER_USER_PROJECT_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.RECOVER_USER_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case USER_PROJECT_ACTION_TYPES.RECOVER_USER_PROJECT_BY_ID_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************RECOVER_USER_PROJECT_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case USER_PROJECT_ACTION_TYPES.TRASH_ALL_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.TRASH_ALL_USER_PROJECT_SUCCESS:
      return { ...state };
    case USER_PROJECT_ACTION_TYPES.TRASH_ALL_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case USER_PROJECT_ACTION_TYPES.GET_ALL_TRASHED_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.GET_ALL_TRASHED_USER_PROJECT_SUCCESS:
      return {
        ...state,
        userProjectState: [...action.payload],
      };
    case USER_PROJECT_ACTION_TYPES.GET_ALL_TRASHED_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case USER_PROJECT_ACTION_TYPES.ARCHIVE_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.ARCHIVE_USER_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case USER_PROJECT_ACTION_TYPES.ARCHIVE_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case USER_PROJECT_ACTION_TYPES.GET_ALL_ARCHIVED_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.GET_ALL_ARCHIVED_USER_PROJECT_SUCCESS:
      return {
        ...state,
        userProjectState: [...action.payload],
      };
    case USER_PROJECT_ACTION_TYPES.GET_ALL_ARCHIVED_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case USER_PROJECT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_USER_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_USER_PROJECT_SUCCESS:
      return {
        ...state,
        // userProjectState: [...action.payload],
      };
    case USER_PROJECT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_USER_PROJECT_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case USER_PROJECT_ACTION_TYPES.RECOVER_ARCHIVED_USER_PROJECT_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_PROJECT_ACTION_TYPES.RECOVER_ARCHIVED_USER_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        userProjectState: [...action.payload],
      };
    case USER_PROJECT_ACTION_TYPES.RECOVER_ARCHIVED_USER_PROJECT_BY_ID_FAILURE:
      return {
        userProjectErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default USER_PROJECT_REDUCER;
