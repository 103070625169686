import TRAINING_TYPE_ACTION_TYPES from "../actionTypes/trainingTypeActionTypes";

const initialState = {
  isLoading: false,
  trainingTypeState: [],
  trainingTypeErrorState: "",
};

const TRAINING_TYPE_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case TRAINING_TYPE_ACTION_TYPES.CREATE_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
      };
    // case TRAINING_TYPE_ACTION_TYPES.CREATE_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.CREATE_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case TRAINING_TYPE_ACTION_TYPES.UPDATE_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
        trainingTypeState: state.trainingTypeState.map((actT) =>
          actT.id === action.payload ? action.payload : actT
        ),
      };
    case TRAINING_TYPE_ACTION_TYPES.UPDATE_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case TRAINING_TYPE_ACTION_TYPES.DELETE_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.DELETE_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
      };
    case TRAINING_TYPE_ACTION_TYPES.DELETE_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case TRAINING_TYPE_ACTION_TYPES.GET_ALL_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.GET_ALL_TRAINING_TYPE_SUCCESS:
      return { ...state, trainingTypeState: [...action.payload] };
    case TRAINING_TYPE_ACTION_TYPES.GET_ALL_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.GET_BY_ID_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.GET_BY_ID_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
        trainingTypeState: action.payload,
      };
    case TRAINING_TYPE_ACTION_TYPES.GET_BY_ID_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.COMPLETE_DELETE_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.COMPLETE_DELETE_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
      };
    case TRAINING_TYPE_ACTION_TYPES.COMPLETE_DELETE_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.EMPTY_TRASH_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.EMPTY_TRASH_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
      };
    case TRAINING_TYPE_ACTION_TYPES.EMPTY_TRASH_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.EXPORT_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.EXPORT_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
        // trainingTypeState: action.payload,
      };
    case TRAINING_TYPE_ACTION_TYPES.EXPORT_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.RECOVER_ALL_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_ALL_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
      };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_ALL_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_TRAINING_TYPE_BY_ID***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.RECOVER_TRAINING_TYPE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_TRAINING_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_TRAINING_TYPE_BY_ID_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************RECOVER_TRAINING_TYPE_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.TRASH_ALL_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.TRASH_ALL_TRAINING_TYPE_SUCCESS:
      return { ...state };
    case TRAINING_TYPE_ACTION_TYPES.TRASH_ALL_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.GET_ALL_TRASHED_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.GET_ALL_TRASHED_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
        trainingTypeState: [...action.payload],
      };
    case TRAINING_TYPE_ACTION_TYPES.GET_ALL_TRASHED_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.ARCHIVE_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.ARCHIVE_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
      };
    case TRAINING_TYPE_ACTION_TYPES.ARCHIVE_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.GET_ALL_ARCHIVED_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.GET_ALL_ARCHIVED_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
        trainingTypeState: [...action.payload],
      };
    case TRAINING_TYPE_ACTION_TYPES.GET_ALL_ARCHIVED_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TRAINING_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TRAINING_TYPE_SUCCESS:
      return {
        ...state,
        // trainingTypeState: [...action.payload],
      };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TRAINING_TYPE_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case TRAINING_TYPE_ACTION_TYPES.RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        trainingTypeState: [...action.payload],
      };
    case TRAINING_TYPE_ACTION_TYPES.RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_FAILURE:
      return {
        trainingTypeErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default TRAINING_TYPE_REDUCER;
