const ACTIVITY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ACTIVITY_REQUEST: "GET_ALL_ACTIVITY_REQUEST",
  GET_ALL_ACTIVITY_SUCCESS: "GET_ALL_ACTIVITY_SUCCESS",
  GET_ALL_ACTIVITY_FAILURE: "GET_ALL_ACTIVITY_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_BY_USER_PROJECT_ACTIVITY_REQUEST:
    "GET_ALL_BY_USER_PROJECT_ACTIVITY_REQUEST",
  GET_ALL_BY_USER_PROJECT_ACTIVITY_SUCCESS:
    "GET_ALL_BY_USER_PROJECT_ACTIVITY_SUCCESS",
  GET_ALL_BY_USER_PROJECT_ACTIVITY_FAILURE:
    "GET_ALL_BY_USER_PROJECT_ACTIVITY_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_NON_ASSIGNED_ACTIVITY_REQUEST:
    "GET_ALL_NON_ASSIGNED_ACTIVITY_REQUEST",
  GET_ALL_NON_ASSIGNED_ACTIVITY_SUCCESS:
    "GET_ALL_NON_ASSIGNED_ACTIVITY_SUCCESS",
  GET_ALL_NON_ASSIGNED_ACTIVITY_FAILURE:
    "GET_ALL_NON_ASSIGNED_ACTIVITY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ACTIVITY_REQUEST: "GET_BY_ID_ACTIVITY_REQUEST",
  GET_BY_ID_ACTIVITY_SUCCESS: "GET_BY_ID_ACTIVITY_SUCCESS",
  GET_BY_ID_ACTIVITY_FAILURE: "GET_BY_ID_ACTIVITY_FAILURE",

  /************************************************************************* */
  CREATE_ACTIVITY_REQUEST: "CREATE_ACTIVITY_REQUEST",
  CREATE_ACTIVITY_SUCCESS: "CREATE_ACTIVITY_SUCCESS",
  CREATE_ACTIVITY_FAILURE: "CREATE_ACTIVITY_FAILURE",

  /************************************************************************* */
  UPDATE_ACTIVITY_REQUEST: "UPDATE_ACTIVITY_REQUEST",
  UPDATE_ACTIVITY_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE: "UPDATE_ACTIVITY_FAILURE",

  /************************************************************************* */
  DELETE_ACTIVITY_REQUEST: "DELETE_ACTIVITY_REQUEST",
  DELETE_ACTIVITY_SUCCESS: "DELETE_ACTIVITY_SUCCESS",
  DELETE_ACTIVITY_FAILURE: "DELETE_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ACTIVITY_BY_ID_REQUEST: "RECOVER_BY_ID_ACTIVITY_BY_ID_REQUEST",
  RECOVER_ACTIVITY_BY_ID_SUCCESS: "RECOVER_ACTIVITY_BY_ID_SUCCESS",
  RECOVER_ACTIVITY_BY_ID_FAILURE: "RECOVER_ACTIVITY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ACTIVITY_REQUEST: "RECOVER_ALL_ACTIVITY_REQUEST",
  RECOVER_ALL_ACTIVITY_SUCCESS: "RECOVER_ALL_ACTIVITY_SUCCESS",
  RECOVER_ALL_ACTIVITY_FAILURE: "RECOVER_ALL_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ACTIVITY_REQUEST: "COMPLETE_DELETE_ACTIVITY_REQUEST",
  COMPLETE_DELETE_ACTIVITY_SUCCESS: "COMPLETE_DELETE_ACTIVITY_SUCCESS",
  COMPLETE_DELETE_ACTIVITY_FAILURE: "COMPLETE_DELETE_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ACTIVITY_REQUEST: "TRASH_ALL_ACTIVITY_REQUEST",
  TRASH_ALL_ACTIVITY_SUCCESS: "TRASH_ALL_ACTIVITY_SUCCESS",
  TRASH_ALL_ACTIVITY_FAILURE: "TRASH_ALL_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ACTIVITY_REQUEST: "EMPTY_TRASH_ACTIVITY_REQUEST",
  EMPTY_TRASH_ACTIVITY_SUCCESS: "EMPTY_TRASH_ACTIVITY_SUCCESS",
  EMPTY_TRASH_ACTIVITY_FAILURE: "EMPTY_TRASH_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ACTIVITY_REQUEST: "EXPORT_ACTIVITY_REQUEST",
  EXPORT_ACTIVITY_SUCCESS: "EXPORT_ACTIVITY_SUCCESS",
  EXPORT_ACTIVITY_FAILURE: "EXPORT_ACTIVITY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ACTIVITY_REQUEST: "GET_ALL_TRASHED_ACTIVITY_REQUEST",
  GET_ALL_TRASHED_ACTIVITY_SUCCESS: "GET_ALL_TRASHED_ACTIVITY_SUCCESS",
  GET_ALL_TRASHED_ACTIVITY_FAILURE: "GET_ALL_TRASHED_ACTIVITY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ACTIVITY_REQUEST: "ARCHIVE_ACTIVITY_REQUEST",
  ARCHIVE_ACTIVITY_SUCCESS: "ARCHIVE_ACTIVITY_SUCCESS",
  ARCHIVE_ACTIVITY_FAILURE: "ARCHIVE_ACTIVITY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ACTIVITY_REQUEST: "GET_ALL_ARCHIVED_ACTIVITY_REQUEST",
  GET_ALL_ARCHIVED_ACTIVITY_SUCCESS: "GET_ALL_ARCHIVED_ACTIVITY_SUCCESS",
  GET_ALL_ARCHIVED_ACTIVITY_FAILURE: "GET_ALL_ARCHIVED_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ACTIVITY_REQUEST:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_REQUEST",
  RECOVER_ALL_ARCHIVED_ACTIVITY_SUCCESS:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_SUCCESS",
  RECOVER_ALL_ARCHIVED_ACTIVITY_FAILURE:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ACTIVITY_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ACTIVITY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ACTIVITY_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ACTIVITY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ACTIVITY_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ACTIVITY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_ACTIVITY_BY_PROJECT_ID_REQUEST:
    "GET_ALL_ACTIVITY_BY_PROJECT_ID_REQUEST",
  GET_ALL_ACTIVITY_BY_PROJECT_ID_SUCCESS:
    "GET_ALL_ACTIVITY_BY_PROJECT_ID_SUCCESS",
  GET_ALL_ACTIVITY_BY_PROJECT_ID_FAILURE:
    "GET_ALL_ACTIVITY_BY_PROJECT_ID_FAILURE",
  /************************************************************************* */
};

export default ACTIVITY_ACTION_TYPES;
