const TOR_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_TOR_REQUEST: "GET_ALL_TOR_REQUEST",
  GET_ALL_TOR_SUCCESS: "GET_ALL_TOR_SUCCESS",
  GET_ALL_TOR_FAILURE: "GET_ALL_TOR_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_PROJECT_TOR_BY_USER_ID_REQUEST:
    "GET_ALL_PROJECT_TOR_BY_USER_ID_REQUEST",
  GET_ALL_PROJECT_TOR_BY_USER_ID_SUCCESS:
    "GET_ALL_PROJECT_TOR_BY_USER_ID_SUCCESS",
  GET_ALL_PROJECT_TOR_BY_USER_ID_FAILURE:
    "GET_ALL_PROJECT_TOR_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_REQUEST:
    "GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_REQUEST",
  GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_SUCCESS:
    "GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_SUCCESS",
  GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_FAILURE:
    "GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_REQUEST:
    "GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_REQUEST",
  GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_SUCCESS:
    "GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_SUCCESS",
  GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_FAILURE:
    "GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SEND_NEW_TOR_FOR_SUPERRVISOR_REQUEST: "SEND_NEW_TOR_FOR_SUPERRVISOR_REQUEST",
  SEND_NEW_TOR_FOR_SUPERRVISOR_SUCCESS: "SEND_NEW_TOR_FOR_SUPERRVISOR_SUCCESS",
  SEND_NEW_TOR_FOR_SUPERRVISOR_FAILURE: "SEND_NEW_TOR_FOR_SUPERRVISOR_FAILURE",

  /************************************************************************* */
  GET_BY_ID_TOR_REQUEST: "GET_BY_ID_TOR_REQUEST",
  GET_BY_ID_TOR_SUCCESS: "GET_BY_ID_TOR_SUCCESS",
  GET_BY_ID_TOR_FAILURE: "GET_BY_ID_TOR_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_REQUEST:
    "GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_REQUEST",
  GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_SUCCESS:
    "GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_SUCCESS",
  GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_FAILURE:
    "GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    "GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
    "GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
    "GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_BY_ID_TOR_FOR_EDIT_REQUEST: "GET_BY_ID_TOR_FOR_EDIT_REQUEST",
  GET_BY_ID_TOR_FOR_EDIT_SUCCESS: "GET_BY_ID_TOR_FOR_EDIT_SUCCESS",
  GET_BY_ID_TOR_FOR_EDIT_FAILURE: "GET_BY_ID_TOR_FOR_EDIT_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_REQUEST:
    "SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_REQUEST",
  SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
    "SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_SUCCESS",
  SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_FAILURE:
    "SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  PROGRAM_REJECT_TOR_FOR_MODIFICATION_REQUEST:
    "PROGRAM_REJECT_TOR_FOR_MODIFICATION_REQUEST",
  PROGRAM_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
    "PROGRAM_REJECT_TOR_FOR_MODIFICATION_SUCCESS",
  PROGRAM_REJECT_TOR_FOR_MODIFICATION_FAILURE:
    "PROGRAM_REJECT_TOR_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  FINANCE_REJECT_TOR_FOR_MODIFICATION_REQUEST:
    "FINANCE_REJECT_TOR_FOR_MODIFICATION_REQUEST",
  FINANCE_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
    "FINANCE_REJECT_TOR_FOR_MODIFICATION_SUCCESS",
  FINANCE_REJECT_TOR_FOR_MODIFICATION_FAILURE:
    "FINANCE_REJECT_TOR_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CEO_REJECT_TOR_FOR_MODIFICATION_REQUEST:
    "CEO_REJECT_TOR_FOR_MODIFICATION_REQUEST",
  CEO_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
    "CEO_REJECT_TOR_FOR_MODIFICATION_SUCCESS",
  CEO_REJECT_TOR_FOR_MODIFICATION_FAILURE:
    "CEO_REJECT_TOR_FOR_MODIFICATION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SUPERVISOR_REJECT_TOR_ALTOGETHER_REQUEST:
    "SUPERVISOR_REJECT_TOR_ALTOGETHER_REQUEST",
  SUPERVISOR_REJECT_TOR_ALTOGETHER_SUCCESS:
    "SUPERVISOR_REJECT_TOR_ALTOGETHER_SUCCESS",
  SUPERVISOR_REJECT_TOR_ALTOGETHER_FAILURE:
    "SUPERVISOR_REJECT_TOR_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  PROGRAM_REJECT_TOR_ALTOGETHER_REQUEST:
    "PROGRAM_REJECT_TOR_ALTOGETHER_REQUEST",
  PROGRAM_REJECT_TOR_ALTOGETHER_SUCCESS:
    "PROGRAM_REJECT_TOR_ALTOGETHER_SUCCESS",
  PROGRAM_REJECT_TOR_ALTOGETHER_FAILURE:
    "PROGRAM_REJECT_TOR_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  FINANCE_REJECT_TOR_ALTOGETHER_REQUEST:
    "FINANCE_REJECT_TOR_ALTOGETHER_REQUEST",
  FINANCE_REJECT_TOR_ALTOGETHER_SUCCESS:
    "FINANCE_REJECT_TOR_ALTOGETHER_SUCCESS",
  FINANCE_REJECT_TOR_ALTOGETHER_FAILURE:
    "FINANCE_REJECT_TOR_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CEO_REJECT_TOR_ALTOGETHER_REQUEST: "CEO_REJECT_TOR_ALTOGETHER_REQUEST",
  CEO_REJECT_TOR_ALTOGETHER_SUCCESS: "CEO_REJECT_TOR_ALTOGETHER_SUCCESS",
  CEO_REJECT_TOR_ALTOGETHER_FAILURE: "CEO_REJECT_TOR_ALTOGETHER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  SUPERVISOR_APPROVE_REQUEST: "SUPERVISOR_APPROVE_REQUEST",
  SUPERVISOR_APPROVE_SUCCESS: "SUPERVISOR_APPROVE_SUCCESS",
  SUPERVISOR_APPROVE_FAILURE: "SUPERVISOR_APPROVE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  PROGRAM_APPROVE_REQUEST: "PROGRAM_APPROVE_REQUEST",
  PROGRAM_APPROVE_SUCCESS: "PROGRAM_APPROVE_SUCCESS",
  PROGRAM_APPROVE_FAILURE: "PROGRAM_APPROVE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  FINANCE_APPROVE_REQUEST: "FINANCE_APPROVE_REQUEST",
  FINANCE_APPROVE_SUCCESS: "FINANCE_APPROVE_SUCCESS",
  FINANCE_APPROVE_FAILURE: "FINANCE_APPROVE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CEO_APPROVE_REQUEST: "CEO_APPROVE_REQUEST",
  CEO_APPROVE_SUCCESS: "CEO_APPROVE_SUCCESS",
  CEO_APPROVE_FAILURE: "CEO_APPROVE_FAILURE",

  /************************************************************************* */
  CREATE_TOR_REQUEST: "CREATE_TOR_REQUEST",
  CREATE_TOR_SUCCESS: "CREATE_TOR_SUCCESS",
  CREATE_TOR_FAILURE: "CREATE_TOR_FAILURE",

  /************************************************************************* */
  UPDATE_TOR_REQUEST: "UPDATE_TOR_REQUEST",
  UPDATE_TOR_SUCCESS: "UPDATE_TOR_SUCCESS",
  UPDATE_TOR_FAILURE: "UPDATE_TOR_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_REQUEST:
    "UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_REQUEST",
  UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_SUCCESS:
    "UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_SUCCESS",
  UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_FAILURE:
    "UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_FAILURE",

  /************************************************************************* */
  DELETE_TOR_REQUEST: "DELETE_TOR_REQUEST",
  DELETE_TOR_SUCCESS: "DELETE_TOR_SUCCESS",
  DELETE_TOR_FAILURE: "DELETE_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_TOR_BY_ID_REQUEST: "RECOVER_BY_ID_TOR_BY_ID_REQUEST",
  RECOVER_TOR_BY_ID_SUCCESS: "RECOVER_TOR_BY_ID_SUCCESS",
  RECOVER_TOR_BY_ID_FAILURE: "RECOVER_TOR_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_TOR_REQUEST: "RECOVER_ALL_TOR_REQUEST",
  RECOVER_ALL_TOR_SUCCESS: "RECOVER_ALL_TOR_SUCCESS",
  RECOVER_ALL_TOR_FAILURE: "RECOVER_ALL_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_TOR_REQUEST: "COMPLETE_DELETE_TOR_REQUEST",
  COMPLETE_DELETE_TOR_SUCCESS: "COMPLETE_DELETE_TOR_SUCCESS",
  COMPLETE_DELETE_TOR_FAILURE: "COMPLETE_DELETE_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_TOR_REQUEST: "TRASH_ALL_TOR_REQUEST",
  TRASH_ALL_TOR_SUCCESS: "TRASH_ALL_TOR_SUCCESS",
  TRASH_ALL_TOR_FAILURE: "TRASH_ALL_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_TOR_REQUEST: "EMPTY_TRASH_TOR_REQUEST",
  EMPTY_TRASH_TOR_SUCCESS: "EMPTY_TRASH_TOR_SUCCESS",
  EMPTY_TRASH_TOR_FAILURE: "EMPTY_TRASH_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_TOR_REQUEST: "EXPORT_TOR_REQUEST",
  EXPORT_TOR_SUCCESS: "EXPORT_TOR_SUCCESS",
  EXPORT_TOR_FAILURE: "EXPORT_TOR_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_TOR_REQUEST: "GET_ALL_TRASHED_TOR_REQUEST",
  GET_ALL_TRASHED_TOR_SUCCESS: "GET_ALL_TRASHED_TOR_SUCCESS",
  GET_ALL_TRASHED_TOR_FAILURE: "GET_ALL_TRASHED_TOR_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_TOR_REQUEST: "ARCHIVE_TOR_REQUEST",
  ARCHIVE_TOR_SUCCESS: "ARCHIVE_TOR_SUCCESS",
  ARCHIVE_TOR_FAILURE: "ARCHIVE_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  ARCHIVE_SELECTED_TOR_REQUEST: "ARCHIVE_SELECTED_TOR_REQUEST",
  ARCHIVE_SELECTED_TOR_SUCCESS: "ARCHIVE_SELECTED_TOR_SUCCESS",
  ARCHIVE_SELECTED_TOR_FAILURE: "ARCHIVE_SELECTED_TOR_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_TOR_REQUEST: "GET_ALL_ARCHIVED_TOR_REQUEST",
  GET_ALL_ARCHIVED_TOR_SUCCESS: "GET_ALL_ARCHIVED_TOR_SUCCESS",
  GET_ALL_ARCHIVED_TOR_FAILURE: "GET_ALL_ARCHIVED_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_TOR_REQUEST: "RECOVER_ALL_ARCHIVED_TOR_REQUEST",
  RECOVER_ALL_ARCHIVED_TOR_SUCCESS: "RECOVER_ALL_ARCHIVED_TOR_SUCCESS",
  RECOVER_ALL_ARCHIVED_TOR_FAILURE: "RECOVER_ALL_ARCHIVED_TOR_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_TOR_BY_ID_REQUEST: "RECOVER_ARCHIVED_TOR_BY_ID_REQUEST",
  RECOVER_ARCHIVED_TOR_BY_ID_SUCCESS: "RECOVER_ARCHIVED_TOR_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_TOR_BY_ID_FAILURE: "RECOVER_ARCHIVED_TOR_BY_ID_FAILURE",
  /************************************************************************* */
};

export default TOR_ACTION_TYPES;
