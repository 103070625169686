import TRADE_COMPANY_ACTION_TYPES from "../actionTypes/tradeCompanyActionTypes";

const initialState = {
  isLoading: false,
  tradeCompanyState: [],
  tradeCompanyErrorState: "",
};

const TRADE_COMPANY_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case TRADE_COMPANY_ACTION_TYPES.CREATE_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
      };
    // case TRADE_COMPANY_ACTION_TYPES.CREATE_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.CREATE_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case TRADE_COMPANY_ACTION_TYPES.UPDATE_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
        tradeCompanyState: state.tradeCompanyState.map((tCom) =>
          tCom.id === action.payload ? action.payload : tCom
        ),
      };
    case TRADE_COMPANY_ACTION_TYPES.UPDATE_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case TRADE_COMPANY_ACTION_TYPES.DELETE_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.DELETE_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
      };
    case TRADE_COMPANY_ACTION_TYPES.DELETE_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case TRADE_COMPANY_ACTION_TYPES.GET_ALL_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.GET_ALL_TRADE_COMPANY_SUCCESS:
      return { ...state, tradeCompanyState: [...action.payload] };
    case TRADE_COMPANY_ACTION_TYPES.GET_ALL_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.GET_BY_ID_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.GET_BY_ID_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
        tradeCompanyState: action.payload,
      };
    case TRADE_COMPANY_ACTION_TYPES.GET_BY_ID_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.COMPLETE_DELETE_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.COMPLETE_DELETE_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
      };
    case TRADE_COMPANY_ACTION_TYPES.COMPLETE_DELETE_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.EMPTY_TRASH_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.EMPTY_TRASH_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
      };
    case TRADE_COMPANY_ACTION_TYPES.EMPTY_TRASH_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.EXPORT_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.EXPORT_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
        // tradeCompanyState: action.payload,
      };
    case TRADE_COMPANY_ACTION_TYPES.EXPORT_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.RECOVER_ALL_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_ALL_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
      };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_ALL_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_TRADE_COMPANY_BY_ID***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.RECOVER_TRADE_COMPANY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_TRADE_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_TRADE_COMPANY_BY_ID_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************RECOVER_TRADE_COMPANY_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.TRASH_ALL_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.TRASH_ALL_TRADE_COMPANY_SUCCESS:
      return { ...state };
    case TRADE_COMPANY_ACTION_TYPES.TRASH_ALL_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.GET_ALL_TRASHED_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.GET_ALL_TRASHED_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
        tradeCompanyState: [...action.payload],
      };
    case TRADE_COMPANY_ACTION_TYPES.GET_ALL_TRASHED_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.ARCHIVE_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.ARCHIVE_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
      };
    case TRADE_COMPANY_ACTION_TYPES.ARCHIVE_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.GET_ALL_ARCHIVED_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.GET_ALL_ARCHIVED_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
        tradeCompanyState: [...action.payload],
      };
    case TRADE_COMPANY_ACTION_TYPES.GET_ALL_ARCHIVED_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TRADE_COMPANY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TRADE_COMPANY_SUCCESS:
      return {
        ...state,
        // tradeCompanyState: [...action.payload],
      };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TRADE_COMPANY_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case TRADE_COMPANY_ACTION_TYPES.RECOVER_ARCHIVED_TRADE_COMPANY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_ARCHIVED_TRADE_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        tradeCompanyState: [...action.payload],
      };
    case TRADE_COMPANY_ACTION_TYPES.RECOVER_ARCHIVED_TRADE_COMPANY_BY_ID_FAILURE:
      return {
        tradeCompanyErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default TRADE_COMPANY_REDUCER;
