import DONNER_ACTION_TYPES from "../actionTypes/donnerActionTypes";

const initialState = {
  isLoading: false,
  donnerState: [],
  donnerErrorState: "",
};

const DONNER_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case DONNER_ACTION_TYPES.CREATE_DONNER_SUCCESS:
      return {
        ...state,
      };
    // case DONNER_ACTION_TYPES.CREATE_DONNER_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case DONNER_ACTION_TYPES.CREATE_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case DONNER_ACTION_TYPES.UPDATE_DONNER_SUCCESS:
      return {
        ...state,
        donnerState: state.donnerState.map((d) =>
          d.id === action.payload ? action.payload : d
        ),
      };
    case DONNER_ACTION_TYPES.UPDATE_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case DONNER_ACTION_TYPES.DELETE_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.DELETE_DONNER_SUCCESS:
      return {
        ...state,
      };
    case DONNER_ACTION_TYPES.DELETE_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case DONNER_ACTION_TYPES.GET_ALL_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.GET_ALL_DONNER_SUCCESS:
      return { ...state, donnerState: [...action.payload] };
    case DONNER_ACTION_TYPES.GET_ALL_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case DONNER_ACTION_TYPES.GET_BY_ID_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.GET_BY_ID_DONNER_SUCCESS:
      return {
        ...state,
        donnerState: action.payload,
      };
    case DONNER_ACTION_TYPES.GET_BY_ID_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case DONNER_ACTION_TYPES.COMPLETE_DELETE_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.COMPLETE_DELETE_DONNER_SUCCESS:
      return {
        ...state,
      };
    case DONNER_ACTION_TYPES.COMPLETE_DELETE_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case DONNER_ACTION_TYPES.EMPTY_TRASH_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.EMPTY_TRASH_DONNER_SUCCESS:
      return {
        ...state,
      };
    case DONNER_ACTION_TYPES.EMPTY_TRASH_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case DONNER_ACTION_TYPES.EXPORT_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.EXPORT_DONNER_SUCCESS:
      return {
        ...state,
        // donnerState: action.payload,
      };
    case DONNER_ACTION_TYPES.EXPORT_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case DONNER_ACTION_TYPES.RECOVER_ALL_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.RECOVER_ALL_DONNER_SUCCESS:
      return {
        ...state,
      };
    case DONNER_ACTION_TYPES.RECOVER_ALL_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_DONNER_BY_ID***********************************************/
    // case DONNER_ACTION_TYPES.RECOVER_DONNER_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.RECOVER_DONNER_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case DONNER_ACTION_TYPES.RECOVER_DONNER_BY_ID_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************RECOVER_DONNER_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case DONNER_ACTION_TYPES.TRASH_ALL_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.TRASH_ALL_DONNER_SUCCESS:
      return { ...state };
    case DONNER_ACTION_TYPES.TRASH_ALL_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case DONNER_ACTION_TYPES.GET_ALL_TRASHED_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.GET_ALL_TRASHED_DONNER_SUCCESS:
      return {
        ...state,
        donnerState: [...action.payload],
      };
    case DONNER_ACTION_TYPES.GET_ALL_TRASHED_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case DONNER_ACTION_TYPES.ARCHIVE_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.ARCHIVE_DONNER_SUCCESS:
      return {
        ...state,
      };
    case DONNER_ACTION_TYPES.ARCHIVE_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case DONNER_ACTION_TYPES.GET_ALL_ARCHIVED_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.GET_ALL_ARCHIVED_DONNER_SUCCESS:
      return {
        ...state,
        donnerState: [...action.payload],
      };
    case DONNER_ACTION_TYPES.GET_ALL_ARCHIVED_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case DONNER_ACTION_TYPES.RECOVER_ALL_ARCHIVED_DONNER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.RECOVER_ALL_ARCHIVED_DONNER_SUCCESS:
      return {
        ...state,
        // donnerState: [...action.payload],
      };
    case DONNER_ACTION_TYPES.RECOVER_ALL_ARCHIVED_DONNER_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case DONNER_ACTION_TYPES.RECOVER_ARCHIVED_DONNER_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.RECOVER_ARCHIVED_DONNER_BY_ID_SUCCESS:
      return {
        ...state,
        donnerState: [...action.payload],
      };
    case DONNER_ACTION_TYPES.RECOVER_ARCHIVED_DONNER_BY_ID_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */

    //*********************UPLOAD_DONNER_LOGO***********************************************/
    // case DONNER_ACTION_TYPES.UPLOAD_DONNER_LOGO_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.UPLOAD_DONNER_LOGO_SUCCESS:
      return {
        ...state,
        donnerState: [...action.payload],
      };
    case DONNER_ACTION_TYPES.UPLOAD_DONNER_LOGO_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************UPLOAD_DONNER_LOGO*********************************************** */

    //********************* DOWNLOAD_LOGO***********************************************/
    // case DONNER_ACTION_TYPES.DOWNLOAD_DONNER_LOGO_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case DONNER_ACTION_TYPES.DOWNLOAD_DONNER_LOGO_SUCCESS:
      return {
        ...state,
        donnerState: [...action.payload],
      };
    case DONNER_ACTION_TYPES.DOWNLOAD_DONNER_LOGO_FAILURE:
      return {
        donnerErrorState: action.payload,
      };
    //*********************DOWNLOAD_LOGO_*********************************************** */
    default:
      return state;
  }
};

export default DONNER_REDUCER;
