const RIGHT_HOLDER_CONTACT_PERSON_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "GET_ALL_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  GET_ALL_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "GET_ALL_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  GET_ALL_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "GET_ALL_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  GET_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "GET_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  GET_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "GET_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  GET_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "GET_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  CREATE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "CREATE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  CREATE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "CREATE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  CREATE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "CREATE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  UPDATE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "UPDATE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  UPDATE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "UPDATE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  UPDATE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "UPDATE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  DELETE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "DELETE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  DELETE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "DELETE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  DELETE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "DELETE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_REQUEST:
    "RECOVER_BY_ID_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_REQUEST",
  RECOVER_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_SUCCESS:
    "RECOVER_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_SUCCESS",
  RECOVER_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_FAILURE:
    "RECOVER_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "RECOVER_ALL_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  RECOVER_ALL_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "RECOVER_ALL_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  RECOVER_ALL_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "RECOVER_ALL_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "COMPLETE_DELETE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  COMPLETE_DELETE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "COMPLETE_DELETE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  COMPLETE_DELETE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "COMPLETE_DELETE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "TRASH_ALL_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  TRASH_ALL_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "TRASH_ALL_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  TRASH_ALL_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "TRASH_ALL_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "EMPTY_TRASH_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  EMPTY_TRASH_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "EMPTY_TRASH_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  EMPTY_TRASH_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "EMPTY_TRASH_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "EXPORT_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  EXPORT_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "EXPORT_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  EXPORT_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "EXPORT_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "GET_ALL_TRASHED_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  GET_ALL_TRASHED_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "GET_ALL_TRASHED_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  GET_ALL_TRASHED_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "GET_ALL_TRASHED_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "ARCHIVE_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  ARCHIVE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "ARCHIVE_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  ARCHIVE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "ARCHIVE_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "GET_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  GET_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "GET_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  GET_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "GET_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_REQUEST:
    "RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_REQUEST",
  RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS:
    "RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_SUCCESS",
  RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_FAILURE:
    "RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_REQUEST",
  RECOVER_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_RIGHT_HOLDER_CONTACT_PERSON_BY_ID_FAILURE",
  /************************************************************************* */
};

export default RIGHT_HOLDER_CONTACT_PERSON_ACTION_TYPES;
