const USER_PROJECT_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_USER_PROJECT_REQUEST: "GET_ALL_USER_PROJECT_REQUEST",
  GET_ALL_USER_PROJECT_SUCCESS: "GET_ALL_USER_PROJECT_SUCCESS",
  GET_ALL_USER_PROJECT_FAILURE: "GET_ALL_USER_PROJECT_FAILURE",

  /************************************************************************* */
  GET_BY_ID_USER_PROJECT_REQUEST: "GET_BY_ID_USER_PROJECT_REQUEST",
  GET_BY_ID_USER_PROJECT_SUCCESS: "GET_BY_ID_USER_PROJECT_SUCCESS",
  GET_BY_ID_USER_PROJECT_FAILURE: "GET_BY_ID_USER_PROJECT_FAILURE",

  /************************************************************************* */
  CREATE_USER_PROJECT_REQUEST: "CREATE_USER_PROJECT_REQUEST",
  CREATE_USER_PROJECT_SUCCESS: "CREATE_USER_PROJECT_SUCCESS",
  CREATE_USER_PROJECT_FAILURE: "CREATE_USER_PROJECT_FAILURE",

  /************************************************************************* */
  UPDATE_USER_PROJECT_REQUEST: "UPDATE_USER_PROJECT_REQUEST",
  UPDATE_USER_PROJECT_SUCCESS: "UPDATE_USER_PROJECT_SUCCESS",
  UPDATE_USER_PROJECT_FAILURE: "UPDATE_USER_PROJECT_FAILURE",

  /************************************************************************* */
  DELETE_USER_PROJECT_REQUEST: "DELETE_USER_PROJECT_REQUEST",
  DELETE_USER_PROJECT_SUCCESS: "DELETE_USER_PROJECT_SUCCESS",
  DELETE_USER_PROJECT_FAILURE: "DELETE_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_USER_PROJECT_BY_ID_REQUEST:
    "RECOVER_BY_ID_USER_PROJECT_BY_ID_REQUEST",
  RECOVER_USER_PROJECT_BY_ID_SUCCESS: "RECOVER_USER_PROJECT_BY_ID_SUCCESS",
  RECOVER_USER_PROJECT_BY_ID_FAILURE: "RECOVER_USER_PROJECT_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_USER_PROJECT_REQUEST: "RECOVER_ALL_USER_PROJECT_REQUEST",
  RECOVER_ALL_USER_PROJECT_SUCCESS: "RECOVER_ALL_USER_PROJECT_SUCCESS",
  RECOVER_ALL_USER_PROJECT_FAILURE: "RECOVER_ALL_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_USER_PROJECT_REQUEST: "COMPLETE_DELETE_USER_PROJECT_REQUEST",
  COMPLETE_DELETE_USER_PROJECT_SUCCESS: "COMPLETE_DELETE_USER_PROJECT_SUCCESS",
  COMPLETE_DELETE_USER_PROJECT_FAILURE: "COMPLETE_DELETE_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_USER_PROJECT_REQUEST: "TRASH_ALL_USER_PROJECT_REQUEST",
  TRASH_ALL_USER_PROJECT_SUCCESS: "TRASH_ALL_USER_PROJECT_SUCCESS",
  TRASH_ALL_USER_PROJECT_FAILURE: "TRASH_ALL_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_USER_PROJECT_REQUEST: "EMPTY_TRASH_USER_PROJECT_REQUEST",
  EMPTY_TRASH_USER_PROJECT_SUCCESS: "EMPTY_TRASH_USER_PROJECT_SUCCESS",
  EMPTY_TRASH_USER_PROJECT_FAILURE: "EMPTY_TRASH_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_USER_PROJECT_REQUEST: "EXPORT_USER_PROJECT_REQUEST",
  EXPORT_USER_PROJECT_SUCCESS: "EXPORT_USER_PROJECT_SUCCESS",
  EXPORT_USER_PROJECT_FAILURE: "EXPORT_USER_PROJECT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_USER_PROJECT_REQUEST: "GET_ALL_TRASHED_USER_PROJECT_REQUEST",
  GET_ALL_TRASHED_USER_PROJECT_SUCCESS: "GET_ALL_TRASHED_USER_PROJECT_SUCCESS",
  GET_ALL_TRASHED_USER_PROJECT_FAILURE: "GET_ALL_TRASHED_USER_PROJECT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_USER_PROJECT_REQUEST: "ARCHIVE_USER_PROJECT_REQUEST",
  ARCHIVE_USER_PROJECT_SUCCESS: "ARCHIVE_USER_PROJECT_SUCCESS",
  ARCHIVE_USER_PROJECT_FAILURE: "ARCHIVE_USER_PROJECT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_USER_PROJECT_REQUEST:
    "GET_ALL_ARCHIVED_USER_PROJECT_REQUEST",
  GET_ALL_ARCHIVED_USER_PROJECT_SUCCESS:
    "GET_ALL_ARCHIVED_USER_PROJECT_SUCCESS",
  GET_ALL_ARCHIVED_USER_PROJECT_FAILURE:
    "GET_ALL_ARCHIVED_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_USER_PROJECT_REQUEST:
    "RECOVER_ALL_ARCHIVED_USER_PROJECT_REQUEST",
  RECOVER_ALL_ARCHIVED_USER_PROJECT_SUCCESS:
    "RECOVER_ALL_ARCHIVED_USER_PROJECT_SUCCESS",
  RECOVER_ALL_ARCHIVED_USER_PROJECT_FAILURE:
    "RECOVER_ALL_ARCHIVED_USER_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_USER_PROJECT_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_USER_PROJECT_BY_ID_REQUEST",
  RECOVER_ARCHIVED_USER_PROJECT_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_USER_PROJECT_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_USER_PROJECT_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_USER_PROJECT_BY_ID_FAILURE",
  /************************************************************************* */
};

export default USER_PROJECT_ACTION_TYPES;
