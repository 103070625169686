import PERMISSION_ACTION_TYPES from "../actionTypes/permissionActionTypes";

const initialState = {
  isLoading: false,
  permissionState: [],
  permissionErrorState: "",
  permissionByContollerState: [],
  permissionByRoleByContollerState: [],
};

const PERMISSION_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case PERMISSION_ACTION_TYPES.CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
      };
    // case PERMISSION_ACTION_TYPES.CREATE_PERMISSION_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case PERMISSION_ACTION_TYPES.CREATE_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case PERMISSION_ACTION_TYPES.UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        permissionState: state.permissionState.map((per) =>
          per.id === action.payload ? action.payload : per
        ),
      };
    case PERMISSION_ACTION_TYPES.UPDATE_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case PERMISSION_ACTION_TYPES.DELETE_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_ACTION_TYPES.DELETE_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_SUCCESS:
      return { ...state, permissionState: [...action.payload] };
    case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_BY_CONTROLLER_SUCCESS:
      return { ...state, permissionByContollerState: [...action.payload] };
    case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_BY_CONTROLLER_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_SUCCESS:
      return {
        ...state,
        permissionByRoleByContollerState: [...action.payload],
      };
    case PERMISSION_ACTION_TYPES.GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case PERMISSION_ACTION_TYPES.GET_BY_ID_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.GET_BY_ID_PERMISSION_SUCCESS:
      return {
        ...state,
        permissionState: action.payload,
      };
    case PERMISSION_ACTION_TYPES.GET_BY_ID_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case PERMISSION_ACTION_TYPES.COMPLETE_DELETE_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.COMPLETE_DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_ACTION_TYPES.COMPLETE_DELETE_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case PERMISSION_ACTION_TYPES.EMPTY_TRASH_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.EMPTY_TRASH_PERMISSION_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_ACTION_TYPES.EMPTY_TRASH_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case PERMISSION_ACTION_TYPES.EXPORT_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.EXPORT_PERMISSION_SUCCESS:
      return {
        ...state,
        // permissionState: action.payload,
      };
    case PERMISSION_ACTION_TYPES.EXPORT_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case PERMISSION_ACTION_TYPES.RECOVER_ALL_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.RECOVER_ALL_PERMISSION_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_ACTION_TYPES.RECOVER_ALL_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_PERMISSION_BY_ID***********************************************/
    // case PERMISSION_ACTION_TYPES.RECOVER_PERMISSION_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.RECOVER_PERMISSION_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_ACTION_TYPES.RECOVER_PERMISSION_BY_ID_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************RECOVER_PERMISSION_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case PERMISSION_ACTION_TYPES.TRASH_ALL_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.TRASH_ALL_PERMISSION_SUCCESS:
      return { ...state };
    case PERMISSION_ACTION_TYPES.TRASH_ALL_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case PERMISSION_ACTION_TYPES.GET_ALL_TRASHED_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.GET_ALL_TRASHED_PERMISSION_SUCCESS:
      return {
        ...state,
        permissionState: [...action.payload],
      };
    case PERMISSION_ACTION_TYPES.GET_ALL_TRASHED_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case PERMISSION_ACTION_TYPES.ARCHIVE_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.ARCHIVE_PERMISSION_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_ACTION_TYPES.ARCHIVE_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case PERMISSION_ACTION_TYPES.GET_ALL_ARCHIVED_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.GET_ALL_ARCHIVED_PERMISSION_SUCCESS:
      return {
        ...state,
        permissionState: [...action.payload],
      };
    case PERMISSION_ACTION_TYPES.GET_ALL_ARCHIVED_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case PERMISSION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PERMISSION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PERMISSION_SUCCESS:
      return {
        ...state,
        // permissionState: [...action.payload],
      };
    case PERMISSION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PERMISSION_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case PERMISSION_ACTION_TYPES.RECOVER_ARCHIVED_PERMISSION_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_ACTION_TYPES.RECOVER_ARCHIVED_PERMISSION_BY_ID_SUCCESS:
      return {
        ...state,
        permissionState: [...action.payload],
      };
    case PERMISSION_ACTION_TYPES.RECOVER_ARCHIVED_PERMISSION_BY_ID_FAILURE:
      return {
        permissionErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default PERMISSION_REDUCER;
