import PROJECT_ACTION_TYPES from "../actionTypes/projectActionTypes";

const initialState = {
  isLoading: false,
  projectState: [],
  projectByUserIdState: [],
  projectGroupedUsersState: [],
  projectGroupedUserByProjectState: [],
  projectErrorState: "",
  projectUsersErrorState: "",
  projectSpecificObjectivesState: [],
};

const PROJECT_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case PROJECT_ACTION_TYPES.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
      };
    // case PROJECT_ACTION_TYPES.CREATE_PROJECT_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case PROJECT_ACTION_TYPES.CREATE_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectState: state.projectState.map((proj) =>
          proj.id === action.payload ? action.payload : proj
        ),
      };
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case PROJECT_ACTION_TYPES.DELETE_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case PROJECT_ACTION_TYPES.DELETE_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_SUCCESS:
      return { ...state, projectState: [...action.payload] };
    case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL_PROJECT_BY_USER_ID******************************************/

    // case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_BY_USER_ID_SUCCESS:
      return { ...state, projectByUserIdState: [...action.payload] };
    case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_BY_USER_ID_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //******************************GET_ALL_PROJECT_BY_USER_ID***************************************** */
    // *****************************GET_ALL_PROJECT_GROUPED_BY_USER_ID******************************************/

    // case PROJECT_ACTION_TYPES..GET_ALL_PROJECT_GROUPED_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_GROUPED_BY_USER_ID_SUCCESS:
      return { ...state, projectGroupedUsersState: [...action.payload] };
    case PROJECT_ACTION_TYPES.GET_ALL_PROJECT_GROUPED_BY_USER_ID_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //******************************GET_ALL_PROJECT_GROUPED_BY_USER_ID***************************************** */
    // *****************************GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID******************************************/

    // case PROJECT_ACTION_TYPES..GET_ALL_PROJECT_GROUPED_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_SUCCESS:
      return {
        ...state,
        projectGroupedUserByProjectState: [action.payload],
      };
    case PROJECT_ACTION_TYPES.GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_FAILURE:
      return {
        projectUsersErrorState: action.payload,
      };
    //******************************GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID***************************************** */
    // *****************************GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID******************************************/

    // case PROJECT_ACTION_TYPES.GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_SUCCESS:
      return {
        ...state,
        projectSpecificObjectivesState: [action.payload],
      };
    case PROJECT_ACTION_TYPES.GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //******************************GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case PROJECT_ACTION_TYPES.GET_BY_ID_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_BY_ID_PROJECT_SUCCESS:
      return {
        ...state,
        projectState: [action.payload],
      };
    case PROJECT_ACTION_TYPES.GET_BY_ID_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case PROJECT_ACTION_TYPES.COMPLETE_DELETE_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.COMPLETE_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case PROJECT_ACTION_TYPES.COMPLETE_DELETE_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case PROJECT_ACTION_TYPES.EMPTY_TRASH_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.EMPTY_TRASH_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case PROJECT_ACTION_TYPES.EMPTY_TRASH_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case PROJECT_ACTION_TYPES.EXPORT_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.EXPORT_PROJECT_SUCCESS:
      return {
        ...state,
        // projectState: action.payload,
      };
    case PROJECT_ACTION_TYPES.EXPORT_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case PROJECT_ACTION_TYPES.RECOVER_ALL_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.RECOVER_ALL_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case PROJECT_ACTION_TYPES.RECOVER_ALL_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_PROJECT_BY_ID***********************************************/
    // case PROJECT_ACTION_TYPES.RECOVER_PROJECT_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.RECOVER_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case PROJECT_ACTION_TYPES.RECOVER_PROJECT_BY_ID_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************RECOVER_PROJECT_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case PROJECT_ACTION_TYPES.TRASH_ALL_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.TRASH_ALL_PROJECT_SUCCESS:
      return { ...state };
    case PROJECT_ACTION_TYPES.TRASH_ALL_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case PROJECT_ACTION_TYPES.GET_ALL_TRASHED_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_TRASHED_PROJECT_SUCCESS:
      return {
        ...state,
        projectState: [...action.payload],
      };
    case PROJECT_ACTION_TYPES.GET_ALL_TRASHED_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case PROJECT_ACTION_TYPES.ARCHIVE_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.ARCHIVE_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case PROJECT_ACTION_TYPES.ARCHIVE_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case PROJECT_ACTION_TYPES.GET_ALL_ARCHIVED_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.GET_ALL_ARCHIVED_PROJECT_SUCCESS:
      return {
        ...state,
        projectState: [...action.payload],
      };
    case PROJECT_ACTION_TYPES.GET_ALL_ARCHIVED_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case PROJECT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PROJECT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PROJECT_SUCCESS:
      return {
        ...state,
        // projectState: [...action.payload],
      };
    case PROJECT_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PROJECT_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case PROJECT_ACTION_TYPES.RECOVER_ARCHIVED_PROJECT_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROJECT_ACTION_TYPES.RECOVER_ARCHIVED_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        projectState: [...action.payload],
      };
    case PROJECT_ACTION_TYPES.RECOVER_ARCHIVED_PROJECT_BY_ID_FAILURE:
      return {
        projectErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default PROJECT_REDUCER;
