import ROLE_ACTION_TYPES from "../actionTypes/roleActionTypes";

const initialState = {
  isLoading: false,
  roleState: [],
  roleByIdState: [],
  roleErrorState: "",
};

const ROLE_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case ROLE_ACTION_TYPES.CREATE_ROLE_SUCCESS:
      return {
        ...state,
      };
    // case ROLE_ACTION_TYPES.CREATE_ROLE_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case ROLE_ACTION_TYPES.CREATE_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case ROLE_ACTION_TYPES.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roleState: state.roleState.map((rl) =>
          rl.id === action.payload ? action.payload : rl
        ),
      };
    case ROLE_ACTION_TYPES.UPDATE_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case ROLE_ACTION_TYPES.DELETE_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.DELETE_ROLE_SUCCESS:
      return {
        ...state,
      };
    case ROLE_ACTION_TYPES.DELETE_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case ROLE_ACTION_TYPES.GET_ALL_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.GET_ALL_ROLE_SUCCESS:
      return { ...state, roleState: [...action.payload] };
    case ROLE_ACTION_TYPES.GET_ALL_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case ROLE_ACTION_TYPES.GET_BY_ID_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.GET_BY_ID_ROLE_SUCCESS:
      return {
        ...state,
        roleByIdState: [...action.payload],
      };
    case ROLE_ACTION_TYPES.GET_BY_ID_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case ROLE_ACTION_TYPES.COMPLETE_DELETE_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.COMPLETE_DELETE_ROLE_SUCCESS:
      return {
        ...state,
      };
    case ROLE_ACTION_TYPES.COMPLETE_DELETE_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case ROLE_ACTION_TYPES.EMPTY_TRASH_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.EMPTY_TRASH_ROLE_SUCCESS:
      return {
        ...state,
      };
    case ROLE_ACTION_TYPES.EMPTY_TRASH_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case ROLE_ACTION_TYPES.EXPORT_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.EXPORT_ROLE_SUCCESS:
      return {
        ...state,
        // roleState: action.payload,
      };
    case ROLE_ACTION_TYPES.EXPORT_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case ROLE_ACTION_TYPES.RECOVER_ALL_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.RECOVER_ALL_ROLE_SUCCESS:
      return {
        ...state,
      };
    case ROLE_ACTION_TYPES.RECOVER_ALL_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_ROLE_BY_ID***********************************************/
    // case ROLE_ACTION_TYPES.RECOVER_ROLE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.RECOVER_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case ROLE_ACTION_TYPES.RECOVER_ROLE_BY_ID_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************RECOVER_ROLE_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case ROLE_ACTION_TYPES.TRASH_ALL_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.TRASH_ALL_ROLE_SUCCESS:
      return { ...state };
    case ROLE_ACTION_TYPES.TRASH_ALL_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case ROLE_ACTION_TYPES.GET_ALL_TRASHED_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.GET_ALL_TRASHED_ROLE_SUCCESS:
      return {
        ...state,
        roleState: [...action.payload],
      };
    case ROLE_ACTION_TYPES.GET_ALL_TRASHED_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ROLE_ACTION_TYPES.ARCHIVE_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.ARCHIVE_ROLE_SUCCESS:
      return {
        ...state,
      };
    case ROLE_ACTION_TYPES.ARCHIVE_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ROLE_ACTION_TYPES.GET_ALL_ARCHIVED_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.GET_ALL_ARCHIVED_ROLE_SUCCESS:
      return {
        ...state,
        roleState: [...action.payload],
      };
    case ROLE_ACTION_TYPES.GET_ALL_ARCHIVED_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ROLE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ROLE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ROLE_SUCCESS:
      return {
        ...state,
        // roleState: [...action.payload],
      };
    case ROLE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ROLE_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ROLE_ACTION_TYPES.RECOVER_ARCHIVED_ROLE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ROLE_ACTION_TYPES.RECOVER_ARCHIVED_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        roleState: [...action.payload],
      };
    case ROLE_ACTION_TYPES.RECOVER_ARCHIVED_ROLE_BY_ID_FAILURE:
      return {
        roleErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default ROLE_REDUCER;
