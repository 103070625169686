const EDUCATIONAL_STATUS_TYPE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "GET_ALL_EDUCATIONAL_STATUS_TYPE_REQUEST",
  GET_ALL_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "GET_ALL_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  GET_ALL_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "GET_ALL_EDUCATIONAL_STATUS_TYPE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "GET_BY_ID_EDUCATIONAL_STATUS_TYPE_REQUEST",
  GET_BY_ID_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "GET_BY_ID_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  GET_BY_ID_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "GET_BY_ID_EDUCATIONAL_STATUS_TYPE_FAILURE",

  /************************************************************************* */
  CREATE_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "CREATE_EDUCATIONAL_STATUS_TYPE_REQUEST",
  CREATE_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "CREATE_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  CREATE_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "CREATE_EDUCATIONAL_STATUS_TYPE_FAILURE",

  /************************************************************************* */
  UPDATE_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "UPDATE_EDUCATIONAL_STATUS_TYPE_REQUEST",
  UPDATE_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "UPDATE_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  UPDATE_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "UPDATE_EDUCATIONAL_STATUS_TYPE_FAILURE",

  /************************************************************************* */
  DELETE_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "DELETE_EDUCATIONAL_STATUS_TYPE_REQUEST",
  DELETE_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "DELETE_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  DELETE_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "DELETE_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_EDUCATIONAL_STATUS_TYPE_BY_ID_REQUEST:
    "RECOVER_BY_ID_EDUCATIONAL_STATUS_TYPE_BY_ID_REQUEST",
  RECOVER_EDUCATIONAL_STATUS_TYPE_BY_ID_SUCCESS:
    "RECOVER_EDUCATIONAL_STATUS_TYPE_BY_ID_SUCCESS",
  RECOVER_EDUCATIONAL_STATUS_TYPE_BY_ID_FAILURE:
    "RECOVER_EDUCATIONAL_STATUS_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "RECOVER_ALL_EDUCATIONAL_STATUS_TYPE_REQUEST",
  RECOVER_ALL_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "RECOVER_ALL_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  RECOVER_ALL_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "RECOVER_ALL_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "COMPLETE_DELETE_EDUCATIONAL_STATUS_TYPE_REQUEST",
  COMPLETE_DELETE_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "COMPLETE_DELETE_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  COMPLETE_DELETE_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "COMPLETE_DELETE_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "TRASH_ALL_EDUCATIONAL_STATUS_TYPE_REQUEST",
  TRASH_ALL_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "TRASH_ALL_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  TRASH_ALL_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "TRASH_ALL_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "EMPTY_TRASH_EDUCATIONAL_STATUS_TYPE_REQUEST",
  EMPTY_TRASH_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "EMPTY_TRASH_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  EMPTY_TRASH_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "EMPTY_TRASH_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "EXPORT_EDUCATIONAL_STATUS_TYPE_REQUEST",
  EXPORT_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "EXPORT_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  EXPORT_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "EXPORT_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "GET_ALL_TRASHED_EDUCATIONAL_STATUS_TYPE_REQUEST",
  GET_ALL_TRASHED_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "GET_ALL_TRASHED_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  GET_ALL_TRASHED_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "GET_ALL_TRASHED_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "ARCHIVE_EDUCATIONAL_STATUS_TYPE_REQUEST",
  ARCHIVE_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "ARCHIVE_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  ARCHIVE_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "ARCHIVE_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "GET_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_REQUEST",
  GET_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "GET_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  GET_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "GET_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_REQUEST:
    "RECOVER_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_REQUEST",
  RECOVER_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_SUCCESS:
    "RECOVER_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_SUCCESS",
  RECOVER_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_FAILURE:
    "RECOVER_ALL_ARCHIVED_EDUCATIONAL_STATUS_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_EDUCATIONAL_STATUS_TYPE_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_EDUCATIONAL_STATUS_TYPE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_EDUCATIONAL_STATUS_TYPE_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_EDUCATIONAL_STATUS_TYPE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_EDUCATIONAL_STATUS_TYPE_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_EDUCATIONAL_STATUS_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default EDUCATIONAL_STATUS_TYPE_ACTION_TYPES;
