const ORGANIZATION_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ORGANIZATION_REQUEST: "GET_ALL_ORGANIZATION_REQUEST",
  GET_ALL_ORGANIZATION_SUCCESS: "GET_ALL_ORGANIZATION_SUCCESS",
  GET_ALL_ORGANIZATION_FAILURE: "GET_ALL_ORGANIZATION_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ORGANIZATION_REQUEST: "GET_BY_ID_ORGANIZATION_REQUEST",
  GET_BY_ID_ORGANIZATION_SUCCESS: "GET_BY_ID_ORGANIZATION_SUCCESS",
  GET_BY_ID_ORGANIZATION_FAILURE: "GET_BY_ID_ORGANIZATION_FAILURE",

  /************************************************************************* */
  CREATE_ORGANIZATION_REQUEST: "CREATE_ORGANIZATION_REQUEST",
  CREATE_ORGANIZATION_SUCCESS: "CREATE_ORGANIZATION_SUCCESS",
  CREATE_ORGANIZATION_FAILURE: "CREATE_ORGANIZATION_FAILURE",

  /************************************************************************* */
  UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_FAILURE: "UPDATE_ORGANIZATION_FAILURE",

  /************************************************************************* */
  DELETE_ORGANIZATION_REQUEST: "DELETE_ORGANIZATION_REQUEST",
  DELETE_ORGANIZATION_SUCCESS: "DELETE_ORGANIZATION_SUCCESS",
  DELETE_ORGANIZATION_FAILURE: "DELETE_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ORGANIZATION_BY_ID_REQUEST:
    "RECOVER_BY_ID_ORGANIZATION_BY_ID_REQUEST",
  RECOVER_ORGANIZATION_BY_ID_SUCCESS: "RECOVER_ORGANIZATION_BY_ID_SUCCESS",
  RECOVER_ORGANIZATION_BY_ID_FAILURE: "RECOVER_ORGANIZATION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ORGANIZATION_REQUEST: "RECOVER_ALL_ORGANIZATION_REQUEST",
  RECOVER_ALL_ORGANIZATION_SUCCESS: "RECOVER_ALL_ORGANIZATION_SUCCESS",
  RECOVER_ALL_ORGANIZATION_FAILURE: "RECOVER_ALL_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ORGANIZATION_REQUEST: "COMPLETE_DELETE_ORGANIZATION_REQUEST",
  COMPLETE_DELETE_ORGANIZATION_SUCCESS: "COMPLETE_DELETE_ORGANIZATION_SUCCESS",
  COMPLETE_DELETE_ORGANIZATION_FAILURE: "COMPLETE_DELETE_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ORGANIZATION_REQUEST: "TRASH_ALL_ORGANIZATION_REQUEST",
  TRASH_ALL_ORGANIZATION_SUCCESS: "TRASH_ALL_ORGANIZATION_SUCCESS",
  TRASH_ALL_ORGANIZATION_FAILURE: "TRASH_ALL_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ORGANIZATION_REQUEST: "EMPTY_TRASH_ORGANIZATION_REQUEST",
  EMPTY_TRASH_ORGANIZATION_SUCCESS: "EMPTY_TRASH_ORGANIZATION_SUCCESS",
  EMPTY_TRASH_ORGANIZATION_FAILURE: "EMPTY_TRASH_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ORGANIZATION_REQUEST: "EXPORT_ORGANIZATION_REQUEST",
  EXPORT_ORGANIZATION_SUCCESS: "EXPORT_ORGANIZATION_SUCCESS",
  EXPORT_ORGANIZATION_FAILURE: "EXPORT_ORGANIZATION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ORGANIZATION_REQUEST: "GET_ALL_TRASHED_ORGANIZATION_REQUEST",
  GET_ALL_TRASHED_ORGANIZATION_SUCCESS: "GET_ALL_TRASHED_ORGANIZATION_SUCCESS",
  GET_ALL_TRASHED_ORGANIZATION_FAILURE: "GET_ALL_TRASHED_ORGANIZATION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ORGANIZATION_REQUEST: "ARCHIVE_ORGANIZATION_REQUEST",
  ARCHIVE_ORGANIZATION_SUCCESS: "ARCHIVE_ORGANIZATION_SUCCESS",
  ARCHIVE_ORGANIZATION_FAILURE: "ARCHIVE_ORGANIZATION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ORGANIZATION_REQUEST:
    "GET_ALL_ARCHIVED_ORGANIZATION_REQUEST",
  GET_ALL_ARCHIVED_ORGANIZATION_SUCCESS:
    "GET_ALL_ARCHIVED_ORGANIZATION_SUCCESS",
  GET_ALL_ARCHIVED_ORGANIZATION_FAILURE:
    "GET_ALL_ARCHIVED_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ORGANIZATION_REQUEST:
    "RECOVER_ALL_ARCHIVED_ORGANIZATION_REQUEST",
  RECOVER_ALL_ARCHIVED_ORGANIZATION_SUCCESS:
    "RECOVER_ALL_ARCHIVED_ORGANIZATION_SUCCESS",
  RECOVER_ALL_ARCHIVED_ORGANIZATION_FAILURE:
    "RECOVER_ALL_ARCHIVED_ORGANIZATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ORGANIZATION_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ORGANIZATION_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ORGANIZATION_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ORGANIZATION_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ORGANIZATION_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ORGANIZATION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  UPLOAD_ORGANIZATION_LOGO_REQUEST: "UPLOAD_ORGANIZATION_LOGO_REQUEST",
  UPLOAD_ORGANIZATION_LOGO_SUCCESS: "UPLOAD_ORGANIZATION_LOGO_SUCCESS",
  UPLOAD_ORGANIZATION_LOGO_FAILURE: "UPLOAD_ORGANIZATION_LOGO_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  UPDATE_ORGANIZATION_LOGO_REQUEST: "UPDATE_ORGANIZATION_LOGO_REQUEST",
  UPDATE_ORGANIZATION_LOGO_SUCCESS: "UPDATE_ORGANIZATION_LOGO_SUCCESS",
  UPDATE_ORGANIZATION_LOGO_FAILURE: "UPDATE_ORGANIZATION_LOGO_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  DOWNLOAD_ORGANIZATION_LOGO_REQUEST: "DOWNLOAD_ORGANIZATION_LOGO_REQUEST",
  DOWNLOAD_ORGANIZATION_LOGO_SUCCESS: "DOWNLOAD_ORGANIZATION_LOGO_SUCCESS",
  DOWNLOAD_ORGANIZATION_LOGO_FAILURE: "DOWNLOAD_ORGANIZATION_LOGO_FAILURE",
  /************************************************************************* */
};

export default ORGANIZATION_ACTION_TYPES;
