const ACTIVITY_MEASUREMENT_UNIT_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "GET_ALL_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  GET_ALL_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "GET_ALL_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  GET_ALL_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "GET_ALL_ACTIVITY_MEASUREMENT_UNIT_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "GET_BY_ID_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  GET_BY_ID_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "GET_BY_ID_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  GET_BY_ID_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "GET_BY_ID_ACTIVITY_MEASUREMENT_UNIT_FAILURE",

  /************************************************************************* */
  CREATE_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "CREATE_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  CREATE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "CREATE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  CREATE_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "CREATE_ACTIVITY_MEASUREMENT_UNIT_FAILURE",

  /************************************************************************* */
  UPDATE_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "UPDATE_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  UPDATE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "UPDATE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  UPDATE_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "UPDATE_ACTIVITY_MEASUREMENT_UNIT_FAILURE",

  /************************************************************************* */
  DELETE_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "DELETE_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  DELETE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "DELETE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  DELETE_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "DELETE_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ACTIVITY_MEASUREMENT_UNIT_BY_ID_REQUEST:
    "RECOVER_BY_ID_ACTIVITY_MEASUREMENT_UNIT_BY_ID_REQUEST",
  RECOVER_ACTIVITY_MEASUREMENT_UNIT_BY_ID_SUCCESS:
    "RECOVER_ACTIVITY_MEASUREMENT_UNIT_BY_ID_SUCCESS",
  RECOVER_ACTIVITY_MEASUREMENT_UNIT_BY_ID_FAILURE:
    "RECOVER_ACTIVITY_MEASUREMENT_UNIT_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "RECOVER_ALL_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  RECOVER_ALL_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "RECOVER_ALL_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  RECOVER_ALL_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "RECOVER_ALL_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "COMPLETE_DELETE_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  COMPLETE_DELETE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "COMPLETE_DELETE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  COMPLETE_DELETE_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "COMPLETE_DELETE_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "TRASH_ALL_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  TRASH_ALL_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "TRASH_ALL_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  TRASH_ALL_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "TRASH_ALL_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "EMPTY_TRASH_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  EMPTY_TRASH_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "EMPTY_TRASH_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  EMPTY_TRASH_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "EMPTY_TRASH_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "EXPORT_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  EXPORT_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "EXPORT_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  EXPORT_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "EXPORT_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "GET_ALL_TRASHED_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  GET_ALL_TRASHED_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "GET_ALL_TRASHED_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  GET_ALL_TRASHED_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "GET_ALL_TRASHED_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "ARCHIVE_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  ARCHIVE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "ARCHIVE_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  ARCHIVE_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "ARCHIVE_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "GET_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  GET_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "GET_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  GET_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "GET_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_REQUEST:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_REQUEST",
  RECOVER_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_SUCCESS:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_SUCCESS",
  RECOVER_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_FAILURE:
    "RECOVER_ALL_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ACTIVITY_MEASUREMENT_UNIT_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ACTIVITY_MEASUREMENT_UNIT_ACTION_TYPES;
