import CONTACT_PERSON_ACTION_TYPES from "../actionTypes/contactPersonActionTypes";

const initialState = {
  isLoading: false,
  contactPersonState: [],
  contactPersonErrorState: "",
};

const CONTACT_PERSON_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case CONTACT_PERSON_ACTION_TYPES.CREATE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
      };
    // case CONTACT_PERSON_ACTION_TYPES.CREATE_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.CREATE_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case CONTACT_PERSON_ACTION_TYPES.UPDATE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        contactPersonState: state.contactPersonState.map((ctP) =>
          ctP.id === action.payload ? action.payload : ctP
        ),
      };
    case CONTACT_PERSON_ACTION_TYPES.UPDATE_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case CONTACT_PERSON_ACTION_TYPES.DELETE_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.DELETE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
      };
    case CONTACT_PERSON_ACTION_TYPES.DELETE_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case CONTACT_PERSON_ACTION_TYPES.GET_ALL_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.GET_ALL_CONTACT_PERSON_SUCCESS:
      return { ...state, contactPersonState: [...action.payload] };
    case CONTACT_PERSON_ACTION_TYPES.GET_ALL_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.GET_BY_ID_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.GET_BY_ID_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        contactPersonState: action.payload,
      };
    case CONTACT_PERSON_ACTION_TYPES.GET_BY_ID_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.COMPLETE_DELETE_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.COMPLETE_DELETE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
      };
    case CONTACT_PERSON_ACTION_TYPES.COMPLETE_DELETE_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.EMPTY_TRASH_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.EMPTY_TRASH_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
      };
    case CONTACT_PERSON_ACTION_TYPES.EMPTY_TRASH_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.EXPORT_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.EXPORT_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        // contactPersonState: action.payload,
      };
    case CONTACT_PERSON_ACTION_TYPES.EXPORT_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.RECOVER_ALL_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_ALL_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
      };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_ALL_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_CONTACT_PERSON_BY_ID***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.RECOVER_CONTACT_PERSON_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_CONTACT_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_CONTACT_PERSON_BY_ID_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************RECOVER_CONTACT_PERSON_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.TRASH_ALL_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.TRASH_ALL_CONTACT_PERSON_SUCCESS:
      return { ...state };
    case CONTACT_PERSON_ACTION_TYPES.TRASH_ALL_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.GET_ALL_TRASHED_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.GET_ALL_TRASHED_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        contactPersonState: [...action.payload],
      };
    case CONTACT_PERSON_ACTION_TYPES.GET_ALL_TRASHED_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.ARCHIVE_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.ARCHIVE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
      };
    case CONTACT_PERSON_ACTION_TYPES.ARCHIVE_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.GET_ALL_ARCHIVED_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.GET_ALL_ARCHIVED_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        contactPersonState: [...action.payload],
      };
    case CONTACT_PERSON_ACTION_TYPES.GET_ALL_ARCHIVED_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.RECOVER_ALL_ARCHIVED_CONTACT_PERSON_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_ALL_ARCHIVED_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        // contactPersonState: [...action.payload],
      };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_ALL_ARCHIVED_CONTACT_PERSON_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case CONTACT_PERSON_ACTION_TYPES.RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        contactPersonState: [...action.payload],
      };
    case CONTACT_PERSON_ACTION_TYPES.RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_FAILURE:
      return {
        contactPersonErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default CONTACT_PERSON_REDUCER;
