const CITY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_CITY_REQUEST: "GET_ALL_CITY_REQUEST",
  GET_ALL_CITY_SUCCESS: "GET_ALL_CITY_SUCCESS",
  GET_ALL_CITY_FAILURE: "GET_ALL_CITY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_CITY_REQUEST: "GET_BY_ID_CITY_REQUEST",
  GET_BY_ID_CITY_SUCCESS: "GET_BY_ID_CITY_SUCCESS",
  GET_BY_ID_CITY_FAILURE: "GET_BY_ID_CITY_FAILURE",

  /************************************************************************* */
  CREATE_CITY_REQUEST: "CREATE_CITY_REQUEST",
  CREATE_CITY_SUCCESS: "CREATE_CITY_SUCCESS",
  CREATE_CITY_FAILURE: "CREATE_CITY_FAILURE",

  /************************************************************************* */
  UPDATE_CITY_REQUEST: "UPDATE_CITY_REQUEST",
  UPDATE_CITY_SUCCESS: "UPDATE_CITY_SUCCESS",
  UPDATE_CITY_FAILURE: "UPDATE_CITY_FAILURE",

  /************************************************************************* */
  DELETE_CITY_REQUEST: "DELETE_CITY_REQUEST",
  DELETE_CITY_SUCCESS: "DELETE_CITY_SUCCESS",
  DELETE_CITY_FAILURE: "DELETE_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_CITY_BY_ID_REQUEST: "RECOVER_BY_ID_CITY_BY_ID_REQUEST",
  RECOVER_CITY_BY_ID_SUCCESS: "RECOVER_CITY_BY_ID_SUCCESS",
  RECOVER_CITY_BY_ID_FAILURE: "RECOVER_CITY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_CITY_REQUEST: "RECOVER_ALL_CITY_REQUEST",
  RECOVER_ALL_CITY_SUCCESS: "RECOVER_ALL_CITY_SUCCESS",
  RECOVER_ALL_CITY_FAILURE: "RECOVER_ALL_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_CITY_REQUEST: "COMPLETE_DELETE_CITY_REQUEST",
  COMPLETE_DELETE_CITY_SUCCESS: "COMPLETE_DELETE_CITY_SUCCESS",
  COMPLETE_DELETE_CITY_FAILURE: "COMPLETE_DELETE_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_CITY_REQUEST: "TRASH_ALL_CITY_REQUEST",
  TRASH_ALL_CITY_SUCCESS: "TRASH_ALL_CITY_SUCCESS",
  TRASH_ALL_CITY_FAILURE: "TRASH_ALL_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_CITY_REQUEST: "EMPTY_TRASH_CITY_REQUEST",
  EMPTY_TRASH_CITY_SUCCESS: "EMPTY_TRASH_CITY_SUCCESS",
  EMPTY_TRASH_CITY_FAILURE: "EMPTY_TRASH_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_CITY_REQUEST: "EXPORT_CITY_REQUEST",
  EXPORT_CITY_SUCCESS: "EXPORT_CITY_SUCCESS",
  EXPORT_CITY_FAILURE: "EXPORT_CITY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_CITY_REQUEST: "GET_ALL_TRASHED_CITY_REQUEST",
  GET_ALL_TRASHED_CITY_SUCCESS: "GET_ALL_TRASHED_CITY_SUCCESS",
  GET_ALL_TRASHED_CITY_FAILURE: "GET_ALL_TRASHED_CITY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_CITY_REQUEST: "ARCHIVE_CITY_REQUEST",
  ARCHIVE_CITY_SUCCESS: "ARCHIVE_CITY_SUCCESS",
  ARCHIVE_CITY_FAILURE: "ARCHIVE_CITY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_CITY_REQUEST: "GET_ALL_ARCHIVED_CITY_REQUEST",
  GET_ALL_ARCHIVED_CITY_SUCCESS: "GET_ALL_ARCHIVED_CITY_SUCCESS",
  GET_ALL_ARCHIVED_CITY_FAILURE: "GET_ALL_ARCHIVED_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_CITY_REQUEST: "RECOVER_ALL_ARCHIVED_CITY_REQUEST",
  RECOVER_ALL_ARCHIVED_CITY_SUCCESS: "RECOVER_ALL_ARCHIVED_CITY_SUCCESS",
  RECOVER_ALL_ARCHIVED_CITY_FAILURE: "RECOVER_ALL_ARCHIVED_CITY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_CITY_BY_ID_REQUEST: "RECOVER_ARCHIVED_CITY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_CITY_BY_ID_SUCCESS: "RECOVER_ARCHIVED_CITY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_CITY_BY_ID_FAILURE: "RECOVER_ARCHIVED_CITY_BY_ID_FAILURE",
  /************************************************************************* */
};

export default CITY_ACTION_TYPES;
