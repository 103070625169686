const SUPPLIER_CATEGORY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_SUPPLIER_CATEGORY_REQUEST: "GET_ALL_SUPPLIER_CATEGORY_REQUEST",
  GET_ALL_SUPPLIER_CATEGORY_SUCCESS: "GET_ALL_SUPPLIER_CATEGORY_SUCCESS",
  GET_ALL_SUPPLIER_CATEGORY_FAILURE: "GET_ALL_SUPPLIER_CATEGORY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_SUPPLIER_CATEGORY_REQUEST: "GET_BY_ID_SUPPLIER_CATEGORY_REQUEST",
  GET_BY_ID_SUPPLIER_CATEGORY_SUCCESS: "GET_BY_ID_SUPPLIER_CATEGORY_SUCCESS",
  GET_BY_ID_SUPPLIER_CATEGORY_FAILURE: "GET_BY_ID_SUPPLIER_CATEGORY_FAILURE",

  /************************************************************************* */
  CREATE_SUPPLIER_CATEGORY_REQUEST: "CREATE_SUPPLIER_CATEGORY_REQUEST",
  CREATE_SUPPLIER_CATEGORY_SUCCESS: "CREATE_SUPPLIER_CATEGORY_SUCCESS",
  CREATE_SUPPLIER_CATEGORY_FAILURE: "CREATE_SUPPLIER_CATEGORY_FAILURE",

  /************************************************************************* */
  UPDATE_SUPPLIER_CATEGORY_REQUEST: "UPDATE_SUPPLIER_CATEGORY_REQUEST",
  UPDATE_SUPPLIER_CATEGORY_SUCCESS: "UPDATE_SUPPLIER_CATEGORY_SUCCESS",
  UPDATE_SUPPLIER_CATEGORY_FAILURE: "UPDATE_SUPPLIER_CATEGORY_FAILURE",

  /************************************************************************* */
  DELETE_SUPPLIER_CATEGORY_REQUEST: "DELETE_SUPPLIER_CATEGORY_REQUEST",
  DELETE_SUPPLIER_CATEGORY_SUCCESS: "DELETE_SUPPLIER_CATEGORY_SUCCESS",
  DELETE_SUPPLIER_CATEGORY_FAILURE: "DELETE_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_SUPPLIER_CATEGORY_BY_ID_REQUEST:
    "RECOVER_BY_ID_SUPPLIER_CATEGORY_BY_ID_REQUEST",
  RECOVER_SUPPLIER_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_SUPPLIER_CATEGORY_BY_ID_SUCCESS",
  RECOVER_SUPPLIER_CATEGORY_BY_ID_FAILURE:
    "RECOVER_SUPPLIER_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_SUPPLIER_CATEGORY_REQUEST:
    "RECOVER_ALL_SUPPLIER_CATEGORY_REQUEST",
  RECOVER_ALL_SUPPLIER_CATEGORY_SUCCESS:
    "RECOVER_ALL_SUPPLIER_CATEGORY_SUCCESS",
  RECOVER_ALL_SUPPLIER_CATEGORY_FAILURE:
    "RECOVER_ALL_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_SUPPLIER_CATEGORY_REQUEST:
    "COMPLETE_DELETE_SUPPLIER_CATEGORY_REQUEST",
  COMPLETE_DELETE_SUPPLIER_CATEGORY_SUCCESS:
    "COMPLETE_DELETE_SUPPLIER_CATEGORY_SUCCESS",
  COMPLETE_DELETE_SUPPLIER_CATEGORY_FAILURE:
    "COMPLETE_DELETE_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_SUPPLIER_CATEGORY_REQUEST: "TRASH_ALL_SUPPLIER_CATEGORY_REQUEST",
  TRASH_ALL_SUPPLIER_CATEGORY_SUCCESS: "TRASH_ALL_SUPPLIER_CATEGORY_SUCCESS",
  TRASH_ALL_SUPPLIER_CATEGORY_FAILURE: "TRASH_ALL_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_SUPPLIER_CATEGORY_REQUEST:
    "EMPTY_TRASH_SUPPLIER_CATEGORY_REQUEST",
  EMPTY_TRASH_SUPPLIER_CATEGORY_SUCCESS:
    "EMPTY_TRASH_SUPPLIER_CATEGORY_SUCCESS",
  EMPTY_TRASH_SUPPLIER_CATEGORY_FAILURE:
    "EMPTY_TRASH_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_SUPPLIER_CATEGORY_REQUEST: "EXPORT_SUPPLIER_CATEGORY_REQUEST",
  EXPORT_SUPPLIER_CATEGORY_SUCCESS: "EXPORT_SUPPLIER_CATEGORY_SUCCESS",
  EXPORT_SUPPLIER_CATEGORY_FAILURE: "EXPORT_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_SUPPLIER_CATEGORY_REQUEST:
    "GET_ALL_TRASHED_SUPPLIER_CATEGORY_REQUEST",
  GET_ALL_TRASHED_SUPPLIER_CATEGORY_SUCCESS:
    "GET_ALL_TRASHED_SUPPLIER_CATEGORY_SUCCESS",
  GET_ALL_TRASHED_SUPPLIER_CATEGORY_FAILURE:
    "GET_ALL_TRASHED_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_SUPPLIER_CATEGORY_REQUEST: "ARCHIVE_SUPPLIER_CATEGORY_REQUEST",
  ARCHIVE_SUPPLIER_CATEGORY_SUCCESS: "ARCHIVE_SUPPLIER_CATEGORY_SUCCESS",
  ARCHIVE_SUPPLIER_CATEGORY_FAILURE: "ARCHIVE_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_SUPPLIER_CATEGORY_REQUEST:
    "GET_ALL_ARCHIVED_SUPPLIER_CATEGORY_REQUEST",
  GET_ALL_ARCHIVED_SUPPLIER_CATEGORY_SUCCESS:
    "GET_ALL_ARCHIVED_SUPPLIER_CATEGORY_SUCCESS",
  GET_ALL_ARCHIVED_SUPPLIER_CATEGORY_FAILURE:
    "GET_ALL_ARCHIVED_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_SUPPLIER_CATEGORY_REQUEST:
    "RECOVER_ALL_ARCHIVED_SUPPLIER_CATEGORY_REQUEST",
  RECOVER_ALL_ARCHIVED_SUPPLIER_CATEGORY_SUCCESS:
    "RECOVER_ALL_ARCHIVED_SUPPLIER_CATEGORY_SUCCESS",
  RECOVER_ALL_ARCHIVED_SUPPLIER_CATEGORY_FAILURE:
    "RECOVER_ALL_ARCHIVED_SUPPLIER_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_SUPPLIER_CATEGORY_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_SUPPLIER_CATEGORY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_SUPPLIER_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_SUPPLIER_CATEGORY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_SUPPLIER_CATEGORY_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_SUPPLIER_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
};

export default SUPPLIER_CATEGORY_ACTION_TYPES;
