const DEPARTMENT_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_DEPARTMENT_REQUEST: "GET_ALL_DEPARTMENT_REQUEST",
  GET_ALL_DEPARTMENT_SUCCESS: "GET_ALL_DEPARTMENT_SUCCESS",
  GET_ALL_DEPARTMENT_FAILURE: "GET_ALL_DEPARTMENT_FAILURE",

  /************************************************************************* */
  GET_BY_ID_DEPARTMENT_REQUEST: "GET_BY_ID_DEPARTMENT_REQUEST",
  GET_BY_ID_DEPARTMENT_SUCCESS: "GET_BY_ID_DEPARTMENT_SUCCESS",
  GET_BY_ID_DEPARTMENT_FAILURE: "GET_BY_ID_DEPARTMENT_FAILURE",

  /************************************************************************* */
  CREATE_DEPARTMENT_REQUEST: "CREATE_DEPARTMENT_REQUEST",
  CREATE_DEPARTMENT_SUCCESS: "CREATE_DEPARTMENT_SUCCESS",
  CREATE_DEPARTMENT_FAILURE: "CREATE_DEPARTMENT_FAILURE",

  /************************************************************************* */
  UPDATE_DEPARTMENT_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
  UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
  UPDATE_DEPARTMENT_FAILURE: "UPDATE_DEPARTMENT_FAILURE",

  /************************************************************************* */
  DELETE_DEPARTMENT_REQUEST: "DELETE_DEPARTMENT_REQUEST",
  DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
  DELETE_DEPARTMENT_FAILURE: "DELETE_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_DEPARTMENT_BY_ID_REQUEST: "RECOVER_BY_ID_DEPARTMENT_BY_ID_REQUEST",
  RECOVER_DEPARTMENT_BY_ID_SUCCESS: "RECOVER_DEPARTMENT_BY_ID_SUCCESS",
  RECOVER_DEPARTMENT_BY_ID_FAILURE: "RECOVER_DEPARTMENT_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_DEPARTMENT_REQUEST: "RECOVER_ALL_DEPARTMENT_REQUEST",
  RECOVER_ALL_DEPARTMENT_SUCCESS: "RECOVER_ALL_DEPARTMENT_SUCCESS",
  RECOVER_ALL_DEPARTMENT_FAILURE: "RECOVER_ALL_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_DEPARTMENT_REQUEST: "COMPLETE_DELETE_DEPARTMENT_REQUEST",
  COMPLETE_DELETE_DEPARTMENT_SUCCESS: "COMPLETE_DELETE_DEPARTMENT_SUCCESS",
  COMPLETE_DELETE_DEPARTMENT_FAILURE: "COMPLETE_DELETE_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_DEPARTMENT_REQUEST: "TRASH_ALL_DEPARTMENT_REQUEST",
  TRASH_ALL_DEPARTMENT_SUCCESS: "TRASH_ALL_DEPARTMENT_SUCCESS",
  TRASH_ALL_DEPARTMENT_FAILURE: "TRASH_ALL_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_DEPARTMENT_REQUEST: "EMPTY_TRASH_DEPARTMENT_REQUEST",
  EMPTY_TRASH_DEPARTMENT_SUCCESS: "EMPTY_TRASH_DEPARTMENT_SUCCESS",
  EMPTY_TRASH_DEPARTMENT_FAILURE: "EMPTY_TRASH_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_DEPARTMENT_REQUEST: "EXPORT_DEPARTMENT_REQUEST",
  EXPORT_DEPARTMENT_SUCCESS: "EXPORT_DEPARTMENT_SUCCESS",
  EXPORT_DEPARTMENT_FAILURE: "EXPORT_DEPARTMENT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_DEPARTMENT_REQUEST: "GET_ALL_TRASHED_DEPARTMENT_REQUEST",
  GET_ALL_TRASHED_DEPARTMENT_SUCCESS: "GET_ALL_TRASHED_DEPARTMENT_SUCCESS",
  GET_ALL_TRASHED_DEPARTMENT_FAILURE: "GET_ALL_TRASHED_DEPARTMENT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_DEPARTMENT_REQUEST: "ARCHIVE_DEPARTMENT_REQUEST",
  ARCHIVE_DEPARTMENT_SUCCESS: "ARCHIVE_DEPARTMENT_SUCCESS",
  ARCHIVE_DEPARTMENT_FAILURE: "ARCHIVE_DEPARTMENT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_DEPARTMENT_REQUEST: "GET_ALL_ARCHIVED_DEPARTMENT_REQUEST",
  GET_ALL_ARCHIVED_DEPARTMENT_SUCCESS: "GET_ALL_ARCHIVED_DEPARTMENT_SUCCESS",
  GET_ALL_ARCHIVED_DEPARTMENT_FAILURE: "GET_ALL_ARCHIVED_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_DEPARTMENT_REQUEST:
    "RECOVER_ALL_ARCHIVED_DEPARTMENT_REQUEST",
  RECOVER_ALL_ARCHIVED_DEPARTMENT_SUCCESS:
    "RECOVER_ALL_ARCHIVED_DEPARTMENT_SUCCESS",
  RECOVER_ALL_ARCHIVED_DEPARTMENT_FAILURE:
    "RECOVER_ALL_ARCHIVED_DEPARTMENT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_DEPARTMENT_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_DEPARTMENT_BY_ID_REQUEST",
  RECOVER_ARCHIVED_DEPARTMENT_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_DEPARTMENT_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_DEPARTMENT_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_DEPARTMENT_BY_ID_FAILURE",
  /************************************************************************* */
};

export default DEPARTMENT_ACTION_TYPES;
