const WOREDA_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_WOREDA_REQUEST: "GET_ALL_WOREDA_REQUEST",
  GET_ALL_WOREDA_SUCCESS: "GET_ALL_WOREDA_SUCCESS",
  GET_ALL_WOREDA_FAILURE: "GET_ALL_WOREDA_FAILURE",

  /************************************************************************* */
  GET_BY_ID_WOREDA_REQUEST: "GET_BY_ID_WOREDA_REQUEST",
  GET_BY_ID_WOREDA_SUCCESS: "GET_BY_ID_WOREDA_SUCCESS",
  GET_BY_ID_WOREDA_FAILURE: "GET_BY_ID_WOREDA_FAILURE",

  /************************************************************************* */
  CREATE_WOREDA_REQUEST: "CREATE_WOREDA_REQUEST",
  CREATE_WOREDA_SUCCESS: "CREATE_WOREDA_SUCCESS",
  CREATE_WOREDA_FAILURE: "CREATE_WOREDA_FAILURE",

  /************************************************************************* */
  UPDATE_WOREDA_REQUEST: "UPDATE_WOREDA_REQUEST",
  UPDATE_WOREDA_SUCCESS: "UPDATE_WOREDA_SUCCESS",
  UPDATE_WOREDA_FAILURE: "UPDATE_WOREDA_FAILURE",

  /************************************************************************* */
  DELETE_WOREDA_REQUEST: "DELETE_WOREDA_REQUEST",
  DELETE_WOREDA_SUCCESS: "DELETE_WOREDA_SUCCESS",
  DELETE_WOREDA_FAILURE: "DELETE_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_WOREDA_BY_ID_REQUEST: "RECOVER_BY_ID_WOREDA_BY_ID_REQUEST",
  RECOVER_WOREDA_BY_ID_SUCCESS: "RECOVER_WOREDA_BY_ID_SUCCESS",
  RECOVER_WOREDA_BY_ID_FAILURE: "RECOVER_WOREDA_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_WOREDA_REQUEST: "RECOVER_ALL_WOREDA_REQUEST",
  RECOVER_ALL_WOREDA_SUCCESS: "RECOVER_ALL_WOREDA_SUCCESS",
  RECOVER_ALL_WOREDA_FAILURE: "RECOVER_ALL_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_WOREDA_REQUEST: "COMPLETE_DELETE_WOREDA_REQUEST",
  COMPLETE_DELETE_WOREDA_SUCCESS: "COMPLETE_DELETE_WOREDA_SUCCESS",
  COMPLETE_DELETE_WOREDA_FAILURE: "COMPLETE_DELETE_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_WOREDA_REQUEST: "TRASH_ALL_WOREDA_REQUEST",
  TRASH_ALL_WOREDA_SUCCESS: "TRASH_ALL_WOREDA_SUCCESS",
  TRASH_ALL_WOREDA_FAILURE: "TRASH_ALL_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_WOREDA_REQUEST: "EMPTY_TRASH_WOREDA_REQUEST",
  EMPTY_TRASH_WOREDA_SUCCESS: "EMPTY_TRASH_WOREDA_SUCCESS",
  EMPTY_TRASH_WOREDA_FAILURE: "EMPTY_TRASH_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_WOREDA_REQUEST: "EXPORT_WOREDA_REQUEST",
  EXPORT_WOREDA_SUCCESS: "EXPORT_WOREDA_SUCCESS",
  EXPORT_WOREDA_FAILURE: "EXPORT_WOREDA_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_WOREDA_REQUEST: "GET_ALL_TRASHED_WOREDA_REQUEST",
  GET_ALL_TRASHED_WOREDA_SUCCESS: "GET_ALL_TRASHED_WOREDA_SUCCESS",
  GET_ALL_TRASHED_WOREDA_FAILURE: "GET_ALL_TRASHED_WOREDA_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_WOREDA_REQUEST: "ARCHIVE_WOREDA_REQUEST",
  ARCHIVE_WOREDA_SUCCESS: "ARCHIVE_WOREDA_SUCCESS",
  ARCHIVE_WOREDA_FAILURE: "ARCHIVE_WOREDA_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_WOREDA_REQUEST: "GET_ALL_ARCHIVED_WOREDA_REQUEST",
  GET_ALL_ARCHIVED_WOREDA_SUCCESS: "GET_ALL_ARCHIVED_WOREDA_SUCCESS",
  GET_ALL_ARCHIVED_WOREDA_FAILURE: "GET_ALL_ARCHIVED_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_WOREDA_REQUEST: "RECOVER_ALL_ARCHIVED_WOREDA_REQUEST",
  RECOVER_ALL_ARCHIVED_WOREDA_SUCCESS: "RECOVER_ALL_ARCHIVED_WOREDA_SUCCESS",
  RECOVER_ALL_ARCHIVED_WOREDA_FAILURE: "RECOVER_ALL_ARCHIVED_WOREDA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_WOREDA_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_WOREDA_BY_ID_REQUEST",
  RECOVER_ARCHIVED_WOREDA_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_WOREDA_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_WOREDA_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_WOREDA_BY_ID_FAILURE",
  /************************************************************************* */
};

export default WOREDA_ACTION_TYPES;
