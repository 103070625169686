const PROGRAM_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_PROGRAM_REQUEST: "GET_ALL_PROGRAM_REQUEST",
  GET_ALL_PROGRAM_SUCCESS: "GET_ALL_PROGRAM_SUCCESS",
  GET_ALL_PROGRAM_FAILURE: "GET_ALL_PROGRAM_FAILURE",

  /************************************************************************* */
  GET_BY_ID_PROGRAM_REQUEST: "GET_BY_ID_PROGRAM_REQUEST",
  GET_BY_ID_PROGRAM_SUCCESS: "GET_BY_ID_PROGRAM_SUCCESS",
  GET_BY_ID_PROGRAM_FAILURE: "GET_BY_ID_PROGRAM_FAILURE",

  /************************************************************************* */
  CREATE_PROGRAM_REQUEST: "CREATE_PROGRAM_REQUEST",
  CREATE_PROGRAM_SUCCESS: "CREATE_PROGRAM_SUCCESS",
  CREATE_PROGRAM_FAILURE: "CREATE_PROGRAM_FAILURE",

  /************************************************************************* */
  UPDATE_PROGRAM_REQUEST: "UPDATE_PROGRAM_REQUEST",
  UPDATE_PROGRAM_SUCCESS: "UPDATE_PROGRAM_SUCCESS",
  UPDATE_PROGRAM_FAILURE: "UPDATE_PROGRAM_FAILURE",

  /************************************************************************* */
  DELETE_PROGRAM_REQUEST: "DELETE_PROGRAM_REQUEST",
  DELETE_PROGRAM_SUCCESS: "DELETE_PROGRAM_SUCCESS",
  DELETE_PROGRAM_FAILURE: "DELETE_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_PROGRAM_BY_ID_REQUEST: "RECOVER_BY_ID_PROGRAM_BY_ID_REQUEST",
  RECOVER_PROGRAM_BY_ID_SUCCESS: "RECOVER_PROGRAM_BY_ID_SUCCESS",
  RECOVER_PROGRAM_BY_ID_FAILURE: "RECOVER_PROGRAM_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_PROGRAM_REQUEST: "RECOVER_ALL_PROGRAM_REQUEST",
  RECOVER_ALL_PROGRAM_SUCCESS: "RECOVER_ALL_PROGRAM_SUCCESS",
  RECOVER_ALL_PROGRAM_FAILURE: "RECOVER_ALL_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_PROGRAM_REQUEST: "COMPLETE_DELETE_PROGRAM_REQUEST",
  COMPLETE_DELETE_PROGRAM_SUCCESS: "COMPLETE_DELETE_PROGRAM_SUCCESS",
  COMPLETE_DELETE_PROGRAM_FAILURE: "COMPLETE_DELETE_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_PROGRAM_REQUEST: "TRASH_ALL_PROGRAM_REQUEST",
  TRASH_ALL_PROGRAM_SUCCESS: "TRASH_ALL_PROGRAM_SUCCESS",
  TRASH_ALL_PROGRAM_FAILURE: "TRASH_ALL_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_PROGRAM_REQUEST: "EMPTY_TRASH_PROGRAM_REQUEST",
  EMPTY_TRASH_PROGRAM_SUCCESS: "EMPTY_TRASH_PROGRAM_SUCCESS",
  EMPTY_TRASH_PROGRAM_FAILURE: "EMPTY_TRASH_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_PROGRAM_REQUEST: "EXPORT_PROGRAM_REQUEST",
  EXPORT_PROGRAM_SUCCESS: "EXPORT_PROGRAM_SUCCESS",
  EXPORT_PROGRAM_FAILURE: "EXPORT_PROGRAM_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_PROGRAM_REQUEST: "GET_ALL_TRASHED_PROGRAM_REQUEST",
  GET_ALL_TRASHED_PROGRAM_SUCCESS: "GET_ALL_TRASHED_PROGRAM_SUCCESS",
  GET_ALL_TRASHED_PROGRAM_FAILURE: "GET_ALL_TRASHED_PROGRAM_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_PROGRAM_REQUEST: "ARCHIVE_PROGRAM_REQUEST",
  ARCHIVE_PROGRAM_SUCCESS: "ARCHIVE_PROGRAM_SUCCESS",
  ARCHIVE_PROGRAM_FAILURE: "ARCHIVE_PROGRAM_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_PROGRAM_REQUEST: "GET_ALL_ARCHIVED_PROGRAM_REQUEST",
  GET_ALL_ARCHIVED_PROGRAM_SUCCESS: "GET_ALL_ARCHIVED_PROGRAM_SUCCESS",
  GET_ALL_ARCHIVED_PROGRAM_FAILURE: "GET_ALL_ARCHIVED_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_PROGRAM_REQUEST: "RECOVER_ALL_ARCHIVED_PROGRAM_REQUEST",
  RECOVER_ALL_ARCHIVED_PROGRAM_SUCCESS: "RECOVER_ALL_ARCHIVED_PROGRAM_SUCCESS",
  RECOVER_ALL_ARCHIVED_PROGRAM_FAILURE: "RECOVER_ALL_ARCHIVED_PROGRAM_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_PROGRAM_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_PROGRAM_BY_ID_REQUEST",
  RECOVER_ARCHIVED_PROGRAM_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_PROGRAM_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_PROGRAM_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_PROGRAM_BY_ID_FAILURE",
  /************************************************************************* */
};

export default PROGRAM_ACTION_TYPES;
