const PERMISSION_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_PERMISSION_REQUEST: "GET_ALL_PERMISSION_REQUEST",
  GET_ALL_PERMISSION_SUCCESS: "GET_ALL_PERMISSION_SUCCESS",
  GET_ALL_PERMISSION_FAILURE: "GET_ALL_PERMISSION_FAILURE",

  /************************************************************************* */
  GET_BY_ID_PERMISSION_REQUEST: "GET_BY_ID_PERMISSION_REQUEST",
  GET_BY_ID_PERMISSION_SUCCESS: "GET_BY_ID_PERMISSION_SUCCESS",
  GET_BY_ID_PERMISSION_FAILURE: "GET_BY_ID_PERMISSION_FAILURE",

  /************************************************************************* */
  CREATE_PERMISSION_REQUEST: "CREATE_PERMISSION_REQUEST",
  CREATE_PERMISSION_SUCCESS: "CREATE_PERMISSION_SUCCESS",
  CREATE_PERMISSION_FAILURE: "CREATE_PERMISSION_FAILURE",

  /************************************************************************* */
  UPDATE_PERMISSION_REQUEST: "UPDATE_PERMISSION_REQUEST",
  UPDATE_PERMISSION_SUCCESS: "UPDATE_PERMISSION_SUCCESS",
  UPDATE_PERMISSION_FAILURE: "UPDATE_PERMISSION_FAILURE",

  /************************************************************************* */
  DELETE_PERMISSION_REQUEST: "DELETE_PERMISSION_REQUEST",
  DELETE_PERMISSION_SUCCESS: "DELETE_PERMISSION_SUCCESS",
  DELETE_PERMISSION_FAILURE: "DELETE_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_PERMISSION_BY_ID_REQUEST: "RECOVER_BY_ID_PERMISSION_BY_ID_REQUEST",
  RECOVER_PERMISSION_BY_ID_SUCCESS: "RECOVER_PERMISSION_BY_ID_SUCCESS",
  RECOVER_PERMISSION_BY_ID_FAILURE: "RECOVER_PERMISSION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_PERMISSION_REQUEST: "RECOVER_ALL_PERMISSION_REQUEST",
  RECOVER_ALL_PERMISSION_SUCCESS: "RECOVER_ALL_PERMISSION_SUCCESS",
  RECOVER_ALL_PERMISSION_FAILURE: "RECOVER_ALL_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_PERMISSION_REQUEST: "COMPLETE_DELETE_PERMISSION_REQUEST",
  COMPLETE_DELETE_PERMISSION_SUCCESS: "COMPLETE_DELETE_PERMISSION_SUCCESS",
  COMPLETE_DELETE_PERMISSION_FAILURE: "COMPLETE_DELETE_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_PERMISSION_REQUEST: "TRASH_ALL_PERMISSION_REQUEST",
  TRASH_ALL_PERMISSION_SUCCESS: "TRASH_ALL_PERMISSION_SUCCESS",
  TRASH_ALL_PERMISSION_FAILURE: "TRASH_ALL_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_PERMISSION_REQUEST: "EMPTY_TRASH_PERMISSION_REQUEST",
  EMPTY_TRASH_PERMISSION_SUCCESS: "EMPTY_TRASH_PERMISSION_SUCCESS",
  EMPTY_TRASH_PERMISSION_FAILURE: "EMPTY_TRASH_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_PERMISSION_REQUEST: "EXPORT_PERMISSION_REQUEST",
  EXPORT_PERMISSION_SUCCESS: "EXPORT_PERMISSION_SUCCESS",
  EXPORT_PERMISSION_FAILURE: "EXPORT_PERMISSION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_PERMISSION_REQUEST: "GET_ALL_TRASHED_PERMISSION_REQUEST",
  GET_ALL_TRASHED_PERMISSION_SUCCESS: "GET_ALL_TRASHED_PERMISSION_SUCCESS",
  GET_ALL_TRASHED_PERMISSION_FAILURE: "GET_ALL_TRASHED_PERMISSION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_PERMISSION_REQUEST: "ARCHIVE_PERMISSION_REQUEST",
  ARCHIVE_PERMISSION_SUCCESS: "ARCHIVE_PERMISSION_SUCCESS",
  ARCHIVE_PERMISSION_FAILURE: "ARCHIVE_PERMISSION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_PERMISSION_REQUEST: "GET_ALL_ARCHIVED_PERMISSION_REQUEST",
  GET_ALL_ARCHIVED_PERMISSION_SUCCESS: "GET_ALL_ARCHIVED_PERMISSION_SUCCESS",
  GET_ALL_ARCHIVED_PERMISSION_FAILURE: "GET_ALL_ARCHIVED_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_PERMISSION_REQUEST:
    "RECOVER_ALL_ARCHIVED_PERMISSION_REQUEST",
  RECOVER_ALL_ARCHIVED_PERMISSION_SUCCESS:
    "RECOVER_ALL_ARCHIVED_PERMISSION_SUCCESS",
  RECOVER_ALL_ARCHIVED_PERMISSION_FAILURE:
    "RECOVER_ALL_ARCHIVED_PERMISSION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_PERMISSION_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_PERMISSION_BY_ID_REQUEST",
  RECOVER_ARCHIVED_PERMISSION_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_PERMISSION_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_PERMISSION_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_PERMISSION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_PERMISSION_BY_CONTROLLER_REQUEST:
    "GET_ALL_PERMISSION_BY_CONTROLLER_REQUEST",
  GET_ALL_PERMISSION_BY_CONTROLLER_SUCCESS:
    "GET_ALL_PERMISSION_BY_CONTROLLER_SUCCESS",
  GET_ALL_PERMISSION_BY_CONTROLLER_FAILURE:
    "GET_ALL_PERMISSION_BY_CONTROLLER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_REQUEST:
    "GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_REQUEST",
  GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_SUCCESS:
    "GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_SUCCESS",
  GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_FAILURE:
    "GET_ALL_PERMISSION_BY_ROLE_BY_CONTROLLER_FAILURE",
  /************************************************************************* */
};

export default PERMISSION_ACTION_TYPES;
