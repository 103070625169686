const CONTACT_PERSON_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_CONTACT_PERSON_REQUEST: "GET_ALL_CONTACT_PERSON_REQUEST",
  GET_ALL_CONTACT_PERSON_SUCCESS: "GET_ALL_CONTACT_PERSON_SUCCESS",
  GET_ALL_CONTACT_PERSON_FAILURE: "GET_ALL_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  GET_BY_ID_CONTACT_PERSON_REQUEST: "GET_BY_ID_CONTACT_PERSON_REQUEST",
  GET_BY_ID_CONTACT_PERSON_SUCCESS: "GET_BY_ID_CONTACT_PERSON_SUCCESS",
  GET_BY_ID_CONTACT_PERSON_FAILURE: "GET_BY_ID_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  CREATE_CONTACT_PERSON_REQUEST: "CREATE_CONTACT_PERSON_REQUEST",
  CREATE_CONTACT_PERSON_SUCCESS: "CREATE_CONTACT_PERSON_SUCCESS",
  CREATE_CONTACT_PERSON_FAILURE: "CREATE_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  UPDATE_CONTACT_PERSON_REQUEST: "UPDATE_CONTACT_PERSON_REQUEST",
  UPDATE_CONTACT_PERSON_SUCCESS: "UPDATE_CONTACT_PERSON_SUCCESS",
  UPDATE_CONTACT_PERSON_FAILURE: "UPDATE_CONTACT_PERSON_FAILURE",

  /************************************************************************* */
  DELETE_CONTACT_PERSON_REQUEST: "DELETE_CONTACT_PERSON_REQUEST",
  DELETE_CONTACT_PERSON_SUCCESS: "DELETE_CONTACT_PERSON_SUCCESS",
  DELETE_CONTACT_PERSON_FAILURE: "DELETE_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_CONTACT_PERSON_BY_ID_REQUEST:
    "RECOVER_BY_ID_CONTACT_PERSON_BY_ID_REQUEST",
  RECOVER_CONTACT_PERSON_BY_ID_SUCCESS: "RECOVER_CONTACT_PERSON_BY_ID_SUCCESS",
  RECOVER_CONTACT_PERSON_BY_ID_FAILURE: "RECOVER_CONTACT_PERSON_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_CONTACT_PERSON_REQUEST: "RECOVER_ALL_CONTACT_PERSON_REQUEST",
  RECOVER_ALL_CONTACT_PERSON_SUCCESS: "RECOVER_ALL_CONTACT_PERSON_SUCCESS",
  RECOVER_ALL_CONTACT_PERSON_FAILURE: "RECOVER_ALL_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_CONTACT_PERSON_REQUEST:
    "COMPLETE_DELETE_CONTACT_PERSON_REQUEST",
  COMPLETE_DELETE_CONTACT_PERSON_SUCCESS:
    "COMPLETE_DELETE_CONTACT_PERSON_SUCCESS",
  COMPLETE_DELETE_CONTACT_PERSON_FAILURE:
    "COMPLETE_DELETE_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_CONTACT_PERSON_REQUEST: "TRASH_ALL_CONTACT_PERSON_REQUEST",
  TRASH_ALL_CONTACT_PERSON_SUCCESS: "TRASH_ALL_CONTACT_PERSON_SUCCESS",
  TRASH_ALL_CONTACT_PERSON_FAILURE: "TRASH_ALL_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_CONTACT_PERSON_REQUEST: "EMPTY_TRASH_CONTACT_PERSON_REQUEST",
  EMPTY_TRASH_CONTACT_PERSON_SUCCESS: "EMPTY_TRASH_CONTACT_PERSON_SUCCESS",
  EMPTY_TRASH_CONTACT_PERSON_FAILURE: "EMPTY_TRASH_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_CONTACT_PERSON_REQUEST: "EXPORT_CONTACT_PERSON_REQUEST",
  EXPORT_CONTACT_PERSON_SUCCESS: "EXPORT_CONTACT_PERSON_SUCCESS",
  EXPORT_CONTACT_PERSON_FAILURE: "EXPORT_CONTACT_PERSON_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_CONTACT_PERSON_REQUEST:
    "GET_ALL_TRASHED_CONTACT_PERSON_REQUEST",
  GET_ALL_TRASHED_CONTACT_PERSON_SUCCESS:
    "GET_ALL_TRASHED_CONTACT_PERSON_SUCCESS",
  GET_ALL_TRASHED_CONTACT_PERSON_FAILURE:
    "GET_ALL_TRASHED_CONTACT_PERSON_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_CONTACT_PERSON_REQUEST: "ARCHIVE_CONTACT_PERSON_REQUEST",
  ARCHIVE_CONTACT_PERSON_SUCCESS: "ARCHIVE_CONTACT_PERSON_SUCCESS",
  ARCHIVE_CONTACT_PERSON_FAILURE: "ARCHIVE_CONTACT_PERSON_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_CONTACT_PERSON_REQUEST:
    "GET_ALL_ARCHIVED_CONTACT_PERSON_REQUEST",
  GET_ALL_ARCHIVED_CONTACT_PERSON_SUCCESS:
    "GET_ALL_ARCHIVED_CONTACT_PERSON_SUCCESS",
  GET_ALL_ARCHIVED_CONTACT_PERSON_FAILURE:
    "GET_ALL_ARCHIVED_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_CONTACT_PERSON_REQUEST:
    "RECOVER_ALL_ARCHIVED_CONTACT_PERSON_REQUEST",
  RECOVER_ALL_ARCHIVED_CONTACT_PERSON_SUCCESS:
    "RECOVER_ALL_ARCHIVED_CONTACT_PERSON_SUCCESS",
  RECOVER_ALL_ARCHIVED_CONTACT_PERSON_FAILURE:
    "RECOVER_ALL_ARCHIVED_CONTACT_PERSON_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_REQUEST",
  RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_CONTACT_PERSON_BY_ID_FAILURE",
  /************************************************************************* */
};

export default CONTACT_PERSON_ACTION_TYPES;
