const EMPLOYEE_POSITION_TYPE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_EMPLOYEE_POSITION_TYPE_REQUEST:
    "GET_ALL_EMPLOYEE_POSITION_TYPE_REQUEST",
  GET_ALL_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "GET_ALL_EMPLOYEE_POSITION_TYPE_SUCCESS",
  GET_ALL_EMPLOYEE_POSITION_TYPE_FAILURE:
    "GET_ALL_EMPLOYEE_POSITION_TYPE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_EMPLOYEE_POSITION_TYPE_REQUEST:
    "GET_BY_ID_EMPLOYEE_POSITION_TYPE_REQUEST",
  GET_BY_ID_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "GET_BY_ID_EMPLOYEE_POSITION_TYPE_SUCCESS",
  GET_BY_ID_EMPLOYEE_POSITION_TYPE_FAILURE:
    "GET_BY_ID_EMPLOYEE_POSITION_TYPE_FAILURE",

  /************************************************************************* */
  CREATE_EMPLOYEE_POSITION_TYPE_REQUEST:
    "CREATE_EMPLOYEE_POSITION_TYPE_REQUEST",
  CREATE_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "CREATE_EMPLOYEE_POSITION_TYPE_SUCCESS",
  CREATE_EMPLOYEE_POSITION_TYPE_FAILURE:
    "CREATE_EMPLOYEE_POSITION_TYPE_FAILURE",

  /************************************************************************* */
  UPDATE_EMPLOYEE_POSITION_TYPE_REQUEST:
    "UPDATE_EMPLOYEE_POSITION_TYPE_REQUEST",
  UPDATE_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "UPDATE_EMPLOYEE_POSITION_TYPE_SUCCESS",
  UPDATE_EMPLOYEE_POSITION_TYPE_FAILURE:
    "UPDATE_EMPLOYEE_POSITION_TYPE_FAILURE",

  /************************************************************************* */
  DELETE_EMPLOYEE_POSITION_TYPE_REQUEST:
    "DELETE_EMPLOYEE_POSITION_TYPE_REQUEST",
  DELETE_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "DELETE_EMPLOYEE_POSITION_TYPE_SUCCESS",
  DELETE_EMPLOYEE_POSITION_TYPE_FAILURE:
    "DELETE_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_EMPLOYEE_POSITION_TYPE_BY_ID_REQUEST:
    "RECOVER_BY_ID_EMPLOYEE_POSITION_TYPE_BY_ID_REQUEST",
  RECOVER_EMPLOYEE_POSITION_TYPE_BY_ID_SUCCESS:
    "RECOVER_EMPLOYEE_POSITION_TYPE_BY_ID_SUCCESS",
  RECOVER_EMPLOYEE_POSITION_TYPE_BY_ID_FAILURE:
    "RECOVER_EMPLOYEE_POSITION_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_EMPLOYEE_POSITION_TYPE_REQUEST:
    "RECOVER_ALL_EMPLOYEE_POSITION_TYPE_REQUEST",
  RECOVER_ALL_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "RECOVER_ALL_EMPLOYEE_POSITION_TYPE_SUCCESS",
  RECOVER_ALL_EMPLOYEE_POSITION_TYPE_FAILURE:
    "RECOVER_ALL_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_EMPLOYEE_POSITION_TYPE_REQUEST:
    "COMPLETE_DELETE_EMPLOYEE_POSITION_TYPE_REQUEST",
  COMPLETE_DELETE_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "COMPLETE_DELETE_EMPLOYEE_POSITION_TYPE_SUCCESS",
  COMPLETE_DELETE_EMPLOYEE_POSITION_TYPE_FAILURE:
    "COMPLETE_DELETE_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_EMPLOYEE_POSITION_TYPE_REQUEST:
    "TRASH_ALL_EMPLOYEE_POSITION_TYPE_REQUEST",
  TRASH_ALL_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "TRASH_ALL_EMPLOYEE_POSITION_TYPE_SUCCESS",
  TRASH_ALL_EMPLOYEE_POSITION_TYPE_FAILURE:
    "TRASH_ALL_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_EMPLOYEE_POSITION_TYPE_REQUEST:
    "EMPTY_TRASH_EMPLOYEE_POSITION_TYPE_REQUEST",
  EMPTY_TRASH_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "EMPTY_TRASH_EMPLOYEE_POSITION_TYPE_SUCCESS",
  EMPTY_TRASH_EMPLOYEE_POSITION_TYPE_FAILURE:
    "EMPTY_TRASH_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_EMPLOYEE_POSITION_TYPE_REQUEST:
    "EXPORT_EMPLOYEE_POSITION_TYPE_REQUEST",
  EXPORT_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "EXPORT_EMPLOYEE_POSITION_TYPE_SUCCESS",
  EXPORT_EMPLOYEE_POSITION_TYPE_FAILURE:
    "EXPORT_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_EMPLOYEE_POSITION_TYPE_REQUEST:
    "GET_ALL_TRASHED_EMPLOYEE_POSITION_TYPE_REQUEST",
  GET_ALL_TRASHED_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "GET_ALL_TRASHED_EMPLOYEE_POSITION_TYPE_SUCCESS",
  GET_ALL_TRASHED_EMPLOYEE_POSITION_TYPE_FAILURE:
    "GET_ALL_TRASHED_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_EMPLOYEE_POSITION_TYPE_REQUEST:
    "ARCHIVE_EMPLOYEE_POSITION_TYPE_REQUEST",
  ARCHIVE_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "ARCHIVE_EMPLOYEE_POSITION_TYPE_SUCCESS",
  ARCHIVE_EMPLOYEE_POSITION_TYPE_FAILURE:
    "ARCHIVE_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_REQUEST:
    "GET_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_REQUEST",
  GET_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "GET_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_SUCCESS",
  GET_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_FAILURE:
    "GET_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_REQUEST:
    "RECOVER_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_REQUEST",
  RECOVER_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_SUCCESS:
    "RECOVER_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_SUCCESS",
  RECOVER_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_FAILURE:
    "RECOVER_ALL_ARCHIVED_EMPLOYEE_POSITION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_EMPLOYEE_POSITION_TYPE_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_EMPLOYEE_POSITION_TYPE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_EMPLOYEE_POSITION_TYPE_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_EMPLOYEE_POSITION_TYPE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_EMPLOYEE_POSITION_TYPE_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_EMPLOYEE_POSITION_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default EMPLOYEE_POSITION_TYPE_ACTION_TYPES;
