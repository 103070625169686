const ITEM_CATEGORY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ITEM_CATEGORY_REQUEST: "GET_ALL_ITEM_CATEGORY_REQUEST",
  GET_ALL_ITEM_CATEGORY_SUCCESS: "GET_ALL_ITEM_CATEGORY_SUCCESS",
  GET_ALL_ITEM_CATEGORY_FAILURE: "GET_ALL_ITEM_CATEGORY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ITEM_CATEGORY_REQUEST: "GET_BY_ID_ITEM_CATEGORY_REQUEST",
  GET_BY_ID_ITEM_CATEGORY_SUCCESS: "GET_BY_ID_ITEM_CATEGORY_SUCCESS",
  GET_BY_ID_ITEM_CATEGORY_FAILURE: "GET_BY_ID_ITEM_CATEGORY_FAILURE",

  /************************************************************************* */
  CREATE_ITEM_CATEGORY_REQUEST: "CREATE_ITEM_CATEGORY_REQUEST",
  CREATE_ITEM_CATEGORY_SUCCESS: "CREATE_ITEM_CATEGORY_SUCCESS",
  CREATE_ITEM_CATEGORY_FAILURE: "CREATE_ITEM_CATEGORY_FAILURE",

  /************************************************************************* */
  UPDATE_ITEM_CATEGORY_REQUEST: "UPDATE_ITEM_CATEGORY_REQUEST",
  UPDATE_ITEM_CATEGORY_SUCCESS: "UPDATE_ITEM_CATEGORY_SUCCESS",
  UPDATE_ITEM_CATEGORY_FAILURE: "UPDATE_ITEM_CATEGORY_FAILURE",

  /************************************************************************* */
  DELETE_ITEM_CATEGORY_REQUEST: "DELETE_ITEM_CATEGORY_REQUEST",
  DELETE_ITEM_CATEGORY_SUCCESS: "DELETE_ITEM_CATEGORY_SUCCESS",
  DELETE_ITEM_CATEGORY_FAILURE: "DELETE_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ITEM_CATEGORY_BY_ID_REQUEST:
    "RECOVER_BY_ID_ITEM_CATEGORY_BY_ID_REQUEST",
  RECOVER_ITEM_CATEGORY_BY_ID_SUCCESS: "RECOVER_ITEM_CATEGORY_BY_ID_SUCCESS",
  RECOVER_ITEM_CATEGORY_BY_ID_FAILURE: "RECOVER_ITEM_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ITEM_CATEGORY_REQUEST: "RECOVER_ALL_ITEM_CATEGORY_REQUEST",
  RECOVER_ALL_ITEM_CATEGORY_SUCCESS: "RECOVER_ALL_ITEM_CATEGORY_SUCCESS",
  RECOVER_ALL_ITEM_CATEGORY_FAILURE: "RECOVER_ALL_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ITEM_CATEGORY_REQUEST:
    "COMPLETE_DELETE_ITEM_CATEGORY_REQUEST",
  COMPLETE_DELETE_ITEM_CATEGORY_SUCCESS:
    "COMPLETE_DELETE_ITEM_CATEGORY_SUCCESS",
  COMPLETE_DELETE_ITEM_CATEGORY_FAILURE:
    "COMPLETE_DELETE_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ITEM_CATEGORY_REQUEST: "TRASH_ALL_ITEM_CATEGORY_REQUEST",
  TRASH_ALL_ITEM_CATEGORY_SUCCESS: "TRASH_ALL_ITEM_CATEGORY_SUCCESS",
  TRASH_ALL_ITEM_CATEGORY_FAILURE: "TRASH_ALL_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ITEM_CATEGORY_REQUEST: "EMPTY_TRASH_ITEM_CATEGORY_REQUEST",
  EMPTY_TRASH_ITEM_CATEGORY_SUCCESS: "EMPTY_TRASH_ITEM_CATEGORY_SUCCESS",
  EMPTY_TRASH_ITEM_CATEGORY_FAILURE: "EMPTY_TRASH_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ITEM_CATEGORY_REQUEST: "EXPORT_ITEM_CATEGORY_REQUEST",
  EXPORT_ITEM_CATEGORY_SUCCESS: "EXPORT_ITEM_CATEGORY_SUCCESS",
  EXPORT_ITEM_CATEGORY_FAILURE: "EXPORT_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ITEM_CATEGORY_REQUEST:
    "GET_ALL_TRASHED_ITEM_CATEGORY_REQUEST",
  GET_ALL_TRASHED_ITEM_CATEGORY_SUCCESS:
    "GET_ALL_TRASHED_ITEM_CATEGORY_SUCCESS",
  GET_ALL_TRASHED_ITEM_CATEGORY_FAILURE:
    "GET_ALL_TRASHED_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ITEM_CATEGORY_REQUEST: "ARCHIVE_ITEM_CATEGORY_REQUEST",
  ARCHIVE_ITEM_CATEGORY_SUCCESS: "ARCHIVE_ITEM_CATEGORY_SUCCESS",
  ARCHIVE_ITEM_CATEGORY_FAILURE: "ARCHIVE_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ITEM_CATEGORY_REQUEST:
    "GET_ALL_ARCHIVED_ITEM_CATEGORY_REQUEST",
  GET_ALL_ARCHIVED_ITEM_CATEGORY_SUCCESS:
    "GET_ALL_ARCHIVED_ITEM_CATEGORY_SUCCESS",
  GET_ALL_ARCHIVED_ITEM_CATEGORY_FAILURE:
    "GET_ALL_ARCHIVED_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ITEM_CATEGORY_REQUEST:
    "RECOVER_ALL_ARCHIVED_ITEM_CATEGORY_REQUEST",
  RECOVER_ALL_ARCHIVED_ITEM_CATEGORY_SUCCESS:
    "RECOVER_ALL_ARCHIVED_ITEM_CATEGORY_SUCCESS",
  RECOVER_ALL_ARCHIVED_ITEM_CATEGORY_FAILURE:
    "RECOVER_ALL_ARCHIVED_ITEM_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ITEM_CATEGORY_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ITEM_CATEGORY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ITEM_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ITEM_CATEGORY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ITEM_CATEGORY_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ITEM_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ITEM_CATEGORY_ACTION_TYPES;
