import SHELF_ACTION_TYPES from "../actionTypes/shelfActionTypes";

const initialState = {
  isLoading: false,
  shelfState: [],
  shelfErrorState: "",
};

const SHELF_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case SHELF_ACTION_TYPES.CREATE_SHELF_SUCCESS:
      return {
        ...state,
      };
    // case SHELF_ACTION_TYPES.CREATE_SHELF_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case SHELF_ACTION_TYPES.CREATE_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case SHELF_ACTION_TYPES.UPDATE_SHELF_SUCCESS:
      return {
        ...state,
        shelfState: state.shelfState.map((sl) =>
          sl.id === action.payload ? action.payload : sl
        ),
      };
    case SHELF_ACTION_TYPES.UPDATE_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case SHELF_ACTION_TYPES.DELETE_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.DELETE_SHELF_SUCCESS:
      return {
        ...state,
      };
    case SHELF_ACTION_TYPES.DELETE_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case SHELF_ACTION_TYPES.GET_ALL_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.GET_ALL_SHELF_SUCCESS:
      return { ...state, shelfState: [...action.payload] };
    case SHELF_ACTION_TYPES.GET_ALL_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case SHELF_ACTION_TYPES.GET_BY_ID_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.GET_BY_ID_SHELF_SUCCESS:
      return {
        ...state,
        shelfState: action.payload,
      };
    case SHELF_ACTION_TYPES.GET_BY_ID_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case SHELF_ACTION_TYPES.COMPLETE_DELETE_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.COMPLETE_DELETE_SHELF_SUCCESS:
      return {
        ...state,
      };
    case SHELF_ACTION_TYPES.COMPLETE_DELETE_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case SHELF_ACTION_TYPES.EMPTY_TRASH_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.EMPTY_TRASH_SHELF_SUCCESS:
      return {
        ...state,
      };
    case SHELF_ACTION_TYPES.EMPTY_TRASH_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case SHELF_ACTION_TYPES.EXPORT_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.EXPORT_SHELF_SUCCESS:
      return {
        ...state,
        // shelfState: action.payload,
      };
    case SHELF_ACTION_TYPES.EXPORT_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case SHELF_ACTION_TYPES.RECOVER_ALL_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.RECOVER_ALL_SHELF_SUCCESS:
      return {
        ...state,
      };
    case SHELF_ACTION_TYPES.RECOVER_ALL_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_SHELF_BY_ID***********************************************/
    // case SHELF_ACTION_TYPES.RECOVER_SHELF_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.RECOVER_SHELF_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case SHELF_ACTION_TYPES.RECOVER_SHELF_BY_ID_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************RECOVER_SHELF_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case SHELF_ACTION_TYPES.TRASH_ALL_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.TRASH_ALL_SHELF_SUCCESS:
      return { ...state };
    case SHELF_ACTION_TYPES.TRASH_ALL_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case SHELF_ACTION_TYPES.GET_ALL_TRASHED_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.GET_ALL_TRASHED_SHELF_SUCCESS:
      return {
        ...state,
        shelfState: [...action.payload],
      };
    case SHELF_ACTION_TYPES.GET_ALL_TRASHED_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case SHELF_ACTION_TYPES.ARCHIVE_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.ARCHIVE_SHELF_SUCCESS:
      return {
        ...state,
      };
    case SHELF_ACTION_TYPES.ARCHIVE_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case SHELF_ACTION_TYPES.GET_ALL_ARCHIVED_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.GET_ALL_ARCHIVED_SHELF_SUCCESS:
      return {
        ...state,
        shelfState: [...action.payload],
      };
    case SHELF_ACTION_TYPES.GET_ALL_ARCHIVED_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case SHELF_ACTION_TYPES.RECOVER_ALL_ARCHIVED_SHELF_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.RECOVER_ALL_ARCHIVED_SHELF_SUCCESS:
      return {
        ...state,
        // shelfState: [...action.payload],
      };
    case SHELF_ACTION_TYPES.RECOVER_ALL_ARCHIVED_SHELF_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case SHELF_ACTION_TYPES.RECOVER_ARCHIVED_SHELF_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case SHELF_ACTION_TYPES.RECOVER_ARCHIVED_SHELF_BY_ID_SUCCESS:
      return {
        ...state,
        shelfState: [...action.payload],
      };
    case SHELF_ACTION_TYPES.RECOVER_ARCHIVED_SHELF_BY_ID_FAILURE:
      return {
        shelfErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default SHELF_REDUCER;
