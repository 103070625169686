import BID_ACTION_TYPES from "../actionTypes/bidActionTypes";

const initialState = {
  isLoading: false,
  bidState: [],
  bidErrorState: "",
};

const BID_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case BID_ACTION_TYPES.CREATE_BID_SUCCESS:
      return {
        ...state,
      };
    // case BID_ACTION_TYPES.CREATE_BID_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case BID_ACTION_TYPES.CREATE_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case BID_ACTION_TYPES.UPDATE_BID_SUCCESS:
      return {
        ...state,
        bidState: state.bidState.map((bid) =>
          bid.id === action.payload ? action.payload : bid
        ),
      };
    case BID_ACTION_TYPES.UPDATE_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case BID_ACTION_TYPES.DELETE_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.DELETE_BID_SUCCESS:
      return {
        ...state,
      };
    case BID_ACTION_TYPES.DELETE_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case BID_ACTION_TYPES.GET_ALL_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.GET_ALL_BID_SUCCESS:
      return { ...state, bidState: [...action.payload] };
    case BID_ACTION_TYPES.GET_ALL_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case BID_ACTION_TYPES.GET_BY_ID_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.GET_BY_ID_BID_SUCCESS:
      return {
        ...state,
        bidState: action.payload,
      };
    case BID_ACTION_TYPES.GET_BY_ID_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case BID_ACTION_TYPES.COMPLETE_DELETE_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.COMPLETE_DELETE_BID_SUCCESS:
      return {
        ...state,
      };
    case BID_ACTION_TYPES.COMPLETE_DELETE_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case BID_ACTION_TYPES.EMPTY_TRASH_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.EMPTY_TRASH_BID_SUCCESS:
      return {
        ...state,
      };
    case BID_ACTION_TYPES.EMPTY_TRASH_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case BID_ACTION_TYPES.EXPORT_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.EXPORT_BID_SUCCESS:
      return {
        ...state,
        // bidState: action.payload,
      };
    case BID_ACTION_TYPES.EXPORT_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case BID_ACTION_TYPES.RECOVER_ALL_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.RECOVER_ALL_BID_SUCCESS:
      return {
        ...state,
      };
    case BID_ACTION_TYPES.RECOVER_ALL_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_BID_BY_ID***********************************************/
    // case BID_ACTION_TYPES.RECOVER_BID_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.RECOVER_BID_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case BID_ACTION_TYPES.RECOVER_BID_BY_ID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************RECOVER_BID_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case BID_ACTION_TYPES.TRASH_ALL_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.TRASH_ALL_BID_SUCCESS:
      return { ...state };
    case BID_ACTION_TYPES.TRASH_ALL_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case BID_ACTION_TYPES.GET_ALL_TRASHED_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.GET_ALL_TRASHED_BID_SUCCESS:
      return {
        ...state,
        bidState: [...action.payload],
      };
    case BID_ACTION_TYPES.GET_ALL_TRASHED_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case BID_ACTION_TYPES.ARCHIVE_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.ARCHIVE_BID_SUCCESS:
      return {
        ...state,
      };
    case BID_ACTION_TYPES.ARCHIVE_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case BID_ACTION_TYPES.GET_ALL_ARCHIVED_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.GET_ALL_ARCHIVED_BID_SUCCESS:
      return {
        ...state,
        bidState: [...action.payload],
      };
    case BID_ACTION_TYPES.GET_ALL_ARCHIVED_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case BID_ACTION_TYPES.RECOVER_ALL_ARCHIVED_BID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.RECOVER_ALL_ARCHIVED_BID_SUCCESS:
      return {
        ...state,
        // bidState: [...action.payload],
      };
    case BID_ACTION_TYPES.RECOVER_ALL_ARCHIVED_BID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case BID_ACTION_TYPES.RECOVER_ARCHIVED_BID_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case BID_ACTION_TYPES.RECOVER_ARCHIVED_BID_BY_ID_SUCCESS:
      return {
        ...state,
        bidState: [...action.payload],
      };
    case BID_ACTION_TYPES.RECOVER_ARCHIVED_BID_BY_ID_FAILURE:
      return {
        bidErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default BID_REDUCER;
