const MESSAGE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_MESSAGE_REQUEST: "GET_ALL_MESSAGE_REQUEST",
  GET_ALL_MESSAGE_SUCCESS: "GET_ALL_MESSAGE_SUCCESS",
  GET_ALL_MESSAGE_FAILURE: "GET_ALL_MESSAGE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_MESSAGE_REQUEST: "GET_BY_ID_MESSAGE_REQUEST",
  GET_BY_ID_MESSAGE_SUCCESS: "GET_BY_ID_MESSAGE_SUCCESS",
  GET_BY_ID_MESSAGE_FAILURE: "GET_BY_ID_MESSAGE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_BY_USER_ID_MESSAGE_REQUEST: "GET_BY_USER_ID_MESSAGE_REQUEST",
  GET_BY_USER_ID_MESSAGE_SUCCESS: "GET_BY_USER_ID_MESSAGE_SUCCESS",
  GET_BY_USER_ID_MESSAGE_FAILURE: "GET_BY_USER_ID_MESSAGE_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_MESSAGE_BY_USERID_REQUEST: "GET_MESSAGE_BY_USERID_REQUEST",
  GET_MESSAGE_BY_USERID_SUCCESS: "GET_MESSAGE_BY_USERID_SUCCESS",
  GET_MESSAGE_BY_USERID_FAILURE: "GET_MESSAGE_BY_USERID_FAILURE",

  /************************************************************************* */
  CREATE_MESSAGE_REQUEST: "CREATE_MESSAGE_REQUEST",
  CREATE_MESSAGE_SUCCESS: "CREATE_MESSAGE_SUCCESS",
  CREATE_MESSAGE_FAILURE: "CREATE_MESSAGE_FAILURE",

  /************************************************************************* */
  UPDATE_MESSAGE_REQUEST: "UPDATE_MESSAGE_REQUEST",
  UPDATE_MESSAGE_SUCCESS: "UPDATE_MESSAGE_SUCCESS",
  UPDATE_MESSAGE_FAILURE: "UPDATE_MESSAGE_FAILURE",

  /************************************************************************* */
  DELETE_MESSAGE_REQUEST: "DELETE_MESSAGE_REQUEST",
  DELETE_MESSAGE_SUCCESS: "DELETE_MESSAGE_SUCCESS",
  DELETE_MESSAGE_FAILURE: "DELETE_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_MESSAGE_BY_ID_REQUEST: "RECOVER_BY_ID_MESSAGE_BY_ID_REQUEST",
  RECOVER_MESSAGE_BY_ID_SUCCESS: "RECOVER_MESSAGE_BY_ID_SUCCESS",
  RECOVER_MESSAGE_BY_ID_FAILURE: "RECOVER_MESSAGE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_MESSAGE_REQUEST: "RECOVER_ALL_MESSAGE_REQUEST",
  RECOVER_ALL_MESSAGE_SUCCESS: "RECOVER_ALL_MESSAGE_SUCCESS",
  RECOVER_ALL_MESSAGE_FAILURE: "RECOVER_ALL_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_MESSAGE_REQUEST: "COMPLETE_DELETE_MESSAGE_REQUEST",
  COMPLETE_DELETE_MESSAGE_SUCCESS: "COMPLETE_DELETE_MESSAGE_SUCCESS",
  COMPLETE_DELETE_MESSAGE_FAILURE: "COMPLETE_DELETE_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_MESSAGE_REQUEST: "TRASH_ALL_MESSAGE_REQUEST",
  TRASH_ALL_MESSAGE_SUCCESS: "TRASH_ALL_MESSAGE_SUCCESS",
  TRASH_ALL_MESSAGE_FAILURE: "TRASH_ALL_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_MESSAGE_REQUEST: "EMPTY_TRASH_MESSAGE_REQUEST",
  EMPTY_TRASH_MESSAGE_SUCCESS: "EMPTY_TRASH_MESSAGE_SUCCESS",
  EMPTY_TRASH_MESSAGE_FAILURE: "EMPTY_TRASH_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_MESSAGE_REQUEST: "EXPORT_MESSAGE_REQUEST",
  EXPORT_MESSAGE_SUCCESS: "EXPORT_MESSAGE_SUCCESS",
  EXPORT_MESSAGE_FAILURE: "EXPORT_MESSAGE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_MESSAGE_REQUEST: "GET_ALL_TRASHED_MESSAGE_REQUEST",
  GET_ALL_TRASHED_MESSAGE_SUCCESS: "GET_ALL_TRASHED_MESSAGE_SUCCESS",
  GET_ALL_TRASHED_MESSAGE_FAILURE: "GET_ALL_TRASHED_MESSAGE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_MESSAGE_REQUEST: "ARCHIVE_MESSAGE_REQUEST",
  ARCHIVE_MESSAGE_SUCCESS: "ARCHIVE_MESSAGE_SUCCESS",
  ARCHIVE_MESSAGE_FAILURE: "ARCHIVE_MESSAGE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_MESSAGE_REQUEST: "GET_ALL_ARCHIVED_MESSAGE_REQUEST",
  GET_ALL_ARCHIVED_MESSAGE_SUCCESS: "GET_ALL_ARCHIVED_MESSAGE_SUCCESS",
  GET_ALL_ARCHIVED_MESSAGE_FAILURE: "GET_ALL_ARCHIVED_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_MESSAGE_REQUEST: "RECOVER_ALL_ARCHIVED_MESSAGE_REQUEST",
  RECOVER_ALL_ARCHIVED_MESSAGE_SUCCESS: "RECOVER_ALL_ARCHIVED_MESSAGE_SUCCESS",
  RECOVER_ALL_ARCHIVED_MESSAGE_FAILURE: "RECOVER_ALL_ARCHIVED_MESSAGE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_MESSAGE_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_MESSAGE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_MESSAGE_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_MESSAGE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_MESSAGE_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_MESSAGE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default MESSAGE_ACTION_TYPES;
