const STORE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_STORE_REQUEST: "GET_ALL_STORE_REQUEST",
  GET_ALL_STORE_SUCCESS: "GET_ALL_STORE_SUCCESS",
  GET_ALL_STORE_FAILURE: "GET_ALL_STORE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_STORE_REQUEST: "GET_BY_ID_STORE_REQUEST",
  GET_BY_ID_STORE_SUCCESS: "GET_BY_ID_STORE_SUCCESS",
  GET_BY_ID_STORE_FAILURE: "GET_BY_ID_STORE_FAILURE",

  /************************************************************************* */
  CREATE_STORE_REQUEST: "CREATE_STORE_REQUEST",
  CREATE_STORE_SUCCESS: "CREATE_STORE_SUCCESS",
  CREATE_STORE_FAILURE: "CREATE_STORE_FAILURE",

  /************************************************************************* */
  UPDATE_STORE_REQUEST: "UPDATE_STORE_REQUEST",
  UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",
  UPDATE_STORE_FAILURE: "UPDATE_STORE_FAILURE",

  /************************************************************************* */
  DELETE_STORE_REQUEST: "DELETE_STORE_REQUEST",
  DELETE_STORE_SUCCESS: "DELETE_STORE_SUCCESS",
  DELETE_STORE_FAILURE: "DELETE_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_STORE_BY_ID_REQUEST: "RECOVER_BY_ID_STORE_BY_ID_REQUEST",
  RECOVER_STORE_BY_ID_SUCCESS: "RECOVER_STORE_BY_ID_SUCCESS",
  RECOVER_STORE_BY_ID_FAILURE: "RECOVER_STORE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_STORE_REQUEST: "RECOVER_ALL_STORE_REQUEST",
  RECOVER_ALL_STORE_SUCCESS: "RECOVER_ALL_STORE_SUCCESS",
  RECOVER_ALL_STORE_FAILURE: "RECOVER_ALL_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_STORE_REQUEST: "COMPLETE_DELETE_STORE_REQUEST",
  COMPLETE_DELETE_STORE_SUCCESS: "COMPLETE_DELETE_STORE_SUCCESS",
  COMPLETE_DELETE_STORE_FAILURE: "COMPLETE_DELETE_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_STORE_REQUEST: "TRASH_ALL_STORE_REQUEST",
  TRASH_ALL_STORE_SUCCESS: "TRASH_ALL_STORE_SUCCESS",
  TRASH_ALL_STORE_FAILURE: "TRASH_ALL_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_STORE_REQUEST: "EMPTY_TRASH_STORE_REQUEST",
  EMPTY_TRASH_STORE_SUCCESS: "EMPTY_TRASH_STORE_SUCCESS",
  EMPTY_TRASH_STORE_FAILURE: "EMPTY_TRASH_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_STORE_REQUEST: "EXPORT_STORE_REQUEST",
  EXPORT_STORE_SUCCESS: "EXPORT_STORE_SUCCESS",
  EXPORT_STORE_FAILURE: "EXPORT_STORE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_STORE_REQUEST: "GET_ALL_TRASHED_STORE_REQUEST",
  GET_ALL_TRASHED_STORE_SUCCESS: "GET_ALL_TRASHED_STORE_SUCCESS",
  GET_ALL_TRASHED_STORE_FAILURE: "GET_ALL_TRASHED_STORE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_STORE_REQUEST: "ARCHIVE_STORE_REQUEST",
  ARCHIVE_STORE_SUCCESS: "ARCHIVE_STORE_SUCCESS",
  ARCHIVE_STORE_FAILURE: "ARCHIVE_STORE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_STORE_REQUEST: "GET_ALL_ARCHIVED_STORE_REQUEST",
  GET_ALL_ARCHIVED_STORE_SUCCESS: "GET_ALL_ARCHIVED_STORE_SUCCESS",
  GET_ALL_ARCHIVED_STORE_FAILURE: "GET_ALL_ARCHIVED_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_STORE_REQUEST: "RECOVER_ALL_ARCHIVED_STORE_REQUEST",
  RECOVER_ALL_ARCHIVED_STORE_SUCCESS: "RECOVER_ALL_ARCHIVED_STORE_SUCCESS",
  RECOVER_ALL_ARCHIVED_STORE_FAILURE: "RECOVER_ALL_ARCHIVED_STORE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_STORE_BY_ID_REQUEST: "RECOVER_ARCHIVED_STORE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_STORE_BY_ID_SUCCESS: "RECOVER_ARCHIVED_STORE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_STORE_BY_ID_FAILURE: "RECOVER_ARCHIVED_STORE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default STORE_ACTION_TYPES;
