import REPORT_ACTION_TYPES from "../actionTypes/reportActionTypes";

const initialState = {
  isLoading: false,
  reportState: [],
  reportErrorState: "",
};

const REPORT_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case REPORT_ACTION_TYPES.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        reportState: action.payload,
      };
    // case REPORT_ACTION_TYPES.CREATE_REPORT_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case REPORT_ACTION_TYPES.GENERATE_REPORT_FAILURE:
      return {
        reportErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //*******************************CREATE******************************************* */
    case REPORT_ACTION_TYPES.GENERATE_PROJECT_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        reportState: action.payload,
      };
    // case REPORT_ACTION_TYPES.CREATE_REPORT_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case REPORT_ACTION_TYPES.GENERATE_PROJECT_REPORT_BY_ID_FAILURE:
      return {
        reportErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */

    default:
      return state;
  }
};

export default REPORT_REDUCER;
