import MATERIAL_ACTION_TYPES from "../actionTypes/materialActionTypes";

const initialState = {
  isLoading: false,
  materialState: [],
  materialErrorState: "",
};

const MATERIAL_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case MATERIAL_ACTION_TYPES.CREATE_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    // case MATERIAL_ACTION_TYPES.CREATE_MATERIAL_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case MATERIAL_ACTION_TYPES.CREATE_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case MATERIAL_ACTION_TYPES.UPDATE_MATERIAL_SUCCESS:
      return {
        ...state,
        materialState: state.materialState.map((mt) =>
          mt.id === action.payload ? action.payload : mt
        ),
      };
    case MATERIAL_ACTION_TYPES.UPDATE_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case MATERIAL_ACTION_TYPES.DELETE_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case MATERIAL_ACTION_TYPES.DELETE_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case MATERIAL_ACTION_TYPES.GET_ALL_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.GET_ALL_MATERIAL_SUCCESS:
      return { ...state, materialState: [...action.payload] };
    case MATERIAL_ACTION_TYPES.GET_ALL_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case MATERIAL_ACTION_TYPES.GET_BY_ID_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.GET_BY_ID_MATERIAL_SUCCESS:
      return {
        ...state,
        materialState: action.payload,
      };
    case MATERIAL_ACTION_TYPES.GET_BY_ID_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case MATERIAL_ACTION_TYPES.COMPLETE_DELETE_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.COMPLETE_DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case MATERIAL_ACTION_TYPES.COMPLETE_DELETE_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case MATERIAL_ACTION_TYPES.EMPTY_TRASH_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.EMPTY_TRASH_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case MATERIAL_ACTION_TYPES.EMPTY_TRASH_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case MATERIAL_ACTION_TYPES.EXPORT_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.EXPORT_MATERIAL_SUCCESS:
      return {
        ...state,
        // materialState: action.payload,
      };
    case MATERIAL_ACTION_TYPES.EXPORT_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case MATERIAL_ACTION_TYPES.RECOVER_ALL_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.RECOVER_ALL_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case MATERIAL_ACTION_TYPES.RECOVER_ALL_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_MATERIAL_BY_ID***********************************************/
    // case MATERIAL_ACTION_TYPES.RECOVER_MATERIAL_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.RECOVER_MATERIAL_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case MATERIAL_ACTION_TYPES.RECOVER_MATERIAL_BY_ID_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************RECOVER_MATERIAL_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case MATERIAL_ACTION_TYPES.TRASH_ALL_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.TRASH_ALL_MATERIAL_SUCCESS:
      return { ...state };
    case MATERIAL_ACTION_TYPES.TRASH_ALL_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case MATERIAL_ACTION_TYPES.GET_ALL_TRASHED_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.GET_ALL_TRASHED_MATERIAL_SUCCESS:
      return {
        ...state,
        materialState: [...action.payload],
      };
    case MATERIAL_ACTION_TYPES.GET_ALL_TRASHED_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case MATERIAL_ACTION_TYPES.ARCHIVE_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.ARCHIVE_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case MATERIAL_ACTION_TYPES.ARCHIVE_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case MATERIAL_ACTION_TYPES.GET_ALL_ARCHIVED_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.GET_ALL_ARCHIVED_MATERIAL_SUCCESS:
      return {
        ...state,
        materialState: [...action.payload],
      };
    case MATERIAL_ACTION_TYPES.GET_ALL_ARCHIVED_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case MATERIAL_ACTION_TYPES.RECOVER_ALL_ARCHIVED_MATERIAL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.RECOVER_ALL_ARCHIVED_MATERIAL_SUCCESS:
      return {
        ...state,
        // materialState: [...action.payload],
      };
    case MATERIAL_ACTION_TYPES.RECOVER_ALL_ARCHIVED_MATERIAL_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case MATERIAL_ACTION_TYPES.RECOVER_ARCHIVED_MATERIAL_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case MATERIAL_ACTION_TYPES.RECOVER_ARCHIVED_MATERIAL_BY_ID_SUCCESS:
      return {
        ...state,
        materialState: [...action.payload],
      };
    case MATERIAL_ACTION_TYPES.RECOVER_ARCHIVED_MATERIAL_BY_ID_FAILURE:
      return {
        materialErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default MATERIAL_REDUCER;
