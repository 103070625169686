import ACTION_ACTION_TYPES from "../actionTypes/actionActionTypes";

const initialState = {
  isLoading: false,
  actionState: [],
  actionErrorState: "",
};

const ACTION_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case ACTION_ACTION_TYPES.CREATE_ACTION_SUCCESS:
      return {
        ...state,
      };
    // case ACTION_ACTION_TYPES.CREATE_ACTION_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case ACTION_ACTION_TYPES.CREATE_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case ACTION_ACTION_TYPES.UPDATE_ACTION_SUCCESS:
      return {
        ...state,
        actionState: state.actionState.map((act) =>
          act.id === action.payload ? action.payload : act
        ),
      };
    case ACTION_ACTION_TYPES.UPDATE_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case ACTION_ACTION_TYPES.DELETE_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.DELETE_ACTION_SUCCESS:
      return {
        ...state,
      };
    case ACTION_ACTION_TYPES.DELETE_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case ACTION_ACTION_TYPES.GET_ALL_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.GET_ALL_ACTION_SUCCESS:
      return { ...state, actionState: [...action.payload] };
    case ACTION_ACTION_TYPES.GET_ALL_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case ACTION_ACTION_TYPES.GET_BY_ID_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.GET_BY_ID_ACTION_SUCCESS:
      return {
        ...state,
        actionState: action.payload,
      };
    case ACTION_ACTION_TYPES.GET_BY_ID_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* GET_ACTION_NAMES***********************************************/
    // case ACTION_ACTION_TYPES.GET_ACTION_NAMES_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.GET_ACTION_NAMES_SUCCESS:
      return {
        ...state,
        actionState: action.payload,
      };
    case ACTION_ACTION_TYPES.GET_ACTION_NAMES_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case ACTION_ACTION_TYPES.COMPLETE_DELETE_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.COMPLETE_DELETE_ACTION_SUCCESS:
      return {
        ...state,
      };
    case ACTION_ACTION_TYPES.COMPLETE_DELETE_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case ACTION_ACTION_TYPES.EMPTY_TRASH_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.EMPTY_TRASH_ACTION_SUCCESS:
      return {
        ...state,
      };
    case ACTION_ACTION_TYPES.EMPTY_TRASH_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case ACTION_ACTION_TYPES.EXPORT_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.EXPORT_ACTION_SUCCESS:
      return {
        ...state,
        //actionState: action.payload,
      };
    case ACTION_ACTION_TYPES.EXPORT_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case ACTION_ACTION_TYPES.RECOVER_ALL_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.RECOVER_ALL_ACTION_SUCCESS:
      return {
        ...state,
      };
    case ACTION_ACTION_TYPES.RECOVER_ALL_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_ACTION_BY_ID***********************************************/
    // case ACTION_ACTION_TYPES.RECOVER_ACTION_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.RECOVER_ACTION_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case ACTION_ACTION_TYPES.RECOVER_ACTION_BY_ID_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************RECOVER_ACTION_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case ACTION_ACTION_TYPES.TRASH_ALL_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.TRASH_ALL_ACTION_SUCCESS:
      return { ...state };
    case ACTION_ACTION_TYPES.TRASH_ALL_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case ACTION_ACTION_TYPES.GET_ALL_TRASHED_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.GET_ALL_TRASHED_ACTION_SUCCESS:
      return {
        ...state,
        actionState: [...action.payload],
      };
    case ACTION_ACTION_TYPES.GET_ALL_TRASHED_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ACTION_ACTION_TYPES.ARCHIVE_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.ARCHIVE_ACTION_SUCCESS:
      return {
        ...state,
      };
    case ACTION_ACTION_TYPES.ARCHIVE_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ACTION_ACTION_TYPES.GET_ALL_ARCHIVED_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.GET_ALL_ARCHIVED_ACTION_SUCCESS:
      return {
        ...state,
        actionState: [...action.payload],
      };
    case ACTION_ACTION_TYPES.GET_ALL_ARCHIVED_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ACTION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ACTION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ACTION_SUCCESS:
      return {
        ...state,
        //actionState: [...action.payload],
      };
    case ACTION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ACTION_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ACTION_ACTION_TYPES.RECOVER_ARCHIVED_ACTION_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTION_ACTION_TYPES.RECOVER_ARCHIVED_ACTION_BY_ID_SUCCESS:
      return {
        ...state,
        actionState: [...action.payload],
      };
    case ACTION_ACTION_TYPES.RECOVER_ARCHIVED_ACTION_BY_ID_FAILURE:
      return {
        actionErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default ACTION_REDUCER;
