const RIGHT_HOLDER_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_RIGHT_HOLDER_REQUEST: "GET_ALL_RIGHT_HOLDER_REQUEST",
  GET_ALL_RIGHT_HOLDER_SUCCESS: "GET_ALL_RIGHT_HOLDER_SUCCESS",
  GET_ALL_RIGHT_HOLDER_FAILURE: "GET_ALL_RIGHT_HOLDER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_RIGHT_HOLDER_BY_PROJECT_ID_REQUEST:
    "GET_ALL_RIGHT_HOLDER_BY_PROJECT_ID_REQUEST",
  GET_ALL_RIGHT_HOLDER_BY_PROJECT_ID_SUCCESS:
    "GET_ALL_RIGHT_HOLDER_BY_PROJECT_ID_SUCCESS",
  GET_ALL_RIGHT_HOLDER_BY_PROJECT_ID_FAILURE:
    "GET_ALL_RIGHT_HOLDER_BY_PROJECT_ID_FAILURE",

  /************************************************************************* */
  GET_BY_ID_RIGHT_HOLDER_REQUEST: "GET_BY_ID_RIGHT_HOLDER_REQUEST",
  GET_BY_ID_RIGHT_HOLDER_SUCCESS: "GET_BY_ID_RIGHT_HOLDER_SUCCESS",
  GET_BY_ID_RIGHT_HOLDER_FAILURE: "GET_BY_ID_RIGHT_HOLDER_FAILURE",

  /************************************************************************* */
  CREATE_RIGHT_HOLDER_REQUEST: "CREATE_RIGHT_HOLDER_REQUEST",
  CREATE_RIGHT_HOLDER_SUCCESS: "CREATE_RIGHT_HOLDER_SUCCESS",
  CREATE_RIGHT_HOLDER_FAILURE: "CREATE_RIGHT_HOLDER_FAILURE",

  /************************************************************************* */
  UPDATE_RIGHT_HOLDER_REQUEST: "UPDATE_RIGHT_HOLDER_REQUEST",
  UPDATE_RIGHT_HOLDER_SUCCESS: "UPDATE_RIGHT_HOLDER_SUCCESS",
  UPDATE_RIGHT_HOLDER_FAILURE: "UPDATE_RIGHT_HOLDER_FAILURE",

  /************************************************************************* */
  DELETE_RIGHT_HOLDER_REQUEST: "DELETE_RIGHT_HOLDER_REQUEST",
  DELETE_RIGHT_HOLDER_SUCCESS: "DELETE_RIGHT_HOLDER_SUCCESS",
  DELETE_RIGHT_HOLDER_FAILURE: "DELETE_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_RIGHT_HOLDER_BY_ID_REQUEST:
    "RECOVER_BY_ID_RIGHT_HOLDER_BY_ID_REQUEST",
  RECOVER_RIGHT_HOLDER_BY_ID_SUCCESS: "RECOVER_RIGHT_HOLDER_BY_ID_SUCCESS",
  RECOVER_RIGHT_HOLDER_BY_ID_FAILURE: "RECOVER_RIGHT_HOLDER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_RIGHT_HOLDER_REQUEST: "RECOVER_ALL_RIGHT_HOLDER_REQUEST",
  RECOVER_ALL_RIGHT_HOLDER_SUCCESS: "RECOVER_ALL_RIGHT_HOLDER_SUCCESS",
  RECOVER_ALL_RIGHT_HOLDER_FAILURE: "RECOVER_ALL_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_RIGHT_HOLDER_REQUEST: "COMPLETE_DELETE_RIGHT_HOLDER_REQUEST",
  COMPLETE_DELETE_RIGHT_HOLDER_SUCCESS: "COMPLETE_DELETE_RIGHT_HOLDER_SUCCESS",
  COMPLETE_DELETE_RIGHT_HOLDER_FAILURE: "COMPLETE_DELETE_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_RIGHT_HOLDER_REQUEST: "TRASH_ALL_RIGHT_HOLDER_REQUEST",
  TRASH_ALL_RIGHT_HOLDER_SUCCESS: "TRASH_ALL_RIGHT_HOLDER_SUCCESS",
  TRASH_ALL_RIGHT_HOLDER_FAILURE: "TRASH_ALL_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_RIGHT_HOLDER_REQUEST: "EMPTY_TRASH_RIGHT_HOLDER_REQUEST",
  EMPTY_TRASH_RIGHT_HOLDER_SUCCESS: "EMPTY_TRASH_RIGHT_HOLDER_SUCCESS",
  EMPTY_TRASH_RIGHT_HOLDER_FAILURE: "EMPTY_TRASH_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_RIGHT_HOLDER_REQUEST: "EXPORT_RIGHT_HOLDER_REQUEST",
  EXPORT_RIGHT_HOLDER_SUCCESS: "EXPORT_RIGHT_HOLDER_SUCCESS",
  EXPORT_RIGHT_HOLDER_FAILURE: "EXPORT_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_RIGHT_HOLDER_REQUEST: "GET_ALL_TRASHED_RIGHT_HOLDER_REQUEST",
  GET_ALL_TRASHED_RIGHT_HOLDER_SUCCESS: "GET_ALL_TRASHED_RIGHT_HOLDER_SUCCESS",
  GET_ALL_TRASHED_RIGHT_HOLDER_FAILURE: "GET_ALL_TRASHED_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_RIGHT_HOLDER_REQUEST: "ARCHIVE_RIGHT_HOLDER_REQUEST",
  ARCHIVE_RIGHT_HOLDER_SUCCESS: "ARCHIVE_RIGHT_HOLDER_SUCCESS",
  ARCHIVE_RIGHT_HOLDER_FAILURE: "ARCHIVE_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_RIGHT_HOLDER_REQUEST:
    "GET_ALL_ARCHIVED_RIGHT_HOLDER_REQUEST",
  GET_ALL_ARCHIVED_RIGHT_HOLDER_SUCCESS:
    "GET_ALL_ARCHIVED_RIGHT_HOLDER_SUCCESS",
  GET_ALL_ARCHIVED_RIGHT_HOLDER_FAILURE:
    "GET_ALL_ARCHIVED_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_REQUEST:
    "RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_REQUEST",
  RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_SUCCESS:
    "RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_SUCCESS",
  RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_FAILURE:
    "RECOVER_ALL_ARCHIVED_RIGHT_HOLDER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_RIGHT_HOLDER_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_RIGHT_HOLDER_BY_ID_REQUEST",
  RECOVER_ARCHIVED_RIGHT_HOLDER_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_RIGHT_HOLDER_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_RIGHT_HOLDER_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_RIGHT_HOLDER_BY_ID_FAILURE",
  /************************************************************************* */
};

export default RIGHT_HOLDER_ACTION_TYPES;
