import KIFLE_KETEMA_ACTION_TYPES from "../actionTypes/kifleKetemaActionTypes";

const initialState = {
  isLoading: false,
  kifleKetemaState: [],
  kifleKetemaErrorState: "",
};

const KIFLE_KETEMA_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case KIFLE_KETEMA_ACTION_TYPES.CREATE_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
      };
    // case KIFLE_KETEMA_ACTION_TYPES.CREATE_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.CREATE_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case KIFLE_KETEMA_ACTION_TYPES.UPDATE_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
        kifleKetemaState: state.kifleKetemaState.map((kk) =>
          kk.id === action.payload ? action.payload : kk
        ),
      };
    case KIFLE_KETEMA_ACTION_TYPES.UPDATE_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case KIFLE_KETEMA_ACTION_TYPES.DELETE_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.DELETE_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
      };
    case KIFLE_KETEMA_ACTION_TYPES.DELETE_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_KIFLE_KETEMA_SUCCESS:
      return { ...state, kifleKetemaState: [...action.payload] };
    case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.GET_BY_ID_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.GET_BY_ID_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
        kifleKetemaState: action.payload,
      };
    case KIFLE_KETEMA_ACTION_TYPES.GET_BY_ID_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.COMPLETE_DELETE_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.COMPLETE_DELETE_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
      };
    case KIFLE_KETEMA_ACTION_TYPES.COMPLETE_DELETE_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.EMPTY_TRASH_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.EMPTY_TRASH_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
      };
    case KIFLE_KETEMA_ACTION_TYPES.EMPTY_TRASH_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.EXPORT_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.EXPORT_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
        // kifleKetemaState: action.payload,
      };
    case KIFLE_KETEMA_ACTION_TYPES.EXPORT_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ALL_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ALL_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
      };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ALL_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_KIFLE_KETEMA_BY_ID***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.RECOVER_KIFLE_KETEMA_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_KIFLE_KETEMA_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_KIFLE_KETEMA_BY_ID_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************RECOVER_KIFLE_KETEMA_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.TRASH_ALL_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.TRASH_ALL_KIFLE_KETEMA_SUCCESS:
      return { ...state };
    case KIFLE_KETEMA_ACTION_TYPES.TRASH_ALL_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_TRASHED_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_TRASHED_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
        kifleKetemaState: [...action.payload],
      };
    case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_TRASHED_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.ARCHIVE_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.ARCHIVE_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
      };
    case KIFLE_KETEMA_ACTION_TYPES.ARCHIVE_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_ARCHIVED_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_ARCHIVED_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
        kifleKetemaState: [...action.payload],
      };
    case KIFLE_KETEMA_ACTION_TYPES.GET_ALL_ARCHIVED_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_SUCCESS:
      return {
        ...state,
        // kifleKetemaState: [...action.payload],
      };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_SUCCESS:
      return {
        ...state,
        kifleKetemaState: [...action.payload],
      };
    case KIFLE_KETEMA_ACTION_TYPES.RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_FAILURE:
      return {
        kifleKetemaErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default KIFLE_KETEMA_REDUCER;
