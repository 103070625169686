import PROGRAM_ACTION_TYPES from "../actionTypes/programActionTypes";

const initialState = {
  isLoading: false,
  programState: [],
  programErrorState: "",
};

const PROGRAM_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case PROGRAM_ACTION_TYPES.CREATE_PROGRAM_SUCCESS:
      return {
        ...state,
      };
    // case PROGRAM_ACTION_TYPES.CREATE_PROGRAM_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case PROGRAM_ACTION_TYPES.CREATE_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case PROGRAM_ACTION_TYPES.UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        programState: state.programState.map((prog) =>
          prog.id === action.payload ? action.payload : prog
        ),
      };
    case PROGRAM_ACTION_TYPES.UPDATE_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case PROGRAM_ACTION_TYPES.DELETE_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.DELETE_PROGRAM_SUCCESS:
      return {
        ...state,
      };
    case PROGRAM_ACTION_TYPES.DELETE_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    case PROGRAM_ACTION_TYPES.GET_ALL_PROGRAM_REQUEST:
      return {
        isLoading: true,
      };
    case PROGRAM_ACTION_TYPES.GET_ALL_PROGRAM_SUCCESS:
      return { ...state, programState: [...action.payload] };
    case PROGRAM_ACTION_TYPES.GET_ALL_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case PROGRAM_ACTION_TYPES.GET_BY_ID_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.GET_BY_ID_PROGRAM_SUCCESS:
      return {
        ...state,
        programState: action.payload,
      };
    case PROGRAM_ACTION_TYPES.GET_BY_ID_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case PROGRAM_ACTION_TYPES.COMPLETE_DELETE_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.COMPLETE_DELETE_PROGRAM_SUCCESS:
      return {
        ...state,
      };
    case PROGRAM_ACTION_TYPES.COMPLETE_DELETE_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case PROGRAM_ACTION_TYPES.EMPTY_TRASH_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.EMPTY_TRASH_PROGRAM_SUCCESS:
      return {
        ...state,
      };
    case PROGRAM_ACTION_TYPES.EMPTY_TRASH_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case PROGRAM_ACTION_TYPES.EXPORT_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.EXPORT_PROGRAM_SUCCESS:
      return {
        ...state,
        // programState: action.payload,
      };
    case PROGRAM_ACTION_TYPES.EXPORT_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case PROGRAM_ACTION_TYPES.RECOVER_ALL_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.RECOVER_ALL_PROGRAM_SUCCESS:
      return {
        ...state,
      };
    case PROGRAM_ACTION_TYPES.RECOVER_ALL_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_PROGRAM_BY_ID***********************************************/
    // case PROGRAM_ACTION_TYPES.RECOVER_PROGRAM_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.RECOVER_PROGRAM_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case PROGRAM_ACTION_TYPES.RECOVER_PROGRAM_BY_ID_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************RECOVER_PROGRAM_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case PROGRAM_ACTION_TYPES.TRASH_ALL_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.TRASH_ALL_PROGRAM_SUCCESS:
      return { ...state };
    case PROGRAM_ACTION_TYPES.TRASH_ALL_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case PROGRAM_ACTION_TYPES.GET_ALL_TRASHED_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.GET_ALL_TRASHED_PROGRAM_SUCCESS:
      return {
        ...state,
        programState: [...action.payload],
      };
    case PROGRAM_ACTION_TYPES.GET_ALL_TRASHED_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case PROGRAM_ACTION_TYPES.ARCHIVE_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.ARCHIVE_PROGRAM_SUCCESS:
      return {
        ...state,
      };
    case PROGRAM_ACTION_TYPES.ARCHIVE_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case PROGRAM_ACTION_TYPES.GET_ALL_ARCHIVED_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.GET_ALL_ARCHIVED_PROGRAM_SUCCESS:
      return {
        ...state,
        programState: [...action.payload],
      };
    case PROGRAM_ACTION_TYPES.GET_ALL_ARCHIVED_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case PROGRAM_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PROGRAM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PROGRAM_SUCCESS:
      return {
        ...state,
        // programState: [...action.payload],
      };
    case PROGRAM_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PROGRAM_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case PROGRAM_ACTION_TYPES.RECOVER_ARCHIVED_PROGRAM_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PROGRAM_ACTION_TYPES.RECOVER_ARCHIVED_PROGRAM_BY_ID_SUCCESS:
      return {
        ...state,
        programState: [...action.payload],
      };
    case PROGRAM_ACTION_TYPES.RECOVER_ARCHIVED_PROGRAM_BY_ID_FAILURE:
      return {
        programErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default PROGRAM_REDUCER;
