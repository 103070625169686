const ZONE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ZONE_REQUEST: "GET_ALL_ZONE_REQUEST",
  GET_ALL_ZONE_SUCCESS: "GET_ALL_ZONE_SUCCESS",
  GET_ALL_ZONE_FAILURE: "GET_ALL_ZONE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ZONE_REQUEST: "GET_BY_ID_ZONE_REQUEST",
  GET_BY_ID_ZONE_SUCCESS: "GET_BY_ID_ZONE_SUCCESS",
  GET_BY_ID_ZONE_FAILURE: "GET_BY_ID_ZONE_FAILURE",

  /************************************************************************* */
  CREATE_ZONE_REQUEST: "CREATE_ZONE_REQUEST",
  CREATE_ZONE_SUCCESS: "CREATE_ZONE_SUCCESS",
  CREATE_ZONE_FAILURE: "CREATE_ZONE_FAILURE",

  /************************************************************************* */
  UPDATE_ZONE_REQUEST: "UPDATE_ZONE_REQUEST",
  UPDATE_ZONE_SUCCESS: "UPDATE_ZONE_SUCCESS",
  UPDATE_ZONE_FAILURE: "UPDATE_ZONE_FAILURE",

  /************************************************************************* */
  DELETE_ZONE_REQUEST: "DELETE_ZONE_REQUEST",
  DELETE_ZONE_SUCCESS: "DELETE_ZONE_SUCCESS",
  DELETE_ZONE_FAILURE: "DELETE_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ZONE_BY_ID_REQUEST: "RECOVER_BY_ID_ZONE_BY_ID_REQUEST",
  RECOVER_ZONE_BY_ID_SUCCESS: "RECOVER_ZONE_BY_ID_SUCCESS",
  RECOVER_ZONE_BY_ID_FAILURE: "RECOVER_ZONE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ZONE_REQUEST: "RECOVER_ALL_ZONE_REQUEST",
  RECOVER_ALL_ZONE_SUCCESS: "RECOVER_ALL_ZONE_SUCCESS",
  RECOVER_ALL_ZONE_FAILURE: "RECOVER_ALL_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ZONE_REQUEST: "COMPLETE_DELETE_ZONE_REQUEST",
  COMPLETE_DELETE_ZONE_SUCCESS: "COMPLETE_DELETE_ZONE_SUCCESS",
  COMPLETE_DELETE_ZONE_FAILURE: "COMPLETE_DELETE_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ZONE_REQUEST: "TRASH_ALL_ZONE_REQUEST",
  TRASH_ALL_ZONE_SUCCESS: "TRASH_ALL_ZONE_SUCCESS",
  TRASH_ALL_ZONE_FAILURE: "TRASH_ALL_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ZONE_REQUEST: "EMPTY_TRASH_ZONE_REQUEST",
  EMPTY_TRASH_ZONE_SUCCESS: "EMPTY_TRASH_ZONE_SUCCESS",
  EMPTY_TRASH_ZONE_FAILURE: "EMPTY_TRASH_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ZONE_REQUEST: "EXPORT_ZONE_REQUEST",
  EXPORT_ZONE_SUCCESS: "EXPORT_ZONE_SUCCESS",
  EXPORT_ZONE_FAILURE: "EXPORT_ZONE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ZONE_REQUEST: "GET_ALL_TRASHED_ZONE_REQUEST",
  GET_ALL_TRASHED_ZONE_SUCCESS: "GET_ALL_TRASHED_ZONE_SUCCESS",
  GET_ALL_TRASHED_ZONE_FAILURE: "GET_ALL_TRASHED_ZONE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ZONE_REQUEST: "ARCHIVE_ZONE_REQUEST",
  ARCHIVE_ZONE_SUCCESS: "ARCHIVE_ZONE_SUCCESS",
  ARCHIVE_ZONE_FAILURE: "ARCHIVE_ZONE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ZONE_REQUEST: "GET_ALL_ARCHIVED_ZONE_REQUEST",
  GET_ALL_ARCHIVED_ZONE_SUCCESS: "GET_ALL_ARCHIVED_ZONE_SUCCESS",
  GET_ALL_ARCHIVED_ZONE_FAILURE: "GET_ALL_ARCHIVED_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ZONE_REQUEST: "RECOVER_ALL_ARCHIVED_ZONE_REQUEST",
  RECOVER_ALL_ARCHIVED_ZONE_SUCCESS: "RECOVER_ALL_ARCHIVED_ZONE_SUCCESS",
  RECOVER_ALL_ARCHIVED_ZONE_FAILURE: "RECOVER_ALL_ARCHIVED_ZONE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ZONE_BY_ID_REQUEST: "RECOVER_ARCHIVED_ZONE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ZONE_BY_ID_SUCCESS: "RECOVER_ARCHIVED_ZONE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ZONE_BY_ID_FAILURE: "RECOVER_ARCHIVED_ZONE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ZONE_ACTION_TYPES;
