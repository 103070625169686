const DONNER_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_DONNER_REQUEST: "GET_ALL_DONNER_REQUEST",
  GET_ALL_DONNER_SUCCESS: "GET_ALL_DONNER_SUCCESS",
  GET_ALL_DONNER_FAILURE: "GET_ALL_DONNER_FAILURE",

  /************************************************************************* */
  GET_BY_ID_DONNER_REQUEST: "GET_BY_ID_DONNER_REQUEST",
  GET_BY_ID_DONNER_SUCCESS: "GET_BY_ID_DONNER_SUCCESS",
  GET_BY_ID_DONNER_FAILURE: "GET_BY_ID_DONNER_FAILURE",

  /************************************************************************* */
  CREATE_DONNER_REQUEST: "CREATE_DONNER_REQUEST",
  CREATE_DONNER_SUCCESS: "CREATE_DONNER_SUCCESS",
  CREATE_DONNER_FAILURE: "CREATE_DONNER_FAILURE",

  /************************************************************************* */
  UPDATE_DONNER_REQUEST: "UPDATE_DONNER_REQUEST",
  UPDATE_DONNER_SUCCESS: "UPDATE_DONNER_SUCCESS",
  UPDATE_DONNER_FAILURE: "UPDATE_DONNER_FAILURE",

  /************************************************************************* */
  DELETE_DONNER_REQUEST: "DELETE_DONNER_REQUEST",
  DELETE_DONNER_SUCCESS: "DELETE_DONNER_SUCCESS",
  DELETE_DONNER_FAILURE: "DELETE_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_DONNER_BY_ID_REQUEST: "RECOVER_BY_ID_DONNER_BY_ID_REQUEST",
  RECOVER_DONNER_BY_ID_SUCCESS: "RECOVER_DONNER_BY_ID_SUCCESS",
  RECOVER_DONNER_BY_ID_FAILURE: "RECOVER_DONNER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_DONNER_REQUEST: "RECOVER_ALL_DONNER_REQUEST",
  RECOVER_ALL_DONNER_SUCCESS: "RECOVER_ALL_DONNER_SUCCESS",
  RECOVER_ALL_DONNER_FAILURE: "RECOVER_ALL_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_DONNER_REQUEST: "COMPLETE_DELETE_DONNER_REQUEST",
  COMPLETE_DELETE_DONNER_SUCCESS: "COMPLETE_DELETE_DONNER_SUCCESS",
  COMPLETE_DELETE_DONNER_FAILURE: "COMPLETE_DELETE_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_DONNER_REQUEST: "TRASH_ALL_DONNER_REQUEST",
  TRASH_ALL_DONNER_SUCCESS: "TRASH_ALL_DONNER_SUCCESS",
  TRASH_ALL_DONNER_FAILURE: "TRASH_ALL_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_DONNER_REQUEST: "EMPTY_TRASH_DONNER_REQUEST",
  EMPTY_TRASH_DONNER_SUCCESS: "EMPTY_TRASH_DONNER_SUCCESS",
  EMPTY_TRASH_DONNER_FAILURE: "EMPTY_TRASH_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_DONNER_REQUEST: "EXPORT_DONNER_REQUEST",
  EXPORT_DONNER_SUCCESS: "EXPORT_DONNER_SUCCESS",
  EXPORT_DONNER_FAILURE: "EXPORT_DONNER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_DONNER_REQUEST: "GET_ALL_TRASHED_DONNER_REQUEST",
  GET_ALL_TRASHED_DONNER_SUCCESS: "GET_ALL_TRASHED_DONNER_SUCCESS",
  GET_ALL_TRASHED_DONNER_FAILURE: "GET_ALL_TRASHED_DONNER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_DONNER_REQUEST: "ARCHIVE_DONNER_REQUEST",
  ARCHIVE_DONNER_SUCCESS: "ARCHIVE_DONNER_SUCCESS",
  ARCHIVE_DONNER_FAILURE: "ARCHIVE_DONNER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_DONNER_REQUEST: "GET_ALL_ARCHIVED_DONNER_REQUEST",
  GET_ALL_ARCHIVED_DONNER_SUCCESS: "GET_ALL_ARCHIVED_DONNER_SUCCESS",
  GET_ALL_ARCHIVED_DONNER_FAILURE: "GET_ALL_ARCHIVED_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_DONNER_REQUEST: "RECOVER_ALL_ARCHIVED_DONNER_REQUEST",
  RECOVER_ALL_ARCHIVED_DONNER_SUCCESS: "RECOVER_ALL_ARCHIVED_DONNER_SUCCESS",
  RECOVER_ALL_ARCHIVED_DONNER_FAILURE: "RECOVER_ALL_ARCHIVED_DONNER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_DONNER_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_DONNER_BY_ID_REQUEST",
  RECOVER_ARCHIVED_DONNER_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_DONNER_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_DONNER_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_DONNER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  UPLOAD_DONNER_LOGO_REQUEST: "UPLOAD_DONNER_LOGO_REQUEST",
  UPLOAD_DONNER_LOGO_SUCCESS: "UPLOAD_DONNER_LOGO_SUCCESS",
  UPLOAD_DONNER_LOGO_FAILURE: "UPLOAD_DONNER_LOGO_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  UPDATE_DONNER_LOGO_REQUEST: "UPDATE_DONNER_LOGO_REQUEST",
  UPDATE_DONNER_LOGO_SUCCESS: "UPDATE_DONNER_LOGO_SUCCESS",
  UPDATE_DONNER_LOGO_FAILURE: "UPDATE_DONNER_LOGO_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  DOWNLOAD_DONNER_LOGO_REQUEST: "DOWNLOAD_DONNER_LOGO_REQUEST",
  DOWNLOAD_DONNER_LOGO_SUCCESS: "DOWNLOAD_DONNER_LOGO_SUCCESS",
  DOWNLOAD_DONNER_LOGO_FAILURE: "DOWNLOAD_DONNER_LOGO_FAILURE",
  /************************************************************************* */
};

export default DONNER_ACTION_TYPES;
