const PROJECT_TASK_TYPE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_PROJECT_TASK_TYPE_REQUEST: "GET_ALL_PROJECT_TASK_TYPE_REQUEST",
  GET_ALL_PROJECT_TASK_TYPE_SUCCESS: "GET_ALL_PROJECT_TASK_TYPE_SUCCESS",
  GET_ALL_PROJECT_TASK_TYPE_FAILURE: "GET_ALL_PROJECT_TASK_TYPE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_PROJECT_TASK_TYPE_REQUEST: "GET_BY_ID_PROJECT_TASK_TYPE_REQUEST",
  GET_BY_ID_PROJECT_TASK_TYPE_SUCCESS: "GET_BY_ID_PROJECT_TASK_TYPE_SUCCESS",
  GET_BY_ID_PROJECT_TASK_TYPE_FAILURE: "GET_BY_ID_PROJECT_TASK_TYPE_FAILURE",

  /************************************************************************* */
  CREATE_PROJECT_TASK_TYPE_REQUEST: "CREATE_PROJECT_TASK_TYPE_REQUEST",
  CREATE_PROJECT_TASK_TYPE_SUCCESS: "CREATE_PROJECT_TASK_TYPE_SUCCESS",
  CREATE_PROJECT_TASK_TYPE_FAILURE: "CREATE_PROJECT_TASK_TYPE_FAILURE",

  /************************************************************************* */
  UPDATE_PROJECT_TASK_TYPE_REQUEST: "UPDATE_PROJECT_TASK_TYPE_REQUEST",
  UPDATE_PROJECT_TASK_TYPE_SUCCESS: "UPDATE_PROJECT_TASK_TYPE_SUCCESS",
  UPDATE_PROJECT_TASK_TYPE_FAILURE: "UPDATE_PROJECT_TASK_TYPE_FAILURE",

  /************************************************************************* */
  DELETE_PROJECT_TASK_TYPE_REQUEST: "DELETE_PROJECT_TASK_TYPE_REQUEST",
  DELETE_PROJECT_TASK_TYPE_SUCCESS: "DELETE_PROJECT_TASK_TYPE_SUCCESS",
  DELETE_PROJECT_TASK_TYPE_FAILURE: "DELETE_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_PROJECT_TASK_TYPE_BY_ID_REQUEST:
    "RECOVER_BY_ID_PROJECT_TASK_TYPE_BY_ID_REQUEST",
  RECOVER_PROJECT_TASK_TYPE_BY_ID_SUCCESS:
    "RECOVER_PROJECT_TASK_TYPE_BY_ID_SUCCESS",
  RECOVER_PROJECT_TASK_TYPE_BY_ID_FAILURE:
    "RECOVER_PROJECT_TASK_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_PROJECT_TASK_TYPE_REQUEST:
    "RECOVER_ALL_PROJECT_TASK_TYPE_REQUEST",
  RECOVER_ALL_PROJECT_TASK_TYPE_SUCCESS:
    "RECOVER_ALL_PROJECT_TASK_TYPE_SUCCESS",
  RECOVER_ALL_PROJECT_TASK_TYPE_FAILURE:
    "RECOVER_ALL_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_PROJECT_TASK_TYPE_REQUEST:
    "COMPLETE_DELETE_PROJECT_TASK_TYPE_REQUEST",
  COMPLETE_DELETE_PROJECT_TASK_TYPE_SUCCESS:
    "COMPLETE_DELETE_PROJECT_TASK_TYPE_SUCCESS",
  COMPLETE_DELETE_PROJECT_TASK_TYPE_FAILURE:
    "COMPLETE_DELETE_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_PROJECT_TASK_TYPE_REQUEST: "TRASH_ALL_PROJECT_TASK_TYPE_REQUEST",
  TRASH_ALL_PROJECT_TASK_TYPE_SUCCESS: "TRASH_ALL_PROJECT_TASK_TYPE_SUCCESS",
  TRASH_ALL_PROJECT_TASK_TYPE_FAILURE: "TRASH_ALL_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_PROJECT_TASK_TYPE_REQUEST:
    "EMPTY_TRASH_PROJECT_TASK_TYPE_REQUEST",
  EMPTY_TRASH_PROJECT_TASK_TYPE_SUCCESS:
    "EMPTY_TRASH_PROJECT_TASK_TYPE_SUCCESS",
  EMPTY_TRASH_PROJECT_TASK_TYPE_FAILURE:
    "EMPTY_TRASH_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_PROJECT_TASK_TYPE_REQUEST: "EXPORT_PROJECT_TASK_TYPE_REQUEST",
  EXPORT_PROJECT_TASK_TYPE_SUCCESS: "EXPORT_PROJECT_TASK_TYPE_SUCCESS",
  EXPORT_PROJECT_TASK_TYPE_FAILURE: "EXPORT_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_PROJECT_TASK_TYPE_REQUEST:
    "GET_ALL_TRASHED_PROJECT_TASK_TYPE_REQUEST",
  GET_ALL_TRASHED_PROJECT_TASK_TYPE_SUCCESS:
    "GET_ALL_TRASHED_PROJECT_TASK_TYPE_SUCCESS",
  GET_ALL_TRASHED_PROJECT_TASK_TYPE_FAILURE:
    "GET_ALL_TRASHED_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_PROJECT_TASK_TYPE_REQUEST: "ARCHIVE_PROJECT_TASK_TYPE_REQUEST",
  ARCHIVE_PROJECT_TASK_TYPE_SUCCESS: "ARCHIVE_PROJECT_TASK_TYPE_SUCCESS",
  ARCHIVE_PROJECT_TASK_TYPE_FAILURE: "ARCHIVE_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_PROJECT_TASK_TYPE_REQUEST:
    "GET_ALL_ARCHIVED_PROJECT_TASK_TYPE_REQUEST",
  GET_ALL_ARCHIVED_PROJECT_TASK_TYPE_SUCCESS:
    "GET_ALL_ARCHIVED_PROJECT_TASK_TYPE_SUCCESS",
  GET_ALL_ARCHIVED_PROJECT_TASK_TYPE_FAILURE:
    "GET_ALL_ARCHIVED_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_PROJECT_TASK_TYPE_REQUEST:
    "RECOVER_ALL_ARCHIVED_PROJECT_TASK_TYPE_REQUEST",
  RECOVER_ALL_ARCHIVED_PROJECT_TASK_TYPE_SUCCESS:
    "RECOVER_ALL_ARCHIVED_PROJECT_TASK_TYPE_SUCCESS",
  RECOVER_ALL_ARCHIVED_PROJECT_TASK_TYPE_FAILURE:
    "RECOVER_ALL_ARCHIVED_PROJECT_TASK_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_PROJECT_TASK_TYPE_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_PROJECT_TASK_TYPE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_PROJECT_TASK_TYPE_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_PROJECT_TASK_TYPE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_PROJECT_TASK_TYPE_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_PROJECT_TASK_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default PROJECT_TASK_TYPE_ACTION_TYPES;
