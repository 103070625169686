import NOTIFICATION_ACTION_TYPES from "../actionTypes/notificationActionTypes";

const initialState = {
  isLoading: false,
  notificationState: [],
  notificationErrorState: "",
};

const NOTIFICATION_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    // case NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case NOTIFICATION_ACTION_TYPES.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationState: state.notificationState.map((nfc) =>
          nfc.id === action.payload ? action.payload : nfc
        ),
      };
    case NOTIFICATION_ACTION_TYPES.UPDATE_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case NOTIFICATION_ACTION_TYPES.GET_ALL_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.GET_ALL_NOTIFICATION_SUCCESS:
      return { ...state, notificationState: [...action.payload] };
    case NOTIFICATION_ACTION_TYPES.GET_ALL_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case NOTIFICATION_ACTION_TYPES.GET_BY_ID_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.GET_BY_ID_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationState: action.payload,
      };
    case NOTIFICATION_ACTION_TYPES.GET_BY_ID_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case NOTIFICATION_ACTION_TYPES.COMPLETE_DELETE_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.COMPLETE_DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case NOTIFICATION_ACTION_TYPES.COMPLETE_DELETE_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case NOTIFICATION_ACTION_TYPES.EMPTY_TRASH_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.EMPTY_TRASH_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case NOTIFICATION_ACTION_TYPES.EMPTY_TRASH_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case NOTIFICATION_ACTION_TYPES.EXPORT_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.EXPORT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        // notificationState: action.payload,
      };
    case NOTIFICATION_ACTION_TYPES.EXPORT_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case NOTIFICATION_ACTION_TYPES.RECOVER_ALL_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.RECOVER_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case NOTIFICATION_ACTION_TYPES.RECOVER_ALL_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_NOTIFICATION_BY_ID***********************************************/
    // case NOTIFICATION_ACTION_TYPES.RECOVER_NOTIFICATION_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.RECOVER_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case NOTIFICATION_ACTION_TYPES.RECOVER_NOTIFICATION_BY_ID_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************RECOVER_NOTIFICATION_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case NOTIFICATION_ACTION_TYPES.TRASH_ALL_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.TRASH_ALL_NOTIFICATION_SUCCESS:
      return { ...state };
    case NOTIFICATION_ACTION_TYPES.TRASH_ALL_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case NOTIFICATION_ACTION_TYPES.GET_ALL_TRASHED_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.GET_ALL_TRASHED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationState: [...action.payload],
      };
    case NOTIFICATION_ACTION_TYPES.GET_ALL_TRASHED_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case NOTIFICATION_ACTION_TYPES.ARCHIVE_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.ARCHIVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case NOTIFICATION_ACTION_TYPES.ARCHIVE_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case NOTIFICATION_ACTION_TYPES.GET_ALL_ARCHIVED_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.GET_ALL_ARCHIVED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationState: [...action.payload],
      };
    case NOTIFICATION_ACTION_TYPES.GET_ALL_ARCHIVED_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case NOTIFICATION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_NOTIFICATION_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        // notificationState: [...action.payload],
      };
    case NOTIFICATION_ACTION_TYPES.RECOVER_ALL_ARCHIVED_NOTIFICATION_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case NOTIFICATION_ACTION_TYPES.RECOVER_ARCHIVED_NOTIFICATION_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case NOTIFICATION_ACTION_TYPES.RECOVER_ARCHIVED_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
        notificationState: [...action.payload],
      };
    case NOTIFICATION_ACTION_TYPES.RECOVER_ARCHIVED_NOTIFICATION_BY_ID_FAILURE:
      return {
        notificationErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default NOTIFICATION_REDUCER;
