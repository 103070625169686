const NOTIFICATION_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_NOTIFICATION_REQUEST: "GET_ALL_NOTIFICATION_REQUEST",
  GET_ALL_NOTIFICATION_SUCCESS: "GET_ALL_NOTIFICATION_SUCCESS",
  GET_ALL_NOTIFICATION_FAILURE: "GET_ALL_NOTIFICATION_FAILURE",

  /************************************************************************* */
  GET_BY_ID_NOTIFICATION_REQUEST: "GET_BY_ID_NOTIFICATION_REQUEST",
  GET_BY_ID_NOTIFICATION_SUCCESS: "GET_BY_ID_NOTIFICATION_SUCCESS",
  GET_BY_ID_NOTIFICATION_FAILURE: "GET_BY_ID_NOTIFICATION_FAILURE",

  /************************************************************************* */
  CREATE_NOTIFICATION_REQUEST: "CREATE_NOTIFICATION_REQUEST",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",

  /************************************************************************* */
  UPDATE_NOTIFICATION_REQUEST: "UPDATE_NOTIFICATION_REQUEST",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILURE: "UPDATE_NOTIFICATION_FAILURE",

  /************************************************************************* */
  DELETE_NOTIFICATION_REQUEST: "DELETE_NOTIFICATION_REQUEST",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_NOTIFICATION_BY_ID_REQUEST:
    "RECOVER_BY_ID_NOTIFICATION_BY_ID_REQUEST",
  RECOVER_NOTIFICATION_BY_ID_SUCCESS: "RECOVER_NOTIFICATION_BY_ID_SUCCESS",
  RECOVER_NOTIFICATION_BY_ID_FAILURE: "RECOVER_NOTIFICATION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_NOTIFICATION_REQUEST: "RECOVER_ALL_NOTIFICATION_REQUEST",
  RECOVER_ALL_NOTIFICATION_SUCCESS: "RECOVER_ALL_NOTIFICATION_SUCCESS",
  RECOVER_ALL_NOTIFICATION_FAILURE: "RECOVER_ALL_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_NOTIFICATION_REQUEST: "COMPLETE_DELETE_NOTIFICATION_REQUEST",
  COMPLETE_DELETE_NOTIFICATION_SUCCESS: "COMPLETE_DELETE_NOTIFICATION_SUCCESS",
  COMPLETE_DELETE_NOTIFICATION_FAILURE: "COMPLETE_DELETE_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_NOTIFICATION_REQUEST: "TRASH_ALL_NOTIFICATION_REQUEST",
  TRASH_ALL_NOTIFICATION_SUCCESS: "TRASH_ALL_NOTIFICATION_SUCCESS",
  TRASH_ALL_NOTIFICATION_FAILURE: "TRASH_ALL_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_NOTIFICATION_REQUEST: "EMPTY_TRASH_NOTIFICATION_REQUEST",
  EMPTY_TRASH_NOTIFICATION_SUCCESS: "EMPTY_TRASH_NOTIFICATION_SUCCESS",
  EMPTY_TRASH_NOTIFICATION_FAILURE: "EMPTY_TRASH_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_NOTIFICATION_REQUEST: "EXPORT_NOTIFICATION_REQUEST",
  EXPORT_NOTIFICATION_SUCCESS: "EXPORT_NOTIFICATION_SUCCESS",
  EXPORT_NOTIFICATION_FAILURE: "EXPORT_NOTIFICATION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_NOTIFICATION_REQUEST: "GET_ALL_TRASHED_NOTIFICATION_REQUEST",
  GET_ALL_TRASHED_NOTIFICATION_SUCCESS: "GET_ALL_TRASHED_NOTIFICATION_SUCCESS",
  GET_ALL_TRASHED_NOTIFICATION_FAILURE: "GET_ALL_TRASHED_NOTIFICATION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_NOTIFICATION_REQUEST: "ARCHIVE_NOTIFICATION_REQUEST",
  ARCHIVE_NOTIFICATION_SUCCESS: "ARCHIVE_NOTIFICATION_SUCCESS",
  ARCHIVE_NOTIFICATION_FAILURE: "ARCHIVE_NOTIFICATION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_NOTIFICATION_REQUEST:
    "GET_ALL_ARCHIVED_NOTIFICATION_REQUEST",
  GET_ALL_ARCHIVED_NOTIFICATION_SUCCESS:
    "GET_ALL_ARCHIVED_NOTIFICATION_SUCCESS",
  GET_ALL_ARCHIVED_NOTIFICATION_FAILURE:
    "GET_ALL_ARCHIVED_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_NOTIFICATION_REQUEST:
    "RECOVER_ALL_ARCHIVED_NOTIFICATION_REQUEST",
  RECOVER_ALL_ARCHIVED_NOTIFICATION_SUCCESS:
    "RECOVER_ALL_ARCHIVED_NOTIFICATION_SUCCESS",
  RECOVER_ALL_ARCHIVED_NOTIFICATION_FAILURE:
    "RECOVER_ALL_ARCHIVED_NOTIFICATION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_NOTIFICATION_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_NOTIFICATION_BY_ID_REQUEST",
  RECOVER_ARCHIVED_NOTIFICATION_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_NOTIFICATION_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_NOTIFICATION_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_NOTIFICATION_BY_ID_FAILURE",
  /************************************************************************* */
};

export default NOTIFICATION_ACTION_TYPES;
