const KIFLE_KETEMA_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_KIFLE_KETEMA_REQUEST: "GET_ALL_KIFLE_KETEMA_REQUEST",
  GET_ALL_KIFLE_KETEMA_SUCCESS: "GET_ALL_KIFLE_KETEMA_SUCCESS",
  GET_ALL_KIFLE_KETEMA_FAILURE: "GET_ALL_KIFLE_KETEMA_FAILURE",

  /************************************************************************* */
  GET_BY_ID_KIFLE_KETEMA_REQUEST: "GET_BY_ID_KIFLE_KETEMA_REQUEST",
  GET_BY_ID_KIFLE_KETEMA_SUCCESS: "GET_BY_ID_KIFLE_KETEMA_SUCCESS",
  GET_BY_ID_KIFLE_KETEMA_FAILURE: "GET_BY_ID_KIFLE_KETEMA_FAILURE",

  /************************************************************************* */
  CREATE_KIFLE_KETEMA_REQUEST: "CREATE_KIFLE_KETEMA_REQUEST",
  CREATE_KIFLE_KETEMA_SUCCESS: "CREATE_KIFLE_KETEMA_SUCCESS",
  CREATE_KIFLE_KETEMA_FAILURE: "CREATE_KIFLE_KETEMA_FAILURE",

  /************************************************************************* */
  UPDATE_KIFLE_KETEMA_REQUEST: "UPDATE_KIFLE_KETEMA_REQUEST",
  UPDATE_KIFLE_KETEMA_SUCCESS: "UPDATE_KIFLE_KETEMA_SUCCESS",
  UPDATE_KIFLE_KETEMA_FAILURE: "UPDATE_KIFLE_KETEMA_FAILURE",

  /************************************************************************* */
  DELETE_KIFLE_KETEMA_REQUEST: "DELETE_KIFLE_KETEMA_REQUEST",
  DELETE_KIFLE_KETEMA_SUCCESS: "DELETE_KIFLE_KETEMA_SUCCESS",
  DELETE_KIFLE_KETEMA_FAILURE: "DELETE_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_KIFLE_KETEMA_BY_ID_REQUEST:
    "RECOVER_BY_ID_KIFLE_KETEMA_BY_ID_REQUEST",
  RECOVER_KIFLE_KETEMA_BY_ID_SUCCESS: "RECOVER_KIFLE_KETEMA_BY_ID_SUCCESS",
  RECOVER_KIFLE_KETEMA_BY_ID_FAILURE: "RECOVER_KIFLE_KETEMA_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_KIFLE_KETEMA_REQUEST: "RECOVER_ALL_KIFLE_KETEMA_REQUEST",
  RECOVER_ALL_KIFLE_KETEMA_SUCCESS: "RECOVER_ALL_KIFLE_KETEMA_SUCCESS",
  RECOVER_ALL_KIFLE_KETEMA_FAILURE: "RECOVER_ALL_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_KIFLE_KETEMA_REQUEST: "COMPLETE_DELETE_KIFLE_KETEMA_REQUEST",
  COMPLETE_DELETE_KIFLE_KETEMA_SUCCESS: "COMPLETE_DELETE_KIFLE_KETEMA_SUCCESS",
  COMPLETE_DELETE_KIFLE_KETEMA_FAILURE: "COMPLETE_DELETE_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_KIFLE_KETEMA_REQUEST: "TRASH_ALL_KIFLE_KETEMA_REQUEST",
  TRASH_ALL_KIFLE_KETEMA_SUCCESS: "TRASH_ALL_KIFLE_KETEMA_SUCCESS",
  TRASH_ALL_KIFLE_KETEMA_FAILURE: "TRASH_ALL_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_KIFLE_KETEMA_REQUEST: "EMPTY_TRASH_KIFLE_KETEMA_REQUEST",
  EMPTY_TRASH_KIFLE_KETEMA_SUCCESS: "EMPTY_TRASH_KIFLE_KETEMA_SUCCESS",
  EMPTY_TRASH_KIFLE_KETEMA_FAILURE: "EMPTY_TRASH_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_KIFLE_KETEMA_REQUEST: "EXPORT_KIFLE_KETEMA_REQUEST",
  EXPORT_KIFLE_KETEMA_SUCCESS: "EXPORT_KIFLE_KETEMA_SUCCESS",
  EXPORT_KIFLE_KETEMA_FAILURE: "EXPORT_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_KIFLE_KETEMA_REQUEST: "GET_ALL_TRASHED_KIFLE_KETEMA_REQUEST",
  GET_ALL_TRASHED_KIFLE_KETEMA_SUCCESS: "GET_ALL_TRASHED_KIFLE_KETEMA_SUCCESS",
  GET_ALL_TRASHED_KIFLE_KETEMA_FAILURE: "GET_ALL_TRASHED_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_KIFLE_KETEMA_REQUEST: "ARCHIVE_KIFLE_KETEMA_REQUEST",
  ARCHIVE_KIFLE_KETEMA_SUCCESS: "ARCHIVE_KIFLE_KETEMA_SUCCESS",
  ARCHIVE_KIFLE_KETEMA_FAILURE: "ARCHIVE_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_KIFLE_KETEMA_REQUEST:
    "GET_ALL_ARCHIVED_KIFLE_KETEMA_REQUEST",
  GET_ALL_ARCHIVED_KIFLE_KETEMA_SUCCESS:
    "GET_ALL_ARCHIVED_KIFLE_KETEMA_SUCCESS",
  GET_ALL_ARCHIVED_KIFLE_KETEMA_FAILURE:
    "GET_ALL_ARCHIVED_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_REQUEST:
    "RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_REQUEST",
  RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_SUCCESS:
    "RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_SUCCESS",
  RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_FAILURE:
    "RECOVER_ALL_ARCHIVED_KIFLE_KETEMA_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_REQUEST",
  RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_KIFLE_KETEMA_BY_ID_FAILURE",
  /************************************************************************* */
};

export default KIFLE_KETEMA_ACTION_TYPES;
