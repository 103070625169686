const ACTION_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ACTION_REQUEST: "GET_ALL_ACTION_REQUEST",
  GET_ALL_ACTION_SUCCESS: "GET_ALL_ACTION_SUCCESS",
  GET_ALL_ACTION_FAILURE: "GET_ALL_ACTION_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ACTION_NAMES_REQUEST: "GET_ACTION_NAMES_REQUEST",
  GET_ACTION_NAMES_SUCCESS: "GET_ACTION_NAMES_SUCCESS",
  GET_ACTION_NAMES_FAILURE: "GET_ACTION_NAMES_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ACTION_REQUEST: "GET_BY_ID_ACTION_REQUEST",
  GET_BY_ID_ACTION_SUCCESS: "GET_BY_ID_ACTION_SUCCESS",
  GET_BY_ID_ACTION_FAILURE: "GET_BY_ID_ACTION_FAILURE",

  /************************************************************************* */
  CREATE_ACTION_REQUEST: "CREATE_ACTION_REQUEST",
  CREATE_ACTION_SUCCESS: "CREATE_ACTION_SUCCESS",
  CREATE_ACTION_FAILURE: "CREATE_ACTION_FAILURE",

  /************************************************************************* */
  UPDATE_ACTION_REQUEST: "UPDATE_ACTION_REQUEST",
  UPDATE_ACTION_SUCCESS: "UPDATE_ACTION_SUCCESS",
  UPDATE_ACTION_FAILURE: "UPDATE_ACTION_FAILURE",

  /************************************************************************* */
  DELETE_ACTION_REQUEST: "DELETE_ACTION_REQUEST",
  DELETE_ACTION_SUCCESS: "DELETE_ACTION_SUCCESS",
  DELETE_ACTION_FAILURE: "DELETE_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ACTION_BY_ID_REQUEST: "RECOVER_BY_ID_ACTION_BY_ID_REQUEST",
  RECOVER_ACTION_BY_ID_SUCCESS: "RECOVER_ACTION_BY_ID_SUCCESS",
  RECOVER_ACTION_BY_ID_FAILURE: "RECOVER_ACTION_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ACTION_REQUEST: "RECOVER_ALL_ACTION_REQUEST",
  RECOVER_ALL_ACTION_SUCCESS: "RECOVER_ALL_ACTION_SUCCESS",
  RECOVER_ALL_ACTION_FAILURE: "RECOVER_ALL_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ACTION_REQUEST: "COMPLETE_DELETE_ACTION_REQUEST",
  COMPLETE_DELETE_ACTION_SUCCESS: "COMPLETE_DELETE_ACTION_SUCCESS",
  COMPLETE_DELETE_ACTION_FAILURE: "COMPLETE_DELETE_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ACTION_REQUEST: "TRASH_ALL_ACTION_REQUEST",
  TRASH_ALL_ACTION_SUCCESS: "TRASH_ALL_ACTION_SUCCESS",
  TRASH_ALL_ACTION_FAILURE: "TRASH_ALL_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ACTION_REQUEST: "EMPTY_TRASH_ACTION_REQUEST",
  EMPTY_TRASH_ACTION_SUCCESS: "EMPTY_TRASH_ACTION_SUCCESS",
  EMPTY_TRASH_ACTION_FAILURE: "EMPTY_TRASH_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ACTION_REQUEST: "EXPORT_ACTION_REQUEST",
  EXPORT_ACTION_SUCCESS: "EXPORT_ACTION_SUCCESS",
  EXPORT_ACTION_FAILURE: "EXPORT_ACTION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ACTION_REQUEST: "GET_ALL_TRASHED_ACTION_REQUEST",
  GET_ALL_TRASHED_ACTION_SUCCESS: "GET_ALL_TRASHED_ACTION_SUCCESS",
  GET_ALL_TRASHED_ACTION_FAILURE: "GET_ALL_TRASHED_ACTION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ACTION_REQUEST: "ARCHIVE_ACTION_REQUEST",
  ARCHIVE_ACTION_SUCCESS: "ARCHIVE_ACTION_SUCCESS",
  ARCHIVE_ACTION_FAILURE: "ARCHIVE_ACTION_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ACTION_REQUEST: "GET_ALL_ARCHIVED_ACTION_REQUEST",
  GET_ALL_ARCHIVED_ACTION_SUCCESS: "GET_ALL_ARCHIVED_ACTION_SUCCESS",
  GET_ALL_ARCHIVED_ACTION_FAILURE: "GET_ALL_ARCHIVED_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ACTION_REQUEST: "RECOVER_ALL_ARCHIVED_ACTION_REQUEST",
  RECOVER_ALL_ARCHIVED_ACTION_SUCCESS: "RECOVER_ALL_ARCHIVED_ACTION_SUCCESS",
  RECOVER_ALL_ARCHIVED_ACTION_FAILURE: "RECOVER_ALL_ARCHIVED_ACTION_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ACTION_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ACTION_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ACTION_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ACTION_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ACTION_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ACTION_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ACTION_ACTION_TYPES;
