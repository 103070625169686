import ACTIVITY_ACTION_TYPES from "../actionTypes/activityActionTypes";

const initialState = {
  isLoading: false,
  activityState: [],
  nonAssignedActivityState: [],
  activityErrorState: "",
  activitiesByProjectState: [],
  activityByUserByIdState:[]
};

const ACTIVITY_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case ACTIVITY_ACTION_TYPES.CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
      };
    // case ACTIVITY_ACTION_TYPES.CREATE_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case ACTIVITY_ACTION_TYPES.CREATE_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case ACTIVITY_ACTION_TYPES.UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityState: state.activityState.map((act) =>
          act.id === action.payload ? action.payload : act
        ),
      };
    case ACTIVITY_ACTION_TYPES.UPDATE_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case ACTIVITY_ACTION_TYPES.DELETE_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
      };
    case ACTIVITY_ACTION_TYPES.DELETE_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_SUCCESS:
      return { ...state, activityState: [...action.payload] };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case ACTIVITY_ACTION_TYPES.GET_ALL_BY_USER_PROJECT_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_ALL_BY_USER_PROJECT_ACTIVITY_SUCCESS:
      return { ...state, activityByUserByIdState: [...action.payload] };
    case ACTIVITY_ACTION_TYPES.GET_ALL_BY_USER_PROJECT_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_ALL_NON_ASSIGNED_ACTIVITY_SUCCESS:
      return { ...state, nonAssignedActivityState: [...action.payload] };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_BY_PROJECT_ID_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL_ACTIVITIES_BY_PROJECT_ID******************************************/

    // case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_BY_PROJECT_ID_SUCCESS:
      return { ...state, activitiesByProjectState: [...action.payload] };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ACTIVITY_BY_PROJECT_ID_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case ACTIVITY_ACTION_TYPES.GET_BY_ID_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_BY_ID_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityState: action.payload,
      };
    case ACTIVITY_ACTION_TYPES.GET_BY_ID_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case ACTIVITY_ACTION_TYPES.COMPLETE_DELETE_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.COMPLETE_DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
      };
    case ACTIVITY_ACTION_TYPES.COMPLETE_DELETE_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case ACTIVITY_ACTION_TYPES.EMPTY_TRASH_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.EMPTY_TRASH_ACTIVITY_SUCCESS:
      return {
        ...state,
      };
    case ACTIVITY_ACTION_TYPES.EMPTY_TRASH_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case ACTIVITY_ACTION_TYPES.EXPORT_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.EXPORT_ACTIVITY_SUCCESS:
      return {
        ...state,
        //activityState: action.payload,
      };
    case ACTIVITY_ACTION_TYPES.EXPORT_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case ACTIVITY_ACTION_TYPES.RECOVER_ALL_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.RECOVER_ALL_ACTIVITY_SUCCESS:
      return {
        ...state,
      };
    case ACTIVITY_ACTION_TYPES.RECOVER_ALL_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_ACTIVITY_BY_ID***********************************************/
    // case ACTIVITY_ACTION_TYPES.RECOVER_ACTIVITY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.RECOVER_ACTIVITY_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case ACTIVITY_ACTION_TYPES.RECOVER_ACTIVITY_BY_ID_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************RECOVER_ACTIVITY_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case ACTIVITY_ACTION_TYPES.TRASH_ALL_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.TRASH_ALL_ACTIVITY_SUCCESS:
      return { ...state };
    case ACTIVITY_ACTION_TYPES.TRASH_ALL_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case ACTIVITY_ACTION_TYPES.GET_ALL_TRASHED_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_ALL_TRASHED_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityState: [...action.payload],
      };
    case ACTIVITY_ACTION_TYPES.GET_ALL_TRASHED_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ACTIVITY_ACTION_TYPES.ARCHIVE_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.ARCHIVE_ACTIVITY_SUCCESS:
      return {
        ...state,
      };
    case ACTIVITY_ACTION_TYPES.ARCHIVE_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ACTIVITY_ACTION_TYPES.GET_ALL_ARCHIVED_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ARCHIVED_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityState: [...action.payload],
      };
    case ACTIVITY_ACTION_TYPES.GET_ALL_ARCHIVED_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ACTIVITY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ACTIVITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ACTIVITY_SUCCESS:
      return {
        ...state,
        //activityState: [...action.payload],
      };
    case ACTIVITY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ACTIVITY_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ACTIVITY_ACTION_TYPES.RECOVER_ARCHIVED_ACTIVITY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ACTIVITY_ACTION_TYPES.RECOVER_ARCHIVED_ACTIVITY_BY_ID_SUCCESS:
      return {
        ...state,
        activityState: [...action.payload],
      };
    case ACTIVITY_ACTION_TYPES.RECOVER_ARCHIVED_ACTIVITY_BY_ID_FAILURE:
      return {
        activityErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default ACTIVITY_REDUCER;
