import TOR_ACTION_TYPES from "../actionTypes/torActionTypes";

const initialState = {
  isLoading: false,
  torState: [],
  torErrorState: "",
  getNewTorState: [],
  getNewTorErrorState: "",
  getNewTorByIdState: [],
  getNewTorByIdForEditState: [],
  sendNewTorForSupervisorState: [],
  getTorApprovalRequestState: [],
  getTorRejectedApprovalRequestState: [],
  getTorRejectedAltogetherApprovalRequestState: [],
  getAllApprovedTorRequestState: [],
  getTorApprovalByProjectByUserIdRequestState: [],
  getTorRejectedApprovalByProjectByUserIdRequestState: [],
  getTorRejectedAltogetherApprovalByProjectByUserIdRequestState: [],
  getAllApprovedTorByProjectByUserIdRequestState: [],
  rejectForModification: [],
  getApprovedTorState: [],

};

const TOR_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case TOR_ACTION_TYPES.CREATE_TOR_SUCCESS:
      return {
        ...state,
      };
    // case TOR_ACTION_TYPES.CREATE_TOR_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case TOR_ACTION_TYPES.CREATE_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case TOR_ACTION_TYPES.UPDATE_TOR_SUCCESS:
      return {
        ...state,
        torState: state.torState.map((dept) =>
          dept.id === action.payload ? action.payload : dept
        ),
      };
    case TOR_ACTION_TYPES.UPDATE_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //********************************UPDATE******************************************** */
    case TOR_ACTION_TYPES.UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        torState: state.torState.map((dept) =>
          dept.id === action.payload ? action.payload : dept
        ),
      };
    case TOR_ACTION_TYPES.UPDATE_TOR_BASED_ON_MODIFICATION_REQUEST_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case TOR_ACTION_TYPES.DELETE_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.DELETE_TOR_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.DELETE_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_TOR_SUCCESS:
      return { ...state, torState: [...action.payload] };
    case TOR_ACTION_TYPES.GET_ALL_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_PROJECT_TOR_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_PROJECT_TOR_BY_USER_ID_SUCCESS:
      return { ...state, torState: [...action.payload] };
    case TOR_ACTION_TYPES.GET_ALL_PROJECT_TOR_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return { ...state, getTorApprovalRequestState: [...action.payload] };
    case TOR_ACTION_TYPES.GET_ALL_SUPERVISOR_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return { ...state, getTorApprovalRequestState: [...action.payload] };
    case TOR_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return {
        ...state,
        getTorRejectedApprovalRequestState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return {
        ...state,
        getTorRejectedAltogetherApprovalRequestState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_SUCCESS:
      return {
        ...state,
        getAllApprovedTorRequestState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_APPROVER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_APPROVER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getTorApprovalByProjectByUserIdRequestState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getTorRejectedApprovalByProjectByUserIdRequestState: [
          ...action.payload,
        ],
      };
    case TOR_ACTION_TYPES.GET_ALL_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getTorRejectedAltogetherApprovalByProjectByUserIdRequestState: [
          ...action.payload,
        ],
      };
    case TOR_ACTION_TYPES.GET_ALL_ALTOGETHER_REJECTED_APPROVAL_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        getAllApprovedTorByProjectByUserIdRequestState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_APPROVED_REQUESTS_BY_PROJECT_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_PROJECT_TOR_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_SUCCESS:
      return { ...state, getNewTorState: [...action.payload] };
    case TOR_ACTION_TYPES.GET_ALL_NEW_PROJECT_TOR_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case TOR_ACTION_TYPES.GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_SUCCESS:
      return { ...state, getApprovedTorState: [...action.payload] };
    case TOR_ACTION_TYPES.GET_ALL_APPROVED_PROJECT_TOR_BY_USER_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    
    // *****************************GET_ALL******************************************/

    case TOR_ACTION_TYPES.SEND_NEW_TOR_FOR_SUPERRVISOR_SUCCESS:
      return { ...state, sendNewTorForSupervisorState: [...action.payload] };
    case TOR_ACTION_TYPES.SEND_NEW_TOR_FOR_SUPERRVISOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case TOR_ACTION_TYPES.SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case TOR_ACTION_TYPES.SUPERVISOR_REJECT_TOR_FOR_MODIFICATION_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case TOR_ACTION_TYPES.PROGRAM_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case TOR_ACTION_TYPES.PROGRAM_REJECT_TOR_FOR_MODIFICATION_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case TOR_ACTION_TYPES.FINANCE_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case TOR_ACTION_TYPES.FINANCE_REJECT_TOR_FOR_MODIFICATION_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    case TOR_ACTION_TYPES.CEO_REJECT_TOR_FOR_MODIFICATION_SUCCESS:
      return { ...state, rejectForModification: [...action.payload] };
    case TOR_ACTION_TYPES.CEO_REJECT_TOR_FOR_MODIFICATION_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case TOR_ACTION_TYPES.GET_BY_ID_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_BY_ID_TOR_SUCCESS:
      return {
        ...state,
        getNewTorByIdState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_BY_ID_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* GET_BY_ID***********************************************/
    // case TOR_ACTION_TYPES.GET_BY_ID_TOR_FOR_EDIT_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_BY_ID_TOR_FOR_EDIT_SUCCESS:
      return {
        ...state,
        getNewTorByIdForEditState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_BY_ID_TOR_FOR_EDIT_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case TOR_ACTION_TYPES.COMPLETE_DELETE_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.COMPLETE_DELETE_TOR_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.COMPLETE_DELETE_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case TOR_ACTION_TYPES.EMPTY_TRASH_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.EMPTY_TRASH_TOR_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.EMPTY_TRASH_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case TOR_ACTION_TYPES.EXPORT_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.EXPORT_TOR_SUCCESS:
      return {
        ...state,
        // torState: action.payload,
      };
    case TOR_ACTION_TYPES.EXPORT_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case TOR_ACTION_TYPES.RECOVER_ALL_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.RECOVER_ALL_TOR_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.RECOVER_ALL_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_TOR_BY_ID***********************************************/
    // case TOR_ACTION_TYPES.RECOVER_TOR_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.RECOVER_TOR_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.RECOVER_TOR_BY_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************RECOVER_TOR_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case TOR_ACTION_TYPES.TRASH_ALL_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.TRASH_ALL_TOR_SUCCESS:
      return { ...state };
    case TOR_ACTION_TYPES.TRASH_ALL_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case TOR_ACTION_TYPES.GET_ALL_TRASHED_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_TRASHED_TOR_SUCCESS:
      return {
        ...state,
        torState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_TRASHED_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TOR_ACTION_TYPES.ARCHIVE_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.ARCHIVE_TOR_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.ARCHIVE_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TOR_ACTION_TYPES.ARCHIVE_SELECTED_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.ARCHIVE_SELECTED_TOR_SUCCESS:
      return {
        ...state,
      };
    case TOR_ACTION_TYPES.ARCHIVE_SELECTED_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case TOR_ACTION_TYPES.GET_ALL_ARCHIVED_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.GET_ALL_ARCHIVED_TOR_SUCCESS:
      return {
        ...state,
        torState: [...action.payload],
      };
    case TOR_ACTION_TYPES.GET_ALL_ARCHIVED_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case TOR_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TOR_SUCCESS:
      return {
        ...state,
        // torState: [...action.payload],
      };
    case TOR_ACTION_TYPES.RECOVER_ALL_ARCHIVED_TOR_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case TOR_ACTION_TYPES.RECOVER_ARCHIVED_TOR_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case TOR_ACTION_TYPES.RECOVER_ARCHIVED_TOR_BY_ID_SUCCESS:
      return {
        ...state,
        // torState: [...action.payload],
      };
    case TOR_ACTION_TYPES.RECOVER_ARCHIVED_TOR_BY_ID_FAILURE:
      return {
        torErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default TOR_REDUCER;
