import USER_ACTION_TYPES from "../actionTypes/userActionTypes";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  userState: user ? [{ loggedIn: true, user }] : [],
  activeUserState: [],
  newUserState: [],
  userAState: [],
  userTState: [],
  userRState: [],
  userBState: [],
  userByIdState: [],
  isLoading: false,
  isLoading: false,
  errorUserState: "",
  userProfileState: [],
  userByIdDetailState: [],
  getUserRolesByUserTypeState: [],
  // newUserErrorState: "",
};

const USER_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    /************************************************************************* */
    case USER_ACTION_TYPES.AUTHENTICATE_USER_SUCCUSS:
      return {
        ...state,
        userState: [...action.payload],
      };
    // case USER_ACTION_TYPES.AUTHENTICATE_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };

    case USER_ACTION_TYPES.AUTHENTICATE_USER_ERROR:
      return {
        errorUserState: action.payload,
      };
    /************************************************************************* */
    /************************************************************************* */
    case USER_ACTION_TYPES.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        // newUserState: [...action.payload],
      };

    case USER_ACTION_TYPES.VERIFY_EMAIL_ERROR:
      return {
        errorUserState: action.payload,
      };
    /************************************************************************* */
    case USER_ACTION_TYPES.USER_LOG_OUT:
      return {};
    /************************************************************************* */
    case USER_ACTION_TYPES.GET_ALL_USERS_SUCCESS:
      return {};
    case USER_ACTION_TYPES.GET_ALL_USERS_ERROR:
      return {};
      // case USER_ACTION_TYPES.GET_ALL_USERS_REQUEST:
      //   return {};
      // /************************************************************************* */
      // case USER_ACTION_TYPES.REGISTER_USER_SUCCESS:
      return { ...state };
    case USER_ACTION_TYPES.REGISTER_USER_ERROR:
      return { errorUserState: action.payload };
    // case USER_ACTION_TYPES.REGISTER_USER_REQUEST:
    //   return { isLoading: false };

    // //*******************************CREATE******************************************* */
    case USER_ACTION_TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
      };
    // case USER_ACTION_TYPES.CREATE_USER_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case USER_ACTION_TYPES.CREATE_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    // //*******************************CREATE******************************************* */
    case USER_ACTION_TYPES.CREATE_USER_ROLE_SUCCESS:
      return {
        ...state,
      };
    // case USER_ACTION_TYPES.CREATE_USER_ROLE_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case USER_ACTION_TYPES.CREATE_USER_ROLE_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    // //*******************************CREATE******************************************* */
    case USER_ACTION_TYPES.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
      };
    // case USER_ACTION_TYPES.UPDATE_USER_ROLE_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case USER_ACTION_TYPES.UPDATE_USER_ROLE_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case USER_ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        userState: state.userState.map((user) =>
          user.id === action.payload ? action.payload : user
        ),
      };
    case USER_ACTION_TYPES.UPDATE_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //********************************UPDATE******************************************** */
    case USER_ACTION_TYPES.UPDATE_USER_PROFILE_SUCCESS:
      // return { ...state, userProfileState: [...action.payload] };
      return {
        ...state,
        userState: state.userState.map((user) =>
          user.id === action.payload ? action.payload : user
        ),
      };
    case USER_ACTION_TYPES.UPDATE_USER_PROFILE_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //********************************UPDATE******************************************** */
    case USER_ACTION_TYPES.CHANGE_USER_PASSWORD_SUCCESS:
      return { ...state, userProfileState: [...action.payload] };
    // return {
    //   ...state,
    //   userProfileState: state.userState.map((user) =>
    //     user.id === action.payload ? action.payload : user
    //   ),
    // };
    case USER_ACTION_TYPES.CHANGE_USER_PASSWORD_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //********************************FORGOT_PASSWORD******************************************** */
    case USER_ACTION_TYPES.FORGET_PASSWORD_SUCCESS:
      return { ...state };
    // return {
    //   ...state,
    //   userState: state.userState.map((user) =>
    //     user.id === action.payload ? action.payload : user
    //   ),
    // };
    case USER_ACTION_TYPES.FORGET_PASSWORD_ERROR:
      return {
        userErrorState: action.payload,
      };
    //*****************************FORGOT_PASSWORD******************************************** */
    //*************************DELETE********************************************* */
    // case USER_ACTION_TYPES.DELETE_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.DELETE_USER_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTION_TYPES.DELETE_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case USER_ACTION_TYPES.GET_ALL_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_USER_SUCCESS:
      return { ...state, activeUserState: [...action.payload] };
    case USER_ACTION_TYPES.GET_ALL_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    // *****************************GET_ALL******************************************/

    // case USER_ACTION_TYPES. GET_USER_ROLES_BY_USER_TYPE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_USER_ROLES_BY_USER_TYPE_SUCCESS:
      return { ...state, getUserRolesByUserTypeState: [...action.payload] };
    case USER_ACTION_TYPES.GET_USER_ROLES_BY_USER_TYPE_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL******************************************/

    // case USER_ACTION_TYPES.GET_ALL_USER_DETAIL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_USER_DETAIL_SUCCESS:
      return { ...state, activeUserState: [...action.payload] };
    case USER_ACTION_TYPES.GET_ALL_USER_DETAIL_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    // *****************************GET_ALL_NEW*****S*************************************/

    // case USER_ACTION_TYPES.GET_ALL_NEW_USERS_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_NEW_USERS_SUCCESS:
      return { ...state, newUserState: [...action.payload] };
    // return { ...state, userState: [...action.payload] };
    case USER_ACTION_TYPES.GET_ALL_NEW_USERS_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //******************************GET_ALL_NEW*****S************************************ */
    //********************* GET_BY_ID***********************************************/
    // case USER_ACTION_TYPES.GET_BY_ID_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };

    case USER_ACTION_TYPES.GET_BY_ID_USER_SUCCESS:
      return {
        ...state,
        userByIdState: [...action.payload],
      };
    case USER_ACTION_TYPES.GET_BY_ID_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* GET_BY_ID***********************************************/
    // case USER_ACTION_TYPES.GET_BY_ID_USER_DETAIL_REQUEST:
    //   return {
    //     isLoading: true,
    //   };

    case USER_ACTION_TYPES.GET_BY_ID_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userByIdDetailState: [...action.payload],
      };
    case USER_ACTION_TYPES.GET_BY_ID_USER_DETAIL_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case USER_ACTION_TYPES.COMPLETE_DELETE_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.COMPLETE_DELETE_USER_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTION_TYPES.COMPLETE_DELETE_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case USER_ACTION_TYPES.EMPTY_TRASH_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.EMPTY_TRASH_USER_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTION_TYPES.EMPTY_TRASH_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case USER_ACTION_TYPES.EXPORT_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.EXPORT_USER_SUCCESS:
      return {
        ...state,
        // userState: action.payload,
      };
    case USER_ACTION_TYPES.EXPORT_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case USER_ACTION_TYPES.RECOVER_ALL_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.RECOVER_ALL_USER_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTION_TYPES.RECOVER_ALL_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_USER_BY_ID***********************************************/
    // case USER_ACTION_TYPES.RECOVER_USER_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.RECOVER_USER_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTION_TYPES.RECOVER_USER_BY_ID_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************RECOVER_USER_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case USER_ACTION_TYPES.TRASH_ALL_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.TRASH_ALL_USER_SUCCESS:
      return { ...state };
    case USER_ACTION_TYPES.TRASH_ALL_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case USER_ACTION_TYPES.GET_ALL_TRASHED_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_TRASHED_USER_SUCCESS:
      return {
        ...state,
        userTState: [...action.payload],
      };
    case USER_ACTION_TYPES.GET_ALL_TRASHED_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case USER_ACTION_TYPES.ARCHIVE_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.ARCHIVE_USER_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTION_TYPES.ARCHIVE_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case USER_ACTION_TYPES.GET_ALL_ARCHIVED_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_ARCHIVED_USER_SUCCESS:
      return {
        ...state,
        userAState: [...action.payload],
      };
    case USER_ACTION_TYPES.GET_ALL_ARCHIVED_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case USER_ACTION_TYPES.GET_ALL_ARCHIVED_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_RECENTLY_LOGGEDIN_USER_SUCCESS:
      return {
        ...state,
        userRState: [...action.payload],
      };
    case USER_ACTION_TYPES.GET_ALL_RECENTLY_LOGGEDIN_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case USER_ACTION_TYPES.GET_ALL_ARCHIVED_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.GET_ALL_BLOCKED_USER_SUCCESS:
      return {
        ...state,
        userBState: [...action.payload],
      };
    case USER_ACTION_TYPES.GET_ALL_BLOCKED_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* UNBLOCK*********************************************/
    // case USER_ACTION_TYPES.UNBLOCKUSER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.UN_BLOCK_USER_SUCCESS:
      return {
        ...state,
        // userBState: [...action.payload],
      };
    case USER_ACTION_TYPES.UN_BLOCK_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************UNBLOCK********************************************** */
    //********************* BLOCK*********************************************/
    // case USER_ACTION_TYPES.BLOCKUSER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.BLOCK_USER_SUCCESS:
      return {
        ...state,
      
      };
    case USER_ACTION_TYPES.BLOCK_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************BLOCK********************************************** */

    //********************* GET_ALL_ARCHIVED***********************************************/
    // case USER_ACTION_TYPES.RECOVER_ALL_ARCHIVED_USER_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.RECOVER_ALL_ARCHIVED_USER_SUCCESS:
      return {
        ...state,
        // userState: [...action.payload],
      };
    case USER_ACTION_TYPES.RECOVER_ALL_ARCHIVED_USER_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case USER_ACTION_TYPES.RECOVER_ARCHIVED_USER_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case USER_ACTION_TYPES.RECOVER_ARCHIVED_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userState: [...action.payload],
      };
    case USER_ACTION_TYPES.RECOVER_ARCHIVED_USER_BY_ID_FAILURE:
      return {
        userErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */

    default:
      return state;
  }
};

export default USER_REDUCER;
