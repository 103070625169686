const COUNTRY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_COUNTRY_REQUEST: "GET_ALL_COUNTRY_REQUEST",
  GET_ALL_COUNTRY_SUCCESS: "GET_ALL_COUNTRY_SUCCESS",
  GET_ALL_COUNTRY_FAILURE: "GET_ALL_COUNTRY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_COUNTRY_REQUEST: "GET_BY_ID_COUNTRY_REQUEST",
  GET_BY_ID_COUNTRY_SUCCESS: "GET_BY_ID_COUNTRY_SUCCESS",
  GET_BY_ID_COUNTRY_FAILURE: "GET_BY_ID_COUNTRY_FAILURE",

  /************************************************************************* */
  CREATE_COUNTRY_REQUEST: "CREATE_COUNTRY_REQUEST",
  CREATE_COUNTRY_SUCCESS: "CREATE_COUNTRY_SUCCESS",
  CREATE_COUNTRY_FAILURE: "CREATE_COUNTRY_FAILURE",

  /************************************************************************* */
  UPDATE_COUNTRY_REQUEST: "UPDATE_COUNTRY_REQUEST",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_FAILURE: "UPDATE_COUNTRY_FAILURE",

  /************************************************************************* */
  DELETE_COUNTRY_REQUEST: "DELETE_COUNTRY_REQUEST",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILURE: "DELETE_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_COUNTRY_BY_ID_REQUEST: "RECOVER_BY_ID_COUNTRY_BY_ID_REQUEST",
  RECOVER_COUNTRY_BY_ID_SUCCESS: "RECOVER_COUNTRY_BY_ID_SUCCESS",
  RECOVER_COUNTRY_BY_ID_FAILURE: "RECOVER_COUNTRY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_COUNTRY_REQUEST: "RECOVER_ALL_COUNTRY_REQUEST",
  RECOVER_ALL_COUNTRY_SUCCESS: "RECOVER_ALL_COUNTRY_SUCCESS",
  RECOVER_ALL_COUNTRY_FAILURE: "RECOVER_ALL_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_COUNTRY_REQUEST: "COMPLETE_DELETE_COUNTRY_REQUEST",
  COMPLETE_DELETE_COUNTRY_SUCCESS: "COMPLETE_DELETE_COUNTRY_SUCCESS",
  COMPLETE_DELETE_COUNTRY_FAILURE: "COMPLETE_DELETE_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_COUNTRY_REQUEST: "TRASH_ALL_COUNTRY_REQUEST",
  TRASH_ALL_COUNTRY_SUCCESS: "TRASH_ALL_COUNTRY_SUCCESS",
  TRASH_ALL_COUNTRY_FAILURE: "TRASH_ALL_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_COUNTRY_REQUEST: "EMPTY_TRASH_COUNTRY_REQUEST",
  EMPTY_TRASH_COUNTRY_SUCCESS: "EMPTY_TRASH_COUNTRY_SUCCESS",
  EMPTY_TRASH_COUNTRY_FAILURE: "EMPTY_TRASH_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_COUNTRY_REQUEST: "EXPORT_COUNTRY_REQUEST",
  EXPORT_COUNTRY_SUCCESS: "EXPORT_COUNTRY_SUCCESS",
  EXPORT_COUNTRY_FAILURE: "EXPORT_COUNTRY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_COUNTRY_REQUEST: "GET_ALL_TRASHED_COUNTRY_REQUEST",
  GET_ALL_TRASHED_COUNTRY_SUCCESS: "GET_ALL_TRASHED_COUNTRY_SUCCESS",
  GET_ALL_TRASHED_COUNTRY_FAILURE: "GET_ALL_TRASHED_COUNTRY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_COUNTRY_REQUEST: "ARCHIVE_COUNTRY_REQUEST",
  ARCHIVE_COUNTRY_SUCCESS: "ARCHIVE_COUNTRY_SUCCESS",
  ARCHIVE_COUNTRY_FAILURE: "ARCHIVE_COUNTRY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_COUNTRY_REQUEST: "GET_ALL_ARCHIVED_COUNTRY_REQUEST",
  GET_ALL_ARCHIVED_COUNTRY_SUCCESS: "GET_ALL_ARCHIVED_COUNTRY_SUCCESS",
  GET_ALL_ARCHIVED_COUNTRY_FAILURE: "GET_ALL_ARCHIVED_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_COUNTRY_REQUEST: "RECOVER_ALL_ARCHIVED_COUNTRY_REQUEST",
  RECOVER_ALL_ARCHIVED_COUNTRY_SUCCESS: "RECOVER_ALL_ARCHIVED_COUNTRY_SUCCESS",
  RECOVER_ALL_ARCHIVED_COUNTRY_FAILURE: "RECOVER_ALL_ARCHIVED_COUNTRY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_COUNTRY_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_COUNTRY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_COUNTRY_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_COUNTRY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_COUNTRY_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_COUNTRY_BY_ID_FAILURE",
  /************************************************************************* */
};

export default COUNTRY_ACTION_TYPES;
