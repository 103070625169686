const PROJECT_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_PROJECT_REQUEST: "GET_ALL_PROJECT_REQUEST",
  GET_ALL_PROJECT_SUCCESS: "GET_ALL_PROJECT_SUCCESS",
  GET_ALL_PROJECT_FAILURE: "GET_ALL_PROJECT_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_PROJECT_BY_USER_ID_REQUEST: "GET_ALL_PROJECT_BY_USER_ID_REQUEST",
  GET_ALL_PROJECT_BY_USER_ID_SUCCESS: "GET_ALL_PROJECT_BY_USER_ID_SUCCESS",
  GET_ALL_PROJECT_BY_USER_ID_FAILURE: "GET_ALL_PROJECT_BY_USER_ID_FAILURE",

  /************************************************************************* */
  GET_BY_ID_PROJECT_REQUEST: "GET_BY_ID_PROJECT_REQUEST",
  GET_BY_ID_PROJECT_SUCCESS: "GET_BY_ID_PROJECT_SUCCESS",
  GET_BY_ID_PROJECT_FAILURE: "GET_BY_ID_PROJECT_FAILURE",

  /************************************************************************* */
  CREATE_PROJECT_REQUEST: "CREATE_PROJECT_REQUEST",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE: "CREATE_PROJECT_FAILURE",

  /************************************************************************* */
  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",

  /************************************************************************* */
  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_PROJECT_BY_ID_REQUEST: "RECOVER_BY_ID_PROJECT_BY_ID_REQUEST",
  RECOVER_PROJECT_BY_ID_SUCCESS: "RECOVER_PROJECT_BY_ID_SUCCESS",
  RECOVER_PROJECT_BY_ID_FAILURE: "RECOVER_PROJECT_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_PROJECT_REQUEST: "RECOVER_ALL_PROJECT_REQUEST",
  RECOVER_ALL_PROJECT_SUCCESS: "RECOVER_ALL_PROJECT_SUCCESS",
  RECOVER_ALL_PROJECT_FAILURE: "RECOVER_ALL_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_PROJECT_REQUEST: "COMPLETE_DELETE_PROJECT_REQUEST",
  COMPLETE_DELETE_PROJECT_SUCCESS: "COMPLETE_DELETE_PROJECT_SUCCESS",
  COMPLETE_DELETE_PROJECT_FAILURE: "COMPLETE_DELETE_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_PROJECT_REQUEST: "TRASH_ALL_PROJECT_REQUEST",
  TRASH_ALL_PROJECT_SUCCESS: "TRASH_ALL_PROJECT_SUCCESS",
  TRASH_ALL_PROJECT_FAILURE: "TRASH_ALL_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_PROJECT_REQUEST: "EMPTY_TRASH_PROJECT_REQUEST",
  EMPTY_TRASH_PROJECT_SUCCESS: "EMPTY_TRASH_PROJECT_SUCCESS",
  EMPTY_TRASH_PROJECT_FAILURE: "EMPTY_TRASH_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_PROJECT_REQUEST: "EXPORT_PROJECT_REQUEST",
  EXPORT_PROJECT_SUCCESS: "EXPORT_PROJECT_SUCCESS",
  EXPORT_PROJECT_FAILURE: "EXPORT_PROJECT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_PROJECT_REQUEST: "GET_ALL_TRASHED_PROJECT_REQUEST",
  GET_ALL_TRASHED_PROJECT_SUCCESS: "GET_ALL_TRASHED_PROJECT_SUCCESS",
  GET_ALL_TRASHED_PROJECT_FAILURE: "GET_ALL_TRASHED_PROJECT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_PROJECT_REQUEST: "ARCHIVE_PROJECT_REQUEST",
  ARCHIVE_PROJECT_SUCCESS: "ARCHIVE_PROJECT_SUCCESS",
  ARCHIVE_PROJECT_FAILURE: "ARCHIVE_PROJECT_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_PROJECT_REQUEST: "GET_ALL_ARCHIVED_PROJECT_REQUEST",
  GET_ALL_ARCHIVED_PROJECT_SUCCESS: "GET_ALL_ARCHIVED_PROJECT_SUCCESS",
  GET_ALL_ARCHIVED_PROJECT_FAILURE: "GET_ALL_ARCHIVED_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_PROJECT_REQUEST: "RECOVER_ALL_ARCHIVED_PROJECT_REQUEST",
  RECOVER_ALL_ARCHIVED_PROJECT_SUCCESS: "RECOVER_ALL_ARCHIVED_PROJECT_SUCCESS",
  RECOVER_ALL_ARCHIVED_PROJECT_FAILURE: "RECOVER_ALL_ARCHIVED_PROJECT_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_PROJECT_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_PROJECT_BY_ID_REQUEST",
  RECOVER_ARCHIVED_PROJECT_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_PROJECT_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_PROJECT_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_PROJECT_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_PROJECT_GROUPED_BY_USER_ID_REQUEST:
    "GET_ALL_PROJECT_GROUPED_BY_USER_ID_REQUEST",
  GET_ALL_PROJECT_GROUPED_BY_USER_ID_SUCCESS:
    "GET_ALL_PROJECT_GROUPED_BY_USER_ID_SUCCESS",
  GET_ALL_PROJECT_GROUPED_BY_USER_ID_FAILURE:
    "GET_ALL_PROJECT_GROUPED_BY_USER_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_REQUEST:
    " GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_REQUEST",
  GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_SUCCESS:
    " GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_SUCCESS",
  GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_FAILURE:
    " GET_ALL_USER_ACCOUNT_GROUPED_BY_PROJECT_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_REQUEST:
    " GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_REQUEST",
  GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_SUCCESS:
    " GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_SUCCESS",
  GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_FAILURE:
    " GET_ALL_SPECIFIC_OBJECTIVES_BY_PROJECT_ID_FAILURE",
  /************************************************************************* */
};

export default PROJECT_ACTION_TYPES;
