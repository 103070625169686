const ROLE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ROLE_REQUEST: "GET_ALL_ROLE_REQUEST",
  GET_ALL_ROLE_SUCCESS: "GET_ALL_ROLE_SUCCESS",
  GET_ALL_ROLE_FAILURE: "GET_ALL_ROLE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ROLE_REQUEST: "GET_BY_ID_ROLE_REQUEST",
  GET_BY_ID_ROLE_SUCCESS: "GET_BY_ID_ROLE_SUCCESS",
  GET_BY_ID_ROLE_FAILURE: "GET_BY_ID_ROLE_FAILURE",

  /************************************************************************* */
  CREATE_ROLE_REQUEST: "CREATE_ROLE_REQUEST",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",

  /************************************************************************* */
  UPDATE_ROLE_REQUEST: "UPDATE_ROLE_REQUEST",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",

  /************************************************************************* */
  DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ROLE_BY_ID_REQUEST: "RECOVER_BY_ID_ROLE_BY_ID_REQUEST",
  RECOVER_ROLE_BY_ID_SUCCESS: "RECOVER_ROLE_BY_ID_SUCCESS",
  RECOVER_ROLE_BY_ID_FAILURE: "RECOVER_ROLE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ROLE_REQUEST: "RECOVER_ALL_ROLE_REQUEST",
  RECOVER_ALL_ROLE_SUCCESS: "RECOVER_ALL_ROLE_SUCCESS",
  RECOVER_ALL_ROLE_FAILURE: "RECOVER_ALL_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ROLE_REQUEST: "COMPLETE_DELETE_ROLE_REQUEST",
  COMPLETE_DELETE_ROLE_SUCCESS: "COMPLETE_DELETE_ROLE_SUCCESS",
  COMPLETE_DELETE_ROLE_FAILURE: "COMPLETE_DELETE_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ROLE_REQUEST: "TRASH_ALL_ROLE_REQUEST",
  TRASH_ALL_ROLE_SUCCESS: "TRASH_ALL_ROLE_SUCCESS",
  TRASH_ALL_ROLE_FAILURE: "TRASH_ALL_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ROLE_REQUEST: "EMPTY_TRASH_ROLE_REQUEST",
  EMPTY_TRASH_ROLE_SUCCESS: "EMPTY_TRASH_ROLE_SUCCESS",
  EMPTY_TRASH_ROLE_FAILURE: "EMPTY_TRASH_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ROLE_REQUEST: "EXPORT_ROLE_REQUEST",
  EXPORT_ROLE_SUCCESS: "EXPORT_ROLE_SUCCESS",
  EXPORT_ROLE_FAILURE: "EXPORT_ROLE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ROLE_REQUEST: "GET_ALL_TRASHED_ROLE_REQUEST",
  GET_ALL_TRASHED_ROLE_SUCCESS: "GET_ALL_TRASHED_ROLE_SUCCESS",
  GET_ALL_TRASHED_ROLE_FAILURE: "GET_ALL_TRASHED_ROLE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ROLE_REQUEST: "ARCHIVE_ROLE_REQUEST",
  ARCHIVE_ROLE_SUCCESS: "ARCHIVE_ROLE_SUCCESS",
  ARCHIVE_ROLE_FAILURE: "ARCHIVE_ROLE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ROLE_REQUEST: "GET_ALL_ARCHIVED_ROLE_REQUEST",
  GET_ALL_ARCHIVED_ROLE_SUCCESS: "GET_ALL_ARCHIVED_ROLE_SUCCESS",
  GET_ALL_ARCHIVED_ROLE_FAILURE: "GET_ALL_ARCHIVED_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ROLE_REQUEST: "RECOVER_ALL_ARCHIVED_ROLE_REQUEST",
  RECOVER_ALL_ARCHIVED_ROLE_SUCCESS: "RECOVER_ALL_ARCHIVED_ROLE_SUCCESS",
  RECOVER_ALL_ARCHIVED_ROLE_FAILURE: "RECOVER_ALL_ARCHIVED_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ROLE_BY_ID_REQUEST: "RECOVER_ARCHIVED_ROLE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ROLE_BY_ID_SUCCESS: "RECOVER_ARCHIVED_ROLE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ROLE_BY_ID_FAILURE: "RECOVER_ARCHIVED_ROLE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ROLE_ACTION_TYPES;
