const MANAGER_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_MANAGER_REQUEST: "GET_ALL_MANAGER_REQUEST",
  GET_ALL_MANAGER_SUCCESS: "GET_ALL_MANAGER_SUCCESS",
  GET_ALL_MANAGER_FAILURE: "GET_ALL_MANAGER_FAILURE",

  /************************************************************************* */
  GET_BY_ID_MANAGER_REQUEST: "GET_BY_ID_MANAGER_REQUEST",
  GET_BY_ID_MANAGER_SUCCESS: "GET_BY_ID_MANAGER_SUCCESS",
  GET_BY_ID_MANAGER_FAILURE: "GET_BY_ID_MANAGER_FAILURE",

  /************************************************************************* */
  CREATE_MANAGER_REQUEST: "CREATE_MANAGER_REQUEST",
  CREATE_MANAGER_SUCCESS: "CREATE_MANAGER_SUCCESS",
  CREATE_MANAGER_FAILURE: "CREATE_MANAGER_FAILURE",

  /************************************************************************* */
  UPDATE_MANAGER_REQUEST: "UPDATE_MANAGER_REQUEST",
  UPDATE_MANAGER_SUCCESS: "UPDATE_MANAGER_SUCCESS",
  UPDATE_MANAGER_FAILURE: "UPDATE_MANAGER_FAILURE",

  /************************************************************************* */
  DELETE_MANAGER_REQUEST: "DELETE_MANAGER_REQUEST",
  DELETE_MANAGER_SUCCESS: "DELETE_MANAGER_SUCCESS",
  DELETE_MANAGER_FAILURE: "DELETE_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_MANAGER_BY_ID_REQUEST: "RECOVER_BY_ID_MANAGER_BY_ID_REQUEST",
  RECOVER_MANAGER_BY_ID_SUCCESS: "RECOVER_MANAGER_BY_ID_SUCCESS",
  RECOVER_MANAGER_BY_ID_FAILURE: "RECOVER_MANAGER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_MANAGER_REQUEST: "RECOVER_ALL_MANAGER_REQUEST",
  RECOVER_ALL_MANAGER_SUCCESS: "RECOVER_ALL_MANAGER_SUCCESS",
  RECOVER_ALL_MANAGER_FAILURE: "RECOVER_ALL_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_MANAGER_REQUEST: "COMPLETE_DELETE_MANAGER_REQUEST",
  COMPLETE_DELETE_MANAGER_SUCCESS: "COMPLETE_DELETE_MANAGER_SUCCESS",
  COMPLETE_DELETE_MANAGER_FAILURE: "COMPLETE_DELETE_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_MANAGER_REQUEST: "TRASH_ALL_MANAGER_REQUEST",
  TRASH_ALL_MANAGER_SUCCESS: "TRASH_ALL_MANAGER_SUCCESS",
  TRASH_ALL_MANAGER_FAILURE: "TRASH_ALL_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_MANAGER_REQUEST: "EMPTY_TRASH_MANAGER_REQUEST",
  EMPTY_TRASH_MANAGER_SUCCESS: "EMPTY_TRASH_MANAGER_SUCCESS",
  EMPTY_TRASH_MANAGER_FAILURE: "EMPTY_TRASH_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_MANAGER_REQUEST: "EXPORT_MANAGER_REQUEST",
  EXPORT_MANAGER_SUCCESS: "EXPORT_MANAGER_SUCCESS",
  EXPORT_MANAGER_FAILURE: "EXPORT_MANAGER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_MANAGER_REQUEST: "GET_ALL_TRASHED_MANAGER_REQUEST",
  GET_ALL_TRASHED_MANAGER_SUCCESS: "GET_ALL_TRASHED_MANAGER_SUCCESS",
  GET_ALL_TRASHED_MANAGER_FAILURE: "GET_ALL_TRASHED_MANAGER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_MANAGER_REQUEST: "ARCHIVE_MANAGER_REQUEST",
  ARCHIVE_MANAGER_SUCCESS: "ARCHIVE_MANAGER_SUCCESS",
  ARCHIVE_MANAGER_FAILURE: "ARCHIVE_MANAGER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_MANAGER_REQUEST: "GET_ALL_ARCHIVED_MANAGER_REQUEST",
  GET_ALL_ARCHIVED_MANAGER_SUCCESS: "GET_ALL_ARCHIVED_MANAGER_SUCCESS",
  GET_ALL_ARCHIVED_MANAGER_FAILURE: "GET_ALL_ARCHIVED_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_MANAGER_REQUEST: "RECOVER_ALL_ARCHIVED_MANAGER_REQUEST",
  RECOVER_ALL_ARCHIVED_MANAGER_SUCCESS: "RECOVER_ALL_ARCHIVED_MANAGER_SUCCESS",
  RECOVER_ALL_ARCHIVED_MANAGER_FAILURE: "RECOVER_ALL_ARCHIVED_MANAGER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_MANAGER_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_MANAGER_BY_ID_REQUEST",
  RECOVER_ARCHIVED_MANAGER_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_MANAGER_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_MANAGER_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_MANAGER_BY_ID_FAILURE",
  /************************************************************************* */
};

export default MANAGER_ACTION_TYPES;
