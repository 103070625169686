const TRAINING_TYPE_ACTION_TYPES = {
    /************************************************************************* */
    GET_ALL_TRAINING_TYPE_REQUEST: "GET_ALL_TRAINING_TYPE_REQUEST",
    GET_ALL_TRAINING_TYPE_SUCCESS: "GET_ALL_TRAINING_TYPE_SUCCESS",
    GET_ALL_TRAINING_TYPE_FAILURE: "GET_ALL_TRAINING_TYPE_FAILURE",
  
    /************************************************************************* */
    GET_BY_ID_TRAINING_TYPE_REQUEST: "GET_BY_ID_TRAINING_TYPE_REQUEST",
    GET_BY_ID_TRAINING_TYPE_SUCCESS: "GET_BY_ID_TRAINING_TYPE_SUCCESS",
    GET_BY_ID_TRAINING_TYPE_FAILURE: "GET_BY_ID_TRAINING_TYPE_FAILURE",
  
    /************************************************************************* */
    CREATE_TRAINING_TYPE_REQUEST: "CREATE_TRAINING_TYPE_REQUEST",
    CREATE_TRAINING_TYPE_SUCCESS: "CREATE_TRAINING_TYPE_SUCCESS",
    CREATE_TRAINING_TYPE_FAILURE: "CREATE_TRAINING_TYPE_FAILURE",
  
    /************************************************************************* */
    UPDATE_TRAINING_TYPE_REQUEST: "UPDATE_TRAINING_TYPE_REQUEST",
    UPDATE_TRAINING_TYPE_SUCCESS: "UPDATE_TRAINING_TYPE_SUCCESS",
    UPDATE_TRAINING_TYPE_FAILURE: "UPDATE_TRAINING_TYPE_FAILURE",
  
    /************************************************************************* */
    DELETE_TRAINING_TYPE_REQUEST: "DELETE_TRAINING_TYPE_REQUEST",
    DELETE_TRAINING_TYPE_SUCCESS: "DELETE_TRAINING_TYPE_SUCCESS",
    DELETE_TRAINING_TYPE_FAILURE: "DELETE_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_TRAINING_TYPE_BY_ID_REQUEST:
      "RECOVER_BY_ID_TRAINING_TYPE_BY_ID_REQUEST",
    RECOVER_TRAINING_TYPE_BY_ID_SUCCESS: "RECOVER_TRAINING_TYPE_BY_ID_SUCCESS",
    RECOVER_TRAINING_TYPE_BY_ID_FAILURE: "RECOVER_TRAINING_TYPE_BY_ID_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_ALL_TRAINING_TYPE_REQUEST: "RECOVER_ALL_TRAINING_TYPE_REQUEST",
    RECOVER_ALL_TRAINING_TYPE_SUCCESS: "RECOVER_ALL_TRAINING_TYPE_SUCCESS",
    RECOVER_ALL_TRAINING_TYPE_FAILURE: "RECOVER_ALL_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    COMPLETE_DELETE_TRAINING_TYPE_REQUEST:
      "COMPLETE_DELETE_TRAINING_TYPE_REQUEST",
    COMPLETE_DELETE_TRAINING_TYPE_SUCCESS:
      "COMPLETE_DELETE_TRAINING_TYPE_SUCCESS",
    COMPLETE_DELETE_TRAINING_TYPE_FAILURE:
      "COMPLETE_DELETE_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    TRASH_ALL_TRAINING_TYPE_REQUEST: "TRASH_ALL_TRAINING_TYPE_REQUEST",
    TRASH_ALL_TRAINING_TYPE_SUCCESS: "TRASH_ALL_TRAINING_TYPE_SUCCESS",
    TRASH_ALL_TRAINING_TYPE_FAILURE: "TRASH_ALL_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    EMPTY_TRASH_TRAINING_TYPE_REQUEST: "EMPTY_TRASH_TRAINING_TYPE_REQUEST",
    EMPTY_TRASH_TRAINING_TYPE_SUCCESS: "EMPTY_TRASH_TRAINING_TYPE_SUCCESS",
    EMPTY_TRASH_TRAINING_TYPE_FAILURE: "EMPTY_TRASH_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    EXPORT_TRAINING_TYPE_REQUEST: "EXPORT_TRAINING_TYPE_REQUEST",
    EXPORT_TRAINING_TYPE_SUCCESS: "EXPORT_TRAINING_TYPE_SUCCESS",
    EXPORT_TRAINING_TYPE_FAILURE: "EXPORT_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
  
    /************************************************************************* */
    GET_ALL_TRASHED_TRAINING_TYPE_REQUEST:
      "GET_ALL_TRASHED_TRAINING_TYPE_REQUEST",
    GET_ALL_TRASHED_TRAINING_TYPE_SUCCESS:
      "GET_ALL_TRASHED_TRAINING_TYPE_SUCCESS",
    GET_ALL_TRASHED_TRAINING_TYPE_FAILURE:
      "GET_ALL_TRASHED_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
  
    /************************************************************************* */
    ARCHIVE_TRAINING_TYPE_REQUEST: "ARCHIVE_TRAINING_TYPE_REQUEST",
    ARCHIVE_TRAINING_TYPE_SUCCESS: "ARCHIVE_TRAINING_TYPE_SUCCESS",
    ARCHIVE_TRAINING_TYPE_FAILURE: "ARCHIVE_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
  
    /************************************************************************* */
    GET_ALL_ARCHIVED_TRAINING_TYPE_REQUEST:
      "GET_ALL_ARCHIVED_TRAINING_TYPE_REQUEST",
    GET_ALL_ARCHIVED_TRAINING_TYPE_SUCCESS:
      "GET_ALL_ARCHIVED_TRAINING_TYPE_SUCCESS",
    GET_ALL_ARCHIVED_TRAINING_TYPE_FAILURE:
      "GET_ALL_ARCHIVED_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_ALL_ARCHIVED_TRAINING_TYPE_REQUEST:
      "RECOVER_ALL_ARCHIVED_TRAINING_TYPE_REQUEST",
    RECOVER_ALL_ARCHIVED_TRAINING_TYPE_SUCCESS:
      "RECOVER_ALL_ARCHIVED_TRAINING_TYPE_SUCCESS",
    RECOVER_ALL_ARCHIVED_TRAINING_TYPE_FAILURE:
      "RECOVER_ALL_ARCHIVED_TRAINING_TYPE_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_REQUEST:
      "RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_REQUEST",
    RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_SUCCESS:
      "RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_SUCCESS",
    RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_FAILURE:
      "RECOVER_ARCHIVED_TRAINING_TYPE_BY_ID_FAILURE",
    /************************************************************************* */
  };
  
  export default TRAINING_TYPE_ACTION_TYPES;
  