const TRAINING_ACTION_TYPES = {
    /************************************************************************* */
    GET_ALL_TRAINING_REQUEST: "GET_ALL_TRAINING_REQUEST",
    GET_ALL_TRAINING_SUCCESS: "GET_ALL_TRAINING_SUCCESS",
    GET_ALL_TRAINING_FAILURE: "GET_ALL_TRAINING_FAILURE",
  
    /************************************************************************* */
    GET_BY_ID_TRAINING_REQUEST: "GET_BY_ID_TRAINING_REQUEST",
    GET_BY_ID_TRAINING_SUCCESS: "GET_BY_ID_TRAINING_SUCCESS",
    GET_BY_ID_TRAINING_FAILURE: "GET_BY_ID_TRAINING_FAILURE",
  
    /************************************************************************* */
    CREATE_TRAINING_REQUEST: "CREATE_TRAINING_REQUEST",
    CREATE_TRAINING_SUCCESS: "CREATE_TRAINING_SUCCESS",
    CREATE_TRAINING_FAILURE: "CREATE_TRAINING_FAILURE",
  
    /************************************************************************* */
    UPDATE_TRAINING_REQUEST: "UPDATE_TRAINING_REQUEST",
    UPDATE_TRAINING_SUCCESS: "UPDATE_TRAINING_SUCCESS",
    UPDATE_TRAINING_FAILURE: "UPDATE_TRAINING_FAILURE",
  
    /************************************************************************* */
    DELETE_TRAINING_REQUEST: "DELETE_TRAINING_REQUEST",
    DELETE_TRAINING_SUCCESS: "DELETE_TRAINING_SUCCESS",
    DELETE_TRAINING_FAILURE: "DELETE_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_TRAINING_BY_ID_REQUEST: "RECOVER_BY_ID_TRAINING_BY_ID_REQUEST",
    RECOVER_TRAINING_BY_ID_SUCCESS: "RECOVER_TRAINING_BY_ID_SUCCESS",
    RECOVER_TRAINING_BY_ID_FAILURE: "RECOVER_TRAINING_BY_ID_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_ALL_TRAINING_REQUEST: "RECOVER_ALL_TRAINING_REQUEST",
    RECOVER_ALL_TRAINING_SUCCESS: "RECOVER_ALL_TRAINING_SUCCESS",
    RECOVER_ALL_TRAINING_FAILURE: "RECOVER_ALL_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    COMPLETE_DELETE_TRAINING_REQUEST: "COMPLETE_DELETE_TRAINING_REQUEST",
    COMPLETE_DELETE_TRAINING_SUCCESS: "COMPLETE_DELETE_TRAINING_SUCCESS",
    COMPLETE_DELETE_TRAINING_FAILURE: "COMPLETE_DELETE_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    TRASH_ALL_TRAINING_REQUEST: "TRASH_ALL_TRAINING_REQUEST",
    TRASH_ALL_TRAINING_SUCCESS: "TRASH_ALL_TRAINING_SUCCESS",
    TRASH_ALL_TRAINING_FAILURE: "TRASH_ALL_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    EMPTY_TRASH_TRAINING_REQUEST: "EMPTY_TRASH_TRAINING_REQUEST",
    EMPTY_TRASH_TRAINING_SUCCESS: "EMPTY_TRASH_TRAINING_SUCCESS",
    EMPTY_TRASH_TRAINING_FAILURE: "EMPTY_TRASH_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    EXPORT_TRAINING_REQUEST: "EXPORT_TRAINING_REQUEST",
    EXPORT_TRAINING_SUCCESS: "EXPORT_TRAINING_SUCCESS",
    EXPORT_TRAINING_FAILURE: "EXPORT_TRAINING_FAILURE",
    /************************************************************************* */
  
    /************************************************************************* */
    GET_ALL_TRASHED_TRAINING_REQUEST: "GET_ALL_TRASHED_TRAINING_REQUEST",
    GET_ALL_TRASHED_TRAINING_SUCCESS: "GET_ALL_TRASHED_TRAINING_SUCCESS",
    GET_ALL_TRASHED_TRAINING_FAILURE: "GET_ALL_TRASHED_TRAINING_FAILURE",
    /************************************************************************* */
  
    /************************************************************************* */
    ARCHIVE_TRAINING_REQUEST: "ARCHIVE_TRAINING_REQUEST",
    ARCHIVE_TRAINING_SUCCESS: "ARCHIVE_TRAINING_SUCCESS",
    ARCHIVE_TRAINING_FAILURE: "ARCHIVE_TRAINING_FAILURE",
    /************************************************************************* */
  
    /************************************************************************* */
    GET_ALL_ARCHIVED_TRAINING_REQUEST: "GET_ALL_ARCHIVED_TRAINING_REQUEST",
    GET_ALL_ARCHIVED_TRAINING_SUCCESS: "GET_ALL_ARCHIVED_TRAINING_SUCCESS",
    GET_ALL_ARCHIVED_TRAINING_FAILURE: "GET_ALL_ARCHIVED_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_ALL_ARCHIVED_TRAINING_REQUEST:
      "RECOVER_ALL_ARCHIVED_TRAINING_REQUEST",
    RECOVER_ALL_ARCHIVED_TRAINING_SUCCESS:
      "RECOVER_ALL_ARCHIVED_TRAINING_SUCCESS",
    RECOVER_ALL_ARCHIVED_TRAINING_FAILURE:
      "RECOVER_ALL_ARCHIVED_TRAINING_FAILURE",
    /************************************************************************* */
    /************************************************************************* */
    RECOVER_ARCHIVED_TRAINING_BY_ID_REQUEST:
      "RECOVER_ARCHIVED_TRAINING_BY_ID_REQUEST",
    RECOVER_ARCHIVED_TRAINING_BY_ID_SUCCESS:
      "RECOVER_ARCHIVED_TRAINING_BY_ID_SUCCESS",
    RECOVER_ARCHIVED_TRAINING_BY_ID_FAILURE:
      "RECOVER_ARCHIVED_TRAINING_BY_ID_FAILURE",
    /************************************************************************* */
  };
  
  export default TRAINING_ACTION_TYPES;
  