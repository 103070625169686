import KEBELE_ACTION_TYPES from "../actionTypes/kebeleActionTypes";

const initialState = {
  isLoading: false,
  kebeleState: [],
  kebeleErrorState: "",
};

const KEBELE_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case KEBELE_ACTION_TYPES.CREATE_KEBELE_SUCCESS:
      return {
        ...state,
      };
    // case KEBELE_ACTION_TYPES.CREATE_KEBELE_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case KEBELE_ACTION_TYPES.CREATE_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case KEBELE_ACTION_TYPES.UPDATE_KEBELE_SUCCESS:
      return {
        ...state,
        kebeleState: state.kebeleState.map((k) =>
          k.id === action.payload ? action.payload : k
        ),
      };
    case KEBELE_ACTION_TYPES.UPDATE_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case KEBELE_ACTION_TYPES.DELETE_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.DELETE_KEBELE_SUCCESS:
      return {
        ...state,
      };
    case KEBELE_ACTION_TYPES.DELETE_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case KEBELE_ACTION_TYPES.GET_ALL_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.GET_ALL_KEBELE_SUCCESS:
      return { ...state, kebeleState: [...action.payload] };
    case KEBELE_ACTION_TYPES.GET_ALL_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case KEBELE_ACTION_TYPES.GET_BY_ID_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.GET_BY_ID_KEBELE_SUCCESS:
      return {
        ...state,
        kebeleState: action.payload,
      };
    case KEBELE_ACTION_TYPES.GET_BY_ID_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case KEBELE_ACTION_TYPES.COMPLETE_DELETE_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.COMPLETE_DELETE_KEBELE_SUCCESS:
      return {
        ...state,
      };
    case KEBELE_ACTION_TYPES.COMPLETE_DELETE_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case KEBELE_ACTION_TYPES.EMPTY_TRASH_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.EMPTY_TRASH_KEBELE_SUCCESS:
      return {
        ...state,
      };
    case KEBELE_ACTION_TYPES.EMPTY_TRASH_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case KEBELE_ACTION_TYPES.EXPORT_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.EXPORT_KEBELE_SUCCESS:
      return {
        ...state,
        // kebeleState: action.payload,
      };
    case KEBELE_ACTION_TYPES.EXPORT_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case KEBELE_ACTION_TYPES.RECOVER_ALL_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.RECOVER_ALL_KEBELE_SUCCESS:
      return {
        ...state,
      };
    case KEBELE_ACTION_TYPES.RECOVER_ALL_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_KEBELE_BY_ID***********************************************/
    // case KEBELE_ACTION_TYPES.RECOVER_KEBELE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.RECOVER_KEBELE_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case KEBELE_ACTION_TYPES.RECOVER_KEBELE_BY_ID_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************RECOVER_KEBELE_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case KEBELE_ACTION_TYPES.TRASH_ALL_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.TRASH_ALL_KEBELE_SUCCESS:
      return { ...state };
    case KEBELE_ACTION_TYPES.TRASH_ALL_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case KEBELE_ACTION_TYPES.GET_ALL_TRASHED_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.GET_ALL_TRASHED_KEBELE_SUCCESS:
      return {
        ...state,
        kebeleState: [...action.payload],
      };
    case KEBELE_ACTION_TYPES.GET_ALL_TRASHED_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case KEBELE_ACTION_TYPES.ARCHIVE_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.ARCHIVE_KEBELE_SUCCESS:
      return {
        ...state,
      };
    case KEBELE_ACTION_TYPES.ARCHIVE_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case KEBELE_ACTION_TYPES.GET_ALL_ARCHIVED_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.GET_ALL_ARCHIVED_KEBELE_SUCCESS:
      return {
        ...state,
        kebeleState: [...action.payload],
      };
    case KEBELE_ACTION_TYPES.GET_ALL_ARCHIVED_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case KEBELE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_KEBELE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_KEBELE_SUCCESS:
      return {
        ...state,
        // kebeleState: [...action.payload],
      };
    case KEBELE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_KEBELE_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case KEBELE_ACTION_TYPES.RECOVER_ARCHIVED_KEBELE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case KEBELE_ACTION_TYPES.RECOVER_ARCHIVED_KEBELE_BY_ID_SUCCESS:
      return {
        ...state,
        kebeleState: [...action.payload],
      };
    case KEBELE_ACTION_TYPES.RECOVER_ARCHIVED_KEBELE_BY_ID_FAILURE:
      return {
        kebeleErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default KEBELE_REDUCER;
