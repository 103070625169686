import EMPLOYEE_GUARANTOR_ACTION_TYPES from "../actionTypes/employeeGuarantorActionTypes";

const initialState = {
  isLoading: false,
  employeeGuarantorState: [],
  employeeGuarantorErrorState: "",
};

const EMPLOYEE_GUARANTOR_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.CREATE_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
      };
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.CREATE_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.CREATE_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.UPDATE_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
        employeeGuarantorState: state.employeeGuarantorState.map((eG) =>
          eG.id === action.payload ? action.payload : eG
        ),
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.UPDATE_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.DELETE_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.DELETE_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.DELETE_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_EMPLOYEE_GUARANTOR_SUCCESS:
      return { ...state, employeeGuarantorState: [...action.payload] };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_BY_ID_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_BY_ID_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
        employeeGuarantorState: action.payload,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_BY_ID_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.COMPLETE_DELETE_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.COMPLETE_DELETE_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.COMPLETE_DELETE_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.EMPTY_TRASH_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.EMPTY_TRASH_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.EMPTY_TRASH_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.EXPORT_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.EXPORT_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
        // employeeGuarantorState: action.payload,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.EXPORT_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ALL_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ALL_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ALL_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_EMPLOYEE_GUARANTOR_BY_ID***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_EMPLOYEE_GUARANTOR_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_EMPLOYEE_GUARANTOR_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_EMPLOYEE_GUARANTOR_BY_ID_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************RECOVER_EMPLOYEE_GUARANTOR_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.TRASH_ALL_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.TRASH_ALL_EMPLOYEE_GUARANTOR_SUCCESS:
      return { ...state };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.TRASH_ALL_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
        employeeGuarantorState: [...action.payload],
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_TRASHED_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.ARCHIVE_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.ARCHIVE_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.ARCHIVE_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
        employeeGuarantorState: [...action.payload],
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.GET_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_SUCCESS:
      return {
        ...state,
        // employeeGuarantorState: [...action.payload],
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ALL_ARCHIVED_EMPLOYEE_GUARANTOR_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_SUCCESS:
      return {
        ...state,
        employeeGuarantorState: [...action.payload],
      };
    case EMPLOYEE_GUARANTOR_ACTION_TYPES.RECOVER_ARCHIVED_EMPLOYEE_GUARANTOR_BY_ID_FAILURE:
      return {
        employeeGuarantorErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default EMPLOYEE_GUARANTOR_REDUCER;
