import STORE_ACTION_TYPES from "../actionTypes/storeActionTypes";

const initialState = {
  isLoading: false,
  storeState: [],
  storeErrorState: "",
};

const STORE_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case STORE_ACTION_TYPES.CREATE_STORE_SUCCESS:
      return {
        ...state,
      };
    // case STORE_ACTION_TYPES.CREATE_STORE_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case STORE_ACTION_TYPES.CREATE_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case STORE_ACTION_TYPES.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        storeState: state.storeState.map((str) =>
          str.id === action.payload ? action.payload : str
        ),
      };
    case STORE_ACTION_TYPES.UPDATE_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case STORE_ACTION_TYPES.DELETE_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.DELETE_STORE_SUCCESS:
      return {
        ...state,
      };
    case STORE_ACTION_TYPES.DELETE_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case STORE_ACTION_TYPES.GET_ALL_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.GET_ALL_STORE_SUCCESS:
      return { ...state, storeState: [...action.payload] };
    case STORE_ACTION_TYPES.GET_ALL_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case STORE_ACTION_TYPES.GET_BY_ID_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.GET_BY_ID_STORE_SUCCESS:
      return {
        ...state,
        storeState: action.payload,
      };
    case STORE_ACTION_TYPES.GET_BY_ID_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case STORE_ACTION_TYPES.COMPLETE_DELETE_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.COMPLETE_DELETE_STORE_SUCCESS:
      return {
        ...state,
      };
    case STORE_ACTION_TYPES.COMPLETE_DELETE_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case STORE_ACTION_TYPES.EMPTY_TRASH_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.EMPTY_TRASH_STORE_SUCCESS:
      return {
        ...state,
      };
    case STORE_ACTION_TYPES.EMPTY_TRASH_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case STORE_ACTION_TYPES.EXPORT_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.EXPORT_STORE_SUCCESS:
      return {
        ...state,
        // storeState: action.payload,
      };
    case STORE_ACTION_TYPES.EXPORT_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case STORE_ACTION_TYPES.RECOVER_ALL_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.RECOVER_ALL_STORE_SUCCESS:
      return {
        ...state,
      };
    case STORE_ACTION_TYPES.RECOVER_ALL_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_STORE_BY_ID***********************************************/
    // case STORE_ACTION_TYPES.RECOVER_STORE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.RECOVER_STORE_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case STORE_ACTION_TYPES.RECOVER_STORE_BY_ID_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************RECOVER_STORE_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case STORE_ACTION_TYPES.TRASH_ALL_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.TRASH_ALL_STORE_SUCCESS:
      return { ...state };
    case STORE_ACTION_TYPES.TRASH_ALL_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case STORE_ACTION_TYPES.GET_ALL_TRASHED_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.GET_ALL_TRASHED_STORE_SUCCESS:
      return {
        ...state,
        storeState: [...action.payload],
      };
    case STORE_ACTION_TYPES.GET_ALL_TRASHED_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case STORE_ACTION_TYPES.ARCHIVE_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.ARCHIVE_STORE_SUCCESS:
      return {
        ...state,
      };
    case STORE_ACTION_TYPES.ARCHIVE_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case STORE_ACTION_TYPES.GET_ALL_ARCHIVED_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.GET_ALL_ARCHIVED_STORE_SUCCESS:
      return {
        ...state,
        storeState: [...action.payload],
      };
    case STORE_ACTION_TYPES.GET_ALL_ARCHIVED_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case STORE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_STORE_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_STORE_SUCCESS:
      return {
        ...state,
        // storeState: [...action.payload],
      };
    case STORE_ACTION_TYPES.RECOVER_ALL_ARCHIVED_STORE_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case STORE_ACTION_TYPES.RECOVER_ARCHIVED_STORE_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case STORE_ACTION_TYPES.RECOVER_ARCHIVED_STORE_BY_ID_SUCCESS:
      return {
        ...state,
        storeState: [...action.payload],
      };
    case STORE_ACTION_TYPES.RECOVER_ARCHIVED_STORE_BY_ID_FAILURE:
      return {
        storeErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default STORE_REDUCER;
