const USER_ACTION_TYPES = {
  /************************************************************************* */
  AUTHENTICATE_USER_REQUEST: "AUTHENTICATE_USER_REQUEST",
  AUTHENTICATE_USER_SUCCUSS: "AUTHENTICATE_USER_SUCCUSS",
  AUTHENTICATE_USER_ERROR: "AUTHENTICATE_USER_ERROR",
  /************************************************************************* */
  USER_LOG_OUT: "USER_LOG_OUT",
  /************************************************************************* */
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_ERROR: "GET_ALL_USERS_ERROR",
  /************************************************************************* */
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_REQUEST: "REGISTER_USER_REQUEST",
  REGISTER_USER_ERROR: "REGISTER_USER_ERROR",

  /************************************************************************* */
  GET_USER_INFO_BY_ID_SUCCESS: "GET_USER_INFO_BY_ID_SUCCESS",
  GET_USER_INFO_BY_ID_REQUEST: "GET_USER_INFO_BY_ID_REQUEST",
  GET_USER_INFO_BY_ID_ERROR: "GET_USER_INFO_BY_ID_ERROR",

  /************************************************************************* */
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_ERROR: "REFRESH_TOKEN_ERROR",

  /************************************************************************* */
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_ERROR: "VERIFY_EMAIL_ERROR",

  /************************************************************************* */
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",

  /************************************************************************* */
  VALIDATE_RESET_TOKEN_SUCCESS: "VALIDATE_RESET_TOKEN_SUCCESS",
  VALIDATE_RESET_TOKEN_REQUEST: "VALIDATE_RESET_TOKEN_REQUEST",
  VALIDATE_RESET_TOKEN_ERROR: "VALIDATE_RESET_TOKEN_ERROR",

  /************************************************************************* */
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  /************************************************************************* */

  /************************************************************************* */
  /************************************************************************* */
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",

  /************************************************************************* */

  GET_ALL_USER_REQUEST: "GET_ALL_USER_REQUEST",
  GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS",
  GET_ALL_USER_FAILURE: "GET_ALL_USER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */

  GET_ALL_USER_DETAIL_REQUEST: "GET_ALL_USER_DETAIL_REQUEST",
  GET_ALL_USER_DETAIL_SUCCESS: "GET_ALL_USER_DETAIL_SUCCESS",
  GET_ALL_USER_DETAIL_FAILURE: "GET_ALL_USER_DETAIL_FAILURE",

  /************************************************************************* */
  GET_BY_ID_USER_REQUEST: "GET_BY_ID_USER_REQUEST",
  GET_BY_ID_USER_SUCCESS: "GET_BY_ID_USER_SUCCESS",
  GET_BY_ID_USER_FAILURE: "GET_BY_ID_USER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  GET_BY_ID_USER_DETAIL_REQUEST: "GET_BY_ID_USER_DETAIL_REQUEST",
  GET_BY_ID_USER_DETAIL_SUCCESS: "GET_BY_ID_USER_DETAIL_SUCCESS",
  GET_BY_ID_USER_DETAIL_FAILURE: "GET_BY_ID_USER_DETAIL_FAILURE",

  /************************************************************************* */
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  /************************************************************************* */
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  /************************************************************************* */
  /************************************************************************* */
  CHANGE_USER_PASSWORD_REQUEST: "CHANGE_USER_PASSWORD_REQUEST",
  CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",
  CHANGE_USER_PASSWORD_FAILURE: "CHANGE_USER_PASSWORD_FAILURE",

  /************************************************************************* */
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_USER_BY_ID_REQUEST: "RECOVER_BY_ID_USER_BY_ID_REQUEST",
  RECOVER_USER_BY_ID_SUCCESS: "RECOVER_USER_BY_ID_SUCCESS",
  RECOVER_USER_BY_ID_FAILURE: "RECOVER_USER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_USER_REQUEST: "RECOVER_ALL_USER_REQUEST",
  RECOVER_ALL_USER_SUCCESS: "RECOVER_ALL_USER_SUCCESS",
  RECOVER_ALL_USER_FAILURE: "RECOVER_ALL_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_USER_REQUEST: "COMPLETE_DELETE_USER_REQUEST",
  COMPLETE_DELETE_USER_SUCCESS: "COMPLETE_DELETE_USER_SUCCESS",
  COMPLETE_DELETE_USER_FAILURE: "COMPLETE_DELETE_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_USER_REQUEST: "TRASH_ALL_USER_REQUEST",
  TRASH_ALL_USER_SUCCESS: "TRASH_ALL_USER_SUCCESS",
  TRASH_ALL_USER_FAILURE: "TRASH_ALL_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_USER_REQUEST: "EMPTY_TRASH_USER_REQUEST",
  EMPTY_TRASH_USER_SUCCESS: "EMPTY_TRASH_USER_SUCCESS",
  EMPTY_TRASH_USER_FAILURE: "EMPTY_TRASH_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_USER_REQUEST: "EXPORT_USER_REQUEST",
  EXPORT_USER_SUCCESS: "EXPORT_USER_SUCCESS",
  EXPORT_USER_FAILURE: "EXPORT_USER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_USER_REQUEST: "GET_ALL_TRASHED_USER_REQUEST",
  GET_ALL_TRASHED_USER_SUCCESS: "GET_ALL_TRASHED_USER_SUCCESS",
  GET_ALL_TRASHED_USER_FAILURE: "GET_ALL_TRASHED_USER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_USER_REQUEST: "ARCHIVE_USER_REQUEST",
  ARCHIVE_USER_SUCCESS: "ARCHIVE_USER_SUCCESS",
  ARCHIVE_USER_FAILURE: "ARCHIVE_USER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_USER_REQUEST: "GET_ALL_ARCHIVED_USER_REQUEST",
  GET_ALL_ARCHIVED_USER_SUCCESS: "GET_ALL_ARCHIVED_USER_SUCCESS",
  GET_ALL_ARCHIVED_USER_FAILURE: "GET_ALL_ARCHIVED_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_USER_REQUEST: "RECOVER_ALL_ARCHIVED_USER_REQUEST",
  RECOVER_ALL_ARCHIVED_USER_SUCCESS: "RECOVER_ALL_ARCHIVED_USER_SUCCESS",
  RECOVER_ALL_ARCHIVED_USER_FAILURE: "RECOVER_ALL_ARCHIVED_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_USER_BY_ID_REQUEST: "RECOVER_ARCHIVED_USER_BY_ID_REQUEST",
  RECOVER_ARCHIVED_USER_BY_ID_SUCCESS: "RECOVER_ARCHIVED_USER_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_USER_BY_ID_FAILURE: "RECOVER_ARCHIVED_USER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_NEW_USERS_REQUEST: "GET_ALL_NEW_USERS_REQUEST",
  GET_ALL_NEW_USERS_SUCCESS: "GET_ALL_NEW_USERS_SUCCESS",
  GET_ALL_NEW_USERS_FAILURE: "GET_ALL_NEW_USERS_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  CREATE_USER_ROLE_REQUEST: "CREATE_USER_ROLE_REQUEST",
  CREATE_USER_ROLE_SUCCESS: "CREATE_USER_ROLE_SUCCESS",
  CREATE_USER_ROLE_FAILURE: "CREATE_USER_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  UPDATE_USER_ROLE_REQUEST: "UPDATE_USER_ROLE_REQUEST",
  UPDATE_USER_ROLE_SUCCESS: "UPDATE_USER_ROLE_SUCCESS",
  UPDATE_USER_ROLE_FAILURE: "UPDATE_USER_ROLE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_USER_ROLES_BY_USER_TYPE_REQUEST: "GET_USER_ROLES_BY_USER_TYPE_REQUEST",
  GET_USER_ROLES_BY_USER_TYPE_SUCCESS: "GET_USER_ROLES_BY_USER_TYPE_SUCCESS",
  GET_USER_ROLES_BY_USER_TYPE_FAILURE: "GET_USER_ROLES_BY_USER_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_RECENTLY_LOGGEDIN_USER_REQUEST:
    "GET_ALL_RECENTLY_LOGGEDIN_USER_REQUEST",
  GET_ALL_RECENTLY_LOGGEDIN_USER_SUCCESS:
    "GET_ALL_RECENTLY_LOGGEDIN_USER_SUCCESS",
  GET_ALL_RECENTLY_LOGGEDIN_USER_FAILURE:
    "GET_ALL_RECENTLY_LOGGEDIN_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  GET_ALL_BLOCKED_USER_REQUEST: "GET_ALL_BLOCKED_USER_REQUEST",
  GET_ALL_BLOCKED_USER_SUCCESS: "GET_ALL_BLOCKED_USER_SUCCESS",
  GET_ALL_BLOCKED_USER_FAILURE: "GET_ALL_BLOCKED_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  UN_BLOCK_USER_REQUEST: "UN_BLOCK_USER_REQUEST",
  UN_BLOCK_USER_SUCCESS: "UN_BLOCK_USER_SUCCESS",
  UN_BLOCK_USER_FAILURE: "UN_BLOCK_USER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  BLOCK_USER_REQUEST: "BLOCK_USER_REQUEST",
  BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
  BLOCK_USER_FAILURE: "BLOCK_USER_FAILURE",
  /************************************************************************* */
};

export default USER_ACTION_TYPES;
