import WOREDA_ACTION_TYPES from "../actionTypes/woredaActionTypes";

const initialState = {
  isLoading: false,
  woredaState: [],
  woredaErrorState: "",
};

const WOREDA_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case WOREDA_ACTION_TYPES.CREATE_WOREDA_SUCCESS:
      return {
        ...state,
      };
    // case WOREDA_ACTION_TYPES.CREATE_WOREDA_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case WOREDA_ACTION_TYPES.CREATE_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case WOREDA_ACTION_TYPES.UPDATE_WOREDA_SUCCESS:
      return {
        ...state,
        woredaState: state.woredaState.map((wr) =>
          wr.id === action.payload ? action.payload : wr
        ),
      };
    case WOREDA_ACTION_TYPES.UPDATE_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case WOREDA_ACTION_TYPES.DELETE_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.DELETE_WOREDA_SUCCESS:
      return {
        ...state,
      };
    case WOREDA_ACTION_TYPES.DELETE_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case WOREDA_ACTION_TYPES.GET_ALL_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.GET_ALL_WOREDA_SUCCESS:
      return { ...state, woredaState: [...action.payload] };
    case WOREDA_ACTION_TYPES.GET_ALL_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case WOREDA_ACTION_TYPES.GET_BY_ID_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.GET_BY_ID_WOREDA_SUCCESS:
      return {
        ...state,
        woredaState: action.payload,
      };
    case WOREDA_ACTION_TYPES.GET_BY_ID_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case WOREDA_ACTION_TYPES.COMPLETE_DELETE_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.COMPLETE_DELETE_WOREDA_SUCCESS:
      return {
        ...state,
      };
    case WOREDA_ACTION_TYPES.COMPLETE_DELETE_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case WOREDA_ACTION_TYPES.EMPTY_TRASH_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.EMPTY_TRASH_WOREDA_SUCCESS:
      return {
        ...state,
      };
    case WOREDA_ACTION_TYPES.EMPTY_TRASH_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case WOREDA_ACTION_TYPES.EXPORT_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.EXPORT_WOREDA_SUCCESS:
      return {
        ...state,
        // woredaState: action.payload,
      };
    case WOREDA_ACTION_TYPES.EXPORT_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case WOREDA_ACTION_TYPES.RECOVER_ALL_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.RECOVER_ALL_WOREDA_SUCCESS:
      return {
        ...state,
      };
    case WOREDA_ACTION_TYPES.RECOVER_ALL_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_WOREDA_BY_ID***********************************************/
    // case WOREDA_ACTION_TYPES.RECOVER_WOREDA_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.RECOVER_WOREDA_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case WOREDA_ACTION_TYPES.RECOVER_WOREDA_BY_ID_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************RECOVER_WOREDA_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case WOREDA_ACTION_TYPES.TRASH_ALL_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.TRASH_ALL_WOREDA_SUCCESS:
      return { ...state };
    case WOREDA_ACTION_TYPES.TRASH_ALL_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case WOREDA_ACTION_TYPES.GET_ALL_TRASHED_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.GET_ALL_TRASHED_WOREDA_SUCCESS:
      return {
        ...state,
        woredaState: [...action.payload],
      };
    case WOREDA_ACTION_TYPES.GET_ALL_TRASHED_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case WOREDA_ACTION_TYPES.ARCHIVE_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.ARCHIVE_WOREDA_SUCCESS:
      return {
        ...state,
      };
    case WOREDA_ACTION_TYPES.ARCHIVE_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case WOREDA_ACTION_TYPES.GET_ALL_ARCHIVED_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.GET_ALL_ARCHIVED_WOREDA_SUCCESS:
      return {
        ...state,
        woredaState: [...action.payload],
      };
    case WOREDA_ACTION_TYPES.GET_ALL_ARCHIVED_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case WOREDA_ACTION_TYPES.RECOVER_ALL_ARCHIVED_WOREDA_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.RECOVER_ALL_ARCHIVED_WOREDA_SUCCESS:
      return {
        ...state,
        // woredaState: [...action.payload],
      };
    case WOREDA_ACTION_TYPES.RECOVER_ALL_ARCHIVED_WOREDA_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case WOREDA_ACTION_TYPES.RECOVER_ARCHIVED_WOREDA_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case WOREDA_ACTION_TYPES.RECOVER_ARCHIVED_WOREDA_BY_ID_SUCCESS:
      return {
        ...state,
        woredaState: [...action.payload],
      };
    case WOREDA_ACTION_TYPES.RECOVER_ARCHIVED_WOREDA_BY_ID_FAILURE:
      return {
        woredaErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default WOREDA_REDUCER;
