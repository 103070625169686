const MATERIAL_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_MATERIAL_REQUEST: "GET_ALL_MATERIAL_REQUEST",
  GET_ALL_MATERIAL_SUCCESS: "GET_ALL_MATERIAL_SUCCESS",
  GET_ALL_MATERIAL_FAILURE: "GET_ALL_MATERIAL_FAILURE",

  /************************************************************************* */
  GET_BY_ID_MATERIAL_REQUEST: "GET_BY_ID_MATERIAL_REQUEST",
  GET_BY_ID_MATERIAL_SUCCESS: "GET_BY_ID_MATERIAL_SUCCESS",
  GET_BY_ID_MATERIAL_FAILURE: "GET_BY_ID_MATERIAL_FAILURE",

  /************************************************************************* */
  CREATE_MATERIAL_REQUEST: "CREATE_MATERIAL_REQUEST",
  CREATE_MATERIAL_SUCCESS: "CREATE_MATERIAL_SUCCESS",
  CREATE_MATERIAL_FAILURE: "CREATE_MATERIAL_FAILURE",

  /************************************************************************* */
  UPDATE_MATERIAL_REQUEST: "UPDATE_MATERIAL_REQUEST",
  UPDATE_MATERIAL_SUCCESS: "UPDATE_MATERIAL_SUCCESS",
  UPDATE_MATERIAL_FAILURE: "UPDATE_MATERIAL_FAILURE",

  /************************************************************************* */
  DELETE_MATERIAL_REQUEST: "DELETE_MATERIAL_REQUEST",
  DELETE_MATERIAL_SUCCESS: "DELETE_MATERIAL_SUCCESS",
  DELETE_MATERIAL_FAILURE: "DELETE_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_MATERIAL_BY_ID_REQUEST: "RECOVER_BY_ID_MATERIAL_BY_ID_REQUEST",
  RECOVER_MATERIAL_BY_ID_SUCCESS: "RECOVER_MATERIAL_BY_ID_SUCCESS",
  RECOVER_MATERIAL_BY_ID_FAILURE: "RECOVER_MATERIAL_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_MATERIAL_REQUEST: "RECOVER_ALL_MATERIAL_REQUEST",
  RECOVER_ALL_MATERIAL_SUCCESS: "RECOVER_ALL_MATERIAL_SUCCESS",
  RECOVER_ALL_MATERIAL_FAILURE: "RECOVER_ALL_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_MATERIAL_REQUEST: "COMPLETE_DELETE_MATERIAL_REQUEST",
  COMPLETE_DELETE_MATERIAL_SUCCESS: "COMPLETE_DELETE_MATERIAL_SUCCESS",
  COMPLETE_DELETE_MATERIAL_FAILURE: "COMPLETE_DELETE_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_MATERIAL_REQUEST: "TRASH_ALL_MATERIAL_REQUEST",
  TRASH_ALL_MATERIAL_SUCCESS: "TRASH_ALL_MATERIAL_SUCCESS",
  TRASH_ALL_MATERIAL_FAILURE: "TRASH_ALL_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_MATERIAL_REQUEST: "EMPTY_TRASH_MATERIAL_REQUEST",
  EMPTY_TRASH_MATERIAL_SUCCESS: "EMPTY_TRASH_MATERIAL_SUCCESS",
  EMPTY_TRASH_MATERIAL_FAILURE: "EMPTY_TRASH_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_MATERIAL_REQUEST: "EXPORT_MATERIAL_REQUEST",
  EXPORT_MATERIAL_SUCCESS: "EXPORT_MATERIAL_SUCCESS",
  EXPORT_MATERIAL_FAILURE: "EXPORT_MATERIAL_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_MATERIAL_REQUEST: "GET_ALL_TRASHED_MATERIAL_REQUEST",
  GET_ALL_TRASHED_MATERIAL_SUCCESS: "GET_ALL_TRASHED_MATERIAL_SUCCESS",
  GET_ALL_TRASHED_MATERIAL_FAILURE: "GET_ALL_TRASHED_MATERIAL_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_MATERIAL_REQUEST: "ARCHIVE_MATERIAL_REQUEST",
  ARCHIVE_MATERIAL_SUCCESS: "ARCHIVE_MATERIAL_SUCCESS",
  ARCHIVE_MATERIAL_FAILURE: "ARCHIVE_MATERIAL_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_MATERIAL_REQUEST: "GET_ALL_ARCHIVED_MATERIAL_REQUEST",
  GET_ALL_ARCHIVED_MATERIAL_SUCCESS: "GET_ALL_ARCHIVED_MATERIAL_SUCCESS",
  GET_ALL_ARCHIVED_MATERIAL_FAILURE: "GET_ALL_ARCHIVED_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_MATERIAL_REQUEST:
    "RECOVER_ALL_ARCHIVED_MATERIAL_REQUEST",
  RECOVER_ALL_ARCHIVED_MATERIAL_SUCCESS:
    "RECOVER_ALL_ARCHIVED_MATERIAL_SUCCESS",
  RECOVER_ALL_ARCHIVED_MATERIAL_FAILURE:
    "RECOVER_ALL_ARCHIVED_MATERIAL_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_MATERIAL_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_MATERIAL_BY_ID_REQUEST",
  RECOVER_ARCHIVED_MATERIAL_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_MATERIAL_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_MATERIAL_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_MATERIAL_BY_ID_FAILURE",
  /************************************************************************* */
};

export default MATERIAL_ACTION_TYPES;
