import CITY_ACTION_TYPES from "../actionTypes/cityActionTypes";

const initialState = {
  isLoading: false,
  cityState: [],
  cityErrorState: "",
};

const CITY_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case CITY_ACTION_TYPES.CREATE_CITY_SUCCESS:
      return {
        ...state,
      };
    // case CITY_ACTION_TYPES.CREATE_CITY_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case CITY_ACTION_TYPES.CREATE_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case CITY_ACTION_TYPES.UPDATE_CITY_SUCCESS:
      return {
        ...state,
        cityState: state.cityState.map((ct) =>
          ct.id === action.payload ? action.payload : ct
        ),
      };
    case CITY_ACTION_TYPES.UPDATE_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case CITY_ACTION_TYPES.DELETE_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.DELETE_CITY_SUCCESS:
      return {
        ...state,
      };
    case CITY_ACTION_TYPES.DELETE_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case CITY_ACTION_TYPES.GET_ALL_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.GET_ALL_CITY_SUCCESS:
      return { ...state, cityState: [...action.payload] };
    case CITY_ACTION_TYPES.GET_ALL_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case CITY_ACTION_TYPES.GET_BY_ID_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.GET_BY_ID_CITY_SUCCESS:
      return {
        ...state,
        cityState: action.payload,
      };
    case CITY_ACTION_TYPES.GET_BY_ID_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case CITY_ACTION_TYPES.COMPLETE_DELETE_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.COMPLETE_DELETE_CITY_SUCCESS:
      return {
        ...state,
      };
    case CITY_ACTION_TYPES.COMPLETE_DELETE_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case CITY_ACTION_TYPES.EMPTY_TRASH_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.EMPTY_TRASH_CITY_SUCCESS:
      return {
        ...state,
      };
    case CITY_ACTION_TYPES.EMPTY_TRASH_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case CITY_ACTION_TYPES.EXPORT_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.EXPORT_CITY_SUCCESS:
      return {
        ...state,
        // cityState: action.payload,
      };
    case CITY_ACTION_TYPES.EXPORT_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case CITY_ACTION_TYPES.RECOVER_ALL_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.RECOVER_ALL_CITY_SUCCESS:
      return {
        ...state,
      };
    case CITY_ACTION_TYPES.RECOVER_ALL_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_CITY_BY_ID***********************************************/
    // case CITY_ACTION_TYPES.RECOVER_CITY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.RECOVER_CITY_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case CITY_ACTION_TYPES.RECOVER_CITY_BY_ID_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************RECOVER_CITY_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case CITY_ACTION_TYPES.TRASH_ALL_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.TRASH_ALL_CITY_SUCCESS:
      return { ...state };
    case CITY_ACTION_TYPES.TRASH_ALL_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case CITY_ACTION_TYPES.GET_ALL_TRASHED_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.GET_ALL_TRASHED_CITY_SUCCESS:
      return {
        ...state,
        cityState: [...action.payload],
      };
    case CITY_ACTION_TYPES.GET_ALL_TRASHED_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case CITY_ACTION_TYPES.ARCHIVE_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.ARCHIVE_CITY_SUCCESS:
      return {
        ...state,
      };
    case CITY_ACTION_TYPES.ARCHIVE_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case CITY_ACTION_TYPES.GET_ALL_ARCHIVED_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.GET_ALL_ARCHIVED_CITY_SUCCESS:
      return {
        ...state,
        cityState: [...action.payload],
      };
    case CITY_ACTION_TYPES.GET_ALL_ARCHIVED_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case CITY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_CITY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_CITY_SUCCESS:
      return {
        ...state,
        // cityState: [...action.payload],
      };
    case CITY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_CITY_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case CITY_ACTION_TYPES.RECOVER_ARCHIVED_CITY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case CITY_ACTION_TYPES.RECOVER_ARCHIVED_CITY_BY_ID_SUCCESS:
      return {
        ...state,
        cityState: [...action.payload],
      };
    case CITY_ACTION_TYPES.RECOVER_ARCHIVED_CITY_BY_ID_FAILURE:
      return {
        cityErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default CITY_REDUCER;
