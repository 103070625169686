const ORGANIZATION_TYPE_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_ORGANIZATION_TYPE_REQUEST: "GET_ALL_ORGANIZATION_TYPE_REQUEST",
  GET_ALL_ORGANIZATION_TYPE_SUCCESS: "GET_ALL_ORGANIZATION_TYPE_SUCCESS",
  GET_ALL_ORGANIZATION_TYPE_FAILURE: "GET_ALL_ORGANIZATION_TYPE_FAILURE",

  /************************************************************************* */
  GET_BY_ID_ORGANIZATION_TYPE_REQUEST: "GET_BY_ID_ORGANIZATION_TYPE_REQUEST",
  GET_BY_ID_ORGANIZATION_TYPE_SUCCESS: "GET_BY_ID_ORGANIZATION_TYPE_SUCCESS",
  GET_BY_ID_ORGANIZATION_TYPE_FAILURE: "GET_BY_ID_ORGANIZATION_TYPE_FAILURE",

  /************************************************************************* */
  CREATE_ORGANIZATION_TYPE_REQUEST: "CREATE_ORGANIZATION_TYPE_REQUEST",
  CREATE_ORGANIZATION_TYPE_SUCCESS: "CREATE_ORGANIZATION_TYPE_SUCCESS",
  CREATE_ORGANIZATION_TYPE_FAILURE: "CREATE_ORGANIZATION_TYPE_FAILURE",

  /************************************************************************* */
  UPDATE_ORGANIZATION_TYPE_REQUEST: "UPDATE_ORGANIZATION_TYPE_REQUEST",
  UPDATE_ORGANIZATION_TYPE_SUCCESS: "UPDATE_ORGANIZATION_TYPE_SUCCESS",
  UPDATE_ORGANIZATION_TYPE_FAILURE: "UPDATE_ORGANIZATION_TYPE_FAILURE",

  /************************************************************************* */
  DELETE_ORGANIZATION_TYPE_REQUEST: "DELETE_ORGANIZATION_TYPE_REQUEST",
  DELETE_ORGANIZATION_TYPE_SUCCESS: "DELETE_ORGANIZATION_TYPE_SUCCESS",
  DELETE_ORGANIZATION_TYPE_FAILURE: "DELETE_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ORGANIZATION_TYPE_BY_ID_REQUEST:
    "RECOVER_BY_ID_ORGANIZATION_TYPE_BY_ID_REQUEST",
  RECOVER_ORGANIZATION_TYPE_BY_ID_SUCCESS:
    "RECOVER_ORGANIZATION_TYPE_BY_ID_SUCCESS",
  RECOVER_ORGANIZATION_TYPE_BY_ID_FAILURE:
    "RECOVER_ORGANIZATION_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ORGANIZATION_TYPE_REQUEST:
    "RECOVER_ALL_ORGANIZATION_TYPE_REQUEST",
  RECOVER_ALL_ORGANIZATION_TYPE_SUCCESS:
    "RECOVER_ALL_ORGANIZATION_TYPE_SUCCESS",
  RECOVER_ALL_ORGANIZATION_TYPE_FAILURE:
    "RECOVER_ALL_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_ORGANIZATION_TYPE_REQUEST:
    "COMPLETE_DELETE_ORGANIZATION_TYPE_REQUEST",
  COMPLETE_DELETE_ORGANIZATION_TYPE_SUCCESS:
    "COMPLETE_DELETE_ORGANIZATION_TYPE_SUCCESS",
  COMPLETE_DELETE_ORGANIZATION_TYPE_FAILURE:
    "COMPLETE_DELETE_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_ORGANIZATION_TYPE_REQUEST: "TRASH_ALL_ORGANIZATION_TYPE_REQUEST",
  TRASH_ALL_ORGANIZATION_TYPE_SUCCESS: "TRASH_ALL_ORGANIZATION_TYPE_SUCCESS",
  TRASH_ALL_ORGANIZATION_TYPE_FAILURE: "TRASH_ALL_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_ORGANIZATION_TYPE_REQUEST:
    "EMPTY_TRASH_ORGANIZATION_TYPE_REQUEST",
  EMPTY_TRASH_ORGANIZATION_TYPE_SUCCESS:
    "EMPTY_TRASH_ORGANIZATION_TYPE_SUCCESS",
  EMPTY_TRASH_ORGANIZATION_TYPE_FAILURE:
    "EMPTY_TRASH_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_ORGANIZATION_TYPE_REQUEST: "EXPORT_ORGANIZATION_TYPE_REQUEST",
  EXPORT_ORGANIZATION_TYPE_SUCCESS: "EXPORT_ORGANIZATION_TYPE_SUCCESS",
  EXPORT_ORGANIZATION_TYPE_FAILURE: "EXPORT_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_ORGANIZATION_TYPE_REQUEST:
    "GET_ALL_TRASHED_ORGANIZATION_TYPE_REQUEST",
  GET_ALL_TRASHED_ORGANIZATION_TYPE_SUCCESS:
    "GET_ALL_TRASHED_ORGANIZATION_TYPE_SUCCESS",
  GET_ALL_TRASHED_ORGANIZATION_TYPE_FAILURE:
    "GET_ALL_TRASHED_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_ORGANIZATION_TYPE_REQUEST: "ARCHIVE_ORGANIZATION_TYPE_REQUEST",
  ARCHIVE_ORGANIZATION_TYPE_SUCCESS: "ARCHIVE_ORGANIZATION_TYPE_SUCCESS",
  ARCHIVE_ORGANIZATION_TYPE_FAILURE: "ARCHIVE_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_ORGANIZATION_TYPE_REQUEST:
    "GET_ALL_ARCHIVED_ORGANIZATION_TYPE_REQUEST",
  GET_ALL_ARCHIVED_ORGANIZATION_TYPE_SUCCESS:
    "GET_ALL_ARCHIVED_ORGANIZATION_TYPE_SUCCESS",
  GET_ALL_ARCHIVED_ORGANIZATION_TYPE_FAILURE:
    "GET_ALL_ARCHIVED_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_ORGANIZATION_TYPE_REQUEST:
    "RECOVER_ALL_ARCHIVED_ORGANIZATION_TYPE_REQUEST",
  RECOVER_ALL_ARCHIVED_ORGANIZATION_TYPE_SUCCESS:
    "RECOVER_ALL_ARCHIVED_ORGANIZATION_TYPE_SUCCESS",
  RECOVER_ALL_ARCHIVED_ORGANIZATION_TYPE_FAILURE:
    "RECOVER_ALL_ARCHIVED_ORGANIZATION_TYPE_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_ORGANIZATION_TYPE_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_ORGANIZATION_TYPE_BY_ID_REQUEST",
  RECOVER_ARCHIVED_ORGANIZATION_TYPE_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_ORGANIZATION_TYPE_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_ORGANIZATION_TYPE_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_ORGANIZATION_TYPE_BY_ID_FAILURE",
  /************************************************************************* */
};

export default ORGANIZATION_TYPE_ACTION_TYPES;
