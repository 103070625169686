const SHELF_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_SHELF_REQUEST: "GET_ALL_SHELF_REQUEST",
  GET_ALL_SHELF_SUCCESS: "GET_ALL_SHELF_SUCCESS",
  GET_ALL_SHELF_FAILURE: "GET_ALL_SHELF_FAILURE",

  /************************************************************************* */
  GET_BY_ID_SHELF_REQUEST: "GET_BY_ID_SHELF_REQUEST",
  GET_BY_ID_SHELF_SUCCESS: "GET_BY_ID_SHELF_SUCCESS",
  GET_BY_ID_SHELF_FAILURE: "GET_BY_ID_SHELF_FAILURE",

  /************************************************************************* */
  CREATE_SHELF_REQUEST: "CREATE_SHELF_REQUEST",
  CREATE_SHELF_SUCCESS: "CREATE_SHELF_SUCCESS",
  CREATE_SHELF_FAILURE: "CREATE_SHELF_FAILURE",

  /************************************************************************* */
  UPDATE_SHELF_REQUEST: "UPDATE_SHELF_REQUEST",
  UPDATE_SHELF_SUCCESS: "UPDATE_SHELF_SUCCESS",
  UPDATE_SHELF_FAILURE: "UPDATE_SHELF_FAILURE",

  /************************************************************************* */
  DELETE_SHELF_REQUEST: "DELETE_SHELF_REQUEST",
  DELETE_SHELF_SUCCESS: "DELETE_SHELF_SUCCESS",
  DELETE_SHELF_FAILURE: "DELETE_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_SHELF_BY_ID_REQUEST: "RECOVER_BY_ID_SHELF_BY_ID_REQUEST",
  RECOVER_SHELF_BY_ID_SUCCESS: "RECOVER_SHELF_BY_ID_SUCCESS",
  RECOVER_SHELF_BY_ID_FAILURE: "RECOVER_SHELF_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_SHELF_REQUEST: "RECOVER_ALL_SHELF_REQUEST",
  RECOVER_ALL_SHELF_SUCCESS: "RECOVER_ALL_SHELF_SUCCESS",
  RECOVER_ALL_SHELF_FAILURE: "RECOVER_ALL_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_SHELF_REQUEST: "COMPLETE_DELETE_SHELF_REQUEST",
  COMPLETE_DELETE_SHELF_SUCCESS: "COMPLETE_DELETE_SHELF_SUCCESS",
  COMPLETE_DELETE_SHELF_FAILURE: "COMPLETE_DELETE_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_SHELF_REQUEST: "TRASH_ALL_SHELF_REQUEST",
  TRASH_ALL_SHELF_SUCCESS: "TRASH_ALL_SHELF_SUCCESS",
  TRASH_ALL_SHELF_FAILURE: "TRASH_ALL_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_SHELF_REQUEST: "EMPTY_TRASH_SHELF_REQUEST",
  EMPTY_TRASH_SHELF_SUCCESS: "EMPTY_TRASH_SHELF_SUCCESS",
  EMPTY_TRASH_SHELF_FAILURE: "EMPTY_TRASH_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_SHELF_REQUEST: "EXPORT_SHELF_REQUEST",
  EXPORT_SHELF_SUCCESS: "EXPORT_SHELF_SUCCESS",
  EXPORT_SHELF_FAILURE: "EXPORT_SHELF_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_SHELF_REQUEST: "GET_ALL_TRASHED_SHELF_REQUEST",
  GET_ALL_TRASHED_SHELF_SUCCESS: "GET_ALL_TRASHED_SHELF_SUCCESS",
  GET_ALL_TRASHED_SHELF_FAILURE: "GET_ALL_TRASHED_SHELF_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_SHELF_REQUEST: "ARCHIVE_SHELF_REQUEST",
  ARCHIVE_SHELF_SUCCESS: "ARCHIVE_SHELF_SUCCESS",
  ARCHIVE_SHELF_FAILURE: "ARCHIVE_SHELF_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_SHELF_REQUEST: "GET_ALL_ARCHIVED_SHELF_REQUEST",
  GET_ALL_ARCHIVED_SHELF_SUCCESS: "GET_ALL_ARCHIVED_SHELF_SUCCESS",
  GET_ALL_ARCHIVED_SHELF_FAILURE: "GET_ALL_ARCHIVED_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_SHELF_REQUEST: "RECOVER_ALL_ARCHIVED_SHELF_REQUEST",
  RECOVER_ALL_ARCHIVED_SHELF_SUCCESS: "RECOVER_ALL_ARCHIVED_SHELF_SUCCESS",
  RECOVER_ALL_ARCHIVED_SHELF_FAILURE: "RECOVER_ALL_ARCHIVED_SHELF_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_SHELF_BY_ID_REQUEST: "RECOVER_ARCHIVED_SHELF_BY_ID_REQUEST",
  RECOVER_ARCHIVED_SHELF_BY_ID_SUCCESS: "RECOVER_ARCHIVED_SHELF_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_SHELF_BY_ID_FAILURE: "RECOVER_ARCHIVED_SHELF_BY_ID_FAILURE",
  /************************************************************************* */
};

export default SHELF_ACTION_TYPES;
