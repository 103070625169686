import PERMISSION_CATEGORY_ACTION_TYPES from "../actionTypes/permissionCategoryActionTypes";

const initialState = {
  isLoading: false,
  permissionCategoryState: [],
  permissionCategoryErrorState: "",
};

const PERMISSION_CATEGORY_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case PERMISSION_CATEGORY_ACTION_TYPES.CREATE_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    // case PERMISSION_CATEGORY_ACTION_TYPES.CREATE_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.CREATE_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case PERMISSION_CATEGORY_ACTION_TYPES.UPDATE_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        permissionCategoryState: state.permissionCategoryState.map((pC) =>
          pC.id === action.payload ? action.payload : pC
        ),
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.UPDATE_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case PERMISSION_CATEGORY_ACTION_TYPES.DELETE_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.DELETE_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.DELETE_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_PERMISSION_CATEGORY_SUCCESS:
      return { ...state, permissionCategoryState: [...action.payload] };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.GET_BY_ID_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_BY_ID_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        permissionCategoryState: action.payload,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_BY_ID_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.COMPLETE_DELETE_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.COMPLETE_DELETE_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.COMPLETE_DELETE_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.EMPTY_TRASH_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.EMPTY_TRASH_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.EMPTY_TRASH_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.EXPORT_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.EXPORT_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        // permissionCategoryState: action.payload,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.EXPORT_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ALL_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ALL_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ALL_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_PERMISSION_CATEGORY_BY_ID***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_PERMISSION_CATEGORY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_PERMISSION_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_PERMISSION_CATEGORY_BY_ID_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************RECOVER_PERMISSION_CATEGORY_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.TRASH_ALL_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.TRASH_ALL_PERMISSION_CATEGORY_SUCCESS:
      return { ...state };
    case PERMISSION_CATEGORY_ACTION_TYPES.TRASH_ALL_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_TRASHED_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_TRASHED_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        permissionCategoryState: [...action.payload],
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_TRASHED_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.ARCHIVE_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.ARCHIVE_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.ARCHIVE_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** *///********************* GET_ALL_TRASHED***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_ARCHIVED_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_ARCHIVED_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        permissionCategoryState: [...action.payload],
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.GET_ALL_ARCHIVED_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        // permissionCategoryState: [...action.payload],
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** *///********************* GET_ALL_ARCHIVED***********************************************/
    // case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        permissionCategoryState: [...action.payload],
      };
    case PERMISSION_CATEGORY_ACTION_TYPES.RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_FAILURE:
      return {
        permissionCategoryErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default PERMISSION_CATEGORY_REDUCER;
