import ITEM_ACTION_TYPES from "../actionTypes/itemActionTypes";

const initialState = {
  isLoading: false,
  itemState: [],
  itemErrorState: "",
};

const ITEM_REDUCER = (state = initialState, action) => {
  switch (action.type) {
    //*******************************CREATE******************************************* */
    case ITEM_ACTION_TYPES.CREATE_ITEM_SUCCESS:
      return {
        ...state,
      };
    // case ITEM_ACTION_TYPES.CREATE_ITEM_REQUEST:
    //   return {
    //     isLoading: false,
    //   };
    case ITEM_ACTION_TYPES.CREATE_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //**********************************CREATE******************************************** */
    //********************************UPDATE******************************************** */
    case ITEM_ACTION_TYPES.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        itemState: state.itemState.map((i) =>
          i.id === action.payload ? action.payload : i
        ),
      };
    case ITEM_ACTION_TYPES.UPDATE_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*****************************UPDATE******************************************** */
    //*************************DELETE********************************************* */
    // case ITEM_ACTION_TYPES.DELETE_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.DELETE_ITEM_SUCCESS:
      return {
        ...state,
      };
    case ITEM_ACTION_TYPES.DELETE_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*************************DELETE********************************************* */
    // *****************************GET_ALL******************************************/

    // case ITEM_ACTION_TYPES.GET_ALL_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.GET_ALL_ITEM_SUCCESS:
      return { ...state, itemState: [...action.payload] };
    case ITEM_ACTION_TYPES.GET_ALL_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //******************************GET_ALL***************************************** */
    //********************* GET_BY_ID***********************************************/
    // case ITEM_ACTION_TYPES.GET_BY_ID_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.GET_BY_ID_ITEM_SUCCESS:
      return {
        ...state,
        itemState: action.payload,
      };
    case ITEM_ACTION_TYPES.GET_BY_ID_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************GET_BY_ID*********************************************** */
    //********************* COMPLETE_DELETE***********************************************/
    // case ITEM_ACTION_TYPES.COMPLETE_DELETE_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.COMPLETE_DELETE_ITEM_SUCCESS:
      return {
        ...state,
      };
    case ITEM_ACTION_TYPES.COMPLETE_DELETE_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************COMPLETE_DELETE*********************************************** */
    //********************* EMPTY_TRASH***********************************************/
    // case ITEM_ACTION_TYPES.EMPTY_TRASH_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.EMPTY_TRASH_ITEM_SUCCESS:
      return {
        ...state,
      };
    case ITEM_ACTION_TYPES.EMPTY_TRASH_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************EMPTY_TRASH*********************************************** */
    //********************* EXPORT***********************************************/
    // case ITEM_ACTION_TYPES.EXPORT_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.EXPORT_ITEM_SUCCESS:
      return {
        ...state,
        // itemState: action.payload,
      };
    case ITEM_ACTION_TYPES.EXPORT_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************EXPORT*********************************************** */
    //********************* RECOVER_ALL***********************************************/
    // case ITEM_ACTION_TYPES.RECOVER_ALL_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.RECOVER_ALL_ITEM_SUCCESS:
      return {
        ...state,
      };
    case ITEM_ACTION_TYPES.RECOVER_ALL_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************RECOVER_ALL*********************************************** */
    //********************* RECOVER_ITEM_BY_ID***********************************************/
    // case ITEM_ACTION_TYPES.RECOVER_ITEM_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.RECOVER_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
      };
    case ITEM_ACTION_TYPES.RECOVER_ITEM_BY_ID_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************RECOVER_ITEM_BY_ID*********************************************** */
    //********************* TRASH_ALL***********************************************/
    // case ITEM_ACTION_TYPES.TRASH_ALL_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.TRASH_ALL_ITEM_SUCCESS:
      return { ...state };
    case ITEM_ACTION_TYPES.TRASH_ALL_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************TRASH_ALL*********************************************** */

    //********************* GET_ALL_TRASHED***********************************************/
    // case ITEM_ACTION_TYPES.GET_ALL_TRASHED_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.GET_ALL_TRASHED_ITEM_SUCCESS:
      return {
        ...state,
        itemState: [...action.payload],
      };
    case ITEM_ACTION_TYPES.GET_ALL_TRASHED_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************GET_ALL_TRASHED*********************************************** */

    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ITEM_ACTION_TYPES.ARCHIVE_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.ARCHIVE_ITEM_SUCCESS:
      return {
        ...state,
      };
    case ITEM_ACTION_TYPES.ARCHIVE_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_TRASHED*********************************************** */
    //********************* GET_ALL_TRASHED***********************************************/
    // case ITEM_ACTION_TYPES.GET_ALL_ARCHIVED_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.GET_ALL_ARCHIVED_ITEM_SUCCESS:
      return {
        ...state,
        itemState: [...action.payload],
      };
    case ITEM_ACTION_TYPES.GET_ALL_ARCHIVED_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ITEM_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ITEM_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ITEM_SUCCESS:
      return {
        ...state,
        // itemState: [...action.payload],
      };
    case ITEM_ACTION_TYPES.RECOVER_ALL_ARCHIVED_ITEM_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    //*********************GET_ALL_ARCHIVED*********************************************** */
    //********************* GET_ALL_ARCHIVED***********************************************/
    // case ITEM_ACTION_TYPES.RECOVER_ARCHIVED_ITEM_BY_ID_REQUEST:
    //   return {
    //     isLoading: true,
    //   };
    case ITEM_ACTION_TYPES.RECOVER_ARCHIVED_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        itemState: [...action.payload],
      };
    case ITEM_ACTION_TYPES.RECOVER_ARCHIVED_ITEM_BY_ID_FAILURE:
      return {
        itemErrorState: action.payload,
      };
    //*********************RECOVER_ALL_ARCHIVED*********************************************** */
    default:
      return state;
  }
};

export default ITEM_REDUCER;
