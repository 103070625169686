const PERMISSION_CATEGORY_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_PERMISSION_CATEGORY_REQUEST: "GET_ALL_PERMISSION_CATEGORY_REQUEST",
  GET_ALL_PERMISSION_CATEGORY_SUCCESS: "GET_ALL_PERMISSION_CATEGORY_SUCCESS",
  GET_ALL_PERMISSION_CATEGORY_FAILURE: "GET_ALL_PERMISSION_CATEGORY_FAILURE",

  /************************************************************************* */
  GET_BY_ID_PERMISSION_CATEGORY_REQUEST:
    "GET_BY_ID_PERMISSION_CATEGORY_REQUEST",
  GET_BY_ID_PERMISSION_CATEGORY_SUCCESS:
    "GET_BY_ID_PERMISSION_CATEGORY_SUCCESS",
  GET_BY_ID_PERMISSION_CATEGORY_FAILURE:
    "GET_BY_ID_PERMISSION_CATEGORY_FAILURE",

  /************************************************************************* */
  CREATE_PERMISSION_CATEGORY_REQUEST: "CREATE_PERMISSION_CATEGORY_REQUEST",
  CREATE_PERMISSION_CATEGORY_SUCCESS: "CREATE_PERMISSION_CATEGORY_SUCCESS",
  CREATE_PERMISSION_CATEGORY_FAILURE: "CREATE_PERMISSION_CATEGORY_FAILURE",

  /************************************************************************* */
  UPDATE_PERMISSION_CATEGORY_REQUEST: "UPDATE_PERMISSION_CATEGORY_REQUEST",
  UPDATE_PERMISSION_CATEGORY_SUCCESS: "UPDATE_PERMISSION_CATEGORY_SUCCESS",
  UPDATE_PERMISSION_CATEGORY_FAILURE: "UPDATE_PERMISSION_CATEGORY_FAILURE",

  /************************************************************************* */
  DELETE_PERMISSION_CATEGORY_REQUEST: "DELETE_PERMISSION_CATEGORY_REQUEST",
  DELETE_PERMISSION_CATEGORY_SUCCESS: "DELETE_PERMISSION_CATEGORY_SUCCESS",
  DELETE_PERMISSION_CATEGORY_FAILURE: "DELETE_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_PERMISSION_CATEGORY_BY_ID_REQUEST:
    "RECOVER_BY_ID_PERMISSION_CATEGORY_BY_ID_REQUEST",
  RECOVER_PERMISSION_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_PERMISSION_CATEGORY_BY_ID_SUCCESS",
  RECOVER_PERMISSION_CATEGORY_BY_ID_FAILURE:
    "RECOVER_PERMISSION_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_PERMISSION_CATEGORY_REQUEST:
    "RECOVER_ALL_PERMISSION_CATEGORY_REQUEST",
  RECOVER_ALL_PERMISSION_CATEGORY_SUCCESS:
    "RECOVER_ALL_PERMISSION_CATEGORY_SUCCESS",
  RECOVER_ALL_PERMISSION_CATEGORY_FAILURE:
    "RECOVER_ALL_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_PERMISSION_CATEGORY_REQUEST:
    "COMPLETE_DELETE_PERMISSION_CATEGORY_REQUEST",
  COMPLETE_DELETE_PERMISSION_CATEGORY_SUCCESS:
    "COMPLETE_DELETE_PERMISSION_CATEGORY_SUCCESS",
  COMPLETE_DELETE_PERMISSION_CATEGORY_FAILURE:
    "COMPLETE_DELETE_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_PERMISSION_CATEGORY_REQUEST:
    "TRASH_ALL_PERMISSION_CATEGORY_REQUEST",
  TRASH_ALL_PERMISSION_CATEGORY_SUCCESS:
    "TRASH_ALL_PERMISSION_CATEGORY_SUCCESS",
  TRASH_ALL_PERMISSION_CATEGORY_FAILURE:
    "TRASH_ALL_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_PERMISSION_CATEGORY_REQUEST:
    "EMPTY_TRASH_PERMISSION_CATEGORY_REQUEST",
  EMPTY_TRASH_PERMISSION_CATEGORY_SUCCESS:
    "EMPTY_TRASH_PERMISSION_CATEGORY_SUCCESS",
  EMPTY_TRASH_PERMISSION_CATEGORY_FAILURE:
    "EMPTY_TRASH_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_PERMISSION_CATEGORY_REQUEST: "EXPORT_PERMISSION_CATEGORY_REQUEST",
  EXPORT_PERMISSION_CATEGORY_SUCCESS: "EXPORT_PERMISSION_CATEGORY_SUCCESS",
  EXPORT_PERMISSION_CATEGORY_FAILURE: "EXPORT_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_PERMISSION_CATEGORY_REQUEST:
    "GET_ALL_TRASHED_PERMISSION_CATEGORY_REQUEST",
  GET_ALL_TRASHED_PERMISSION_CATEGORY_SUCCESS:
    "GET_ALL_TRASHED_PERMISSION_CATEGORY_SUCCESS",
  GET_ALL_TRASHED_PERMISSION_CATEGORY_FAILURE:
    "GET_ALL_TRASHED_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_PERMISSION_CATEGORY_REQUEST: "ARCHIVE_PERMISSION_CATEGORY_REQUEST",
  ARCHIVE_PERMISSION_CATEGORY_SUCCESS: "ARCHIVE_PERMISSION_CATEGORY_SUCCESS",
  ARCHIVE_PERMISSION_CATEGORY_FAILURE: "ARCHIVE_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_PERMISSION_CATEGORY_REQUEST:
    "GET_ALL_ARCHIVED_PERMISSION_CATEGORY_REQUEST",
  GET_ALL_ARCHIVED_PERMISSION_CATEGORY_SUCCESS:
    "GET_ALL_ARCHIVED_PERMISSION_CATEGORY_SUCCESS",
  GET_ALL_ARCHIVED_PERMISSION_CATEGORY_FAILURE:
    "GET_ALL_ARCHIVED_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_REQUEST:
    "RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_REQUEST",
  RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_SUCCESS:
    "RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_SUCCESS",
  RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_FAILURE:
    "RECOVER_ALL_ARCHIVED_PERMISSION_CATEGORY_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_REQUEST",
  RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_PERMISSION_CATEGORY_BY_ID_FAILURE",
  /************************************************************************* */
};

export default PERMISSION_CATEGORY_ACTION_TYPES;
