const TASK_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_TASK_REQUEST: "GET_ALL_TASK_REQUEST",
  GET_ALL_TASK_SUCCESS: "GET_ALL_TASK_SUCCESS",
  GET_ALL_TASK_FAILURE: "GET_ALL_TASK_FAILURE",

  /************************************************************************* */
  GET_BY_ID_TASK_REQUEST: "GET_BY_ID_TASK_REQUEST",
  GET_BY_ID_TASK_SUCCESS: "GET_BY_ID_TASK_SUCCESS",
  GET_BY_ID_TASK_FAILURE: "GET_BY_ID_TASK_FAILURE",

  /************************************************************************* */
  CREATE_TASK_REQUEST: "CREATE_TASK_REQUEST",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",

  /************************************************************************* */
  UPDATE_TASK_REQUEST: "UPDATE_TASK_REQUEST",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",

  /************************************************************************* */
  DELETE_TASK_REQUEST: "DELETE_TASK_REQUEST",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_TASK_BY_ID_REQUEST: "RECOVER_BY_ID_TASK_BY_ID_REQUEST",
  RECOVER_TASK_BY_ID_SUCCESS: "RECOVER_TASK_BY_ID_SUCCESS",
  RECOVER_TASK_BY_ID_FAILURE: "RECOVER_TASK_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_TASK_REQUEST: "RECOVER_ALL_TASK_REQUEST",
  RECOVER_ALL_TASK_SUCCESS: "RECOVER_ALL_TASK_SUCCESS",
  RECOVER_ALL_TASK_FAILURE: "RECOVER_ALL_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_TASK_REQUEST: "COMPLETE_DELETE_TASK_REQUEST",
  COMPLETE_DELETE_TASK_SUCCESS: "COMPLETE_DELETE_TASK_SUCCESS",
  COMPLETE_DELETE_TASK_FAILURE: "COMPLETE_DELETE_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_TASK_REQUEST: "TRASH_ALL_TASK_REQUEST",
  TRASH_ALL_TASK_SUCCESS: "TRASH_ALL_TASK_SUCCESS",
  TRASH_ALL_TASK_FAILURE: "TRASH_ALL_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_TASK_REQUEST: "EMPTY_TRASH_TASK_REQUEST",
  EMPTY_TRASH_TASK_SUCCESS: "EMPTY_TRASH_TASK_SUCCESS",
  EMPTY_TRASH_TASK_FAILURE: "EMPTY_TRASH_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_TASK_REQUEST: "EXPORT_TASK_REQUEST",
  EXPORT_TASK_SUCCESS: "EXPORT_TASK_SUCCESS",
  EXPORT_TASK_FAILURE: "EXPORT_TASK_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_TASK_REQUEST: "GET_ALL_TRASHED_TASK_REQUEST",
  GET_ALL_TRASHED_TASK_SUCCESS: "GET_ALL_TRASHED_TASK_SUCCESS",
  GET_ALL_TRASHED_TASK_FAILURE: "GET_ALL_TRASHED_TASK_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_TASK_REQUEST: "ARCHIVE_TASK_REQUEST",
  ARCHIVE_TASK_SUCCESS: "ARCHIVE_TASK_SUCCESS",
  ARCHIVE_TASK_FAILURE: "ARCHIVE_TASK_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_TASK_REQUEST: "GET_ALL_ARCHIVED_TASK_REQUEST",
  GET_ALL_ARCHIVED_TASK_SUCCESS: "GET_ALL_ARCHIVED_TASK_SUCCESS",
  GET_ALL_ARCHIVED_TASK_FAILURE: "GET_ALL_ARCHIVED_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_TASK_REQUEST: "RECOVER_ALL_ARCHIVED_TASK_REQUEST",
  RECOVER_ALL_ARCHIVED_TASK_SUCCESS: "RECOVER_ALL_ARCHIVED_TASK_SUCCESS",
  RECOVER_ALL_ARCHIVED_TASK_FAILURE: "RECOVER_ALL_ARCHIVED_TASK_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_TASK_BY_ID_REQUEST: "RECOVER_ARCHIVED_TASK_BY_ID_REQUEST",
  RECOVER_ARCHIVED_TASK_BY_ID_SUCCESS: "RECOVER_ARCHIVED_TASK_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_TASK_BY_ID_FAILURE: "RECOVER_ARCHIVED_TASK_BY_ID_FAILURE",
  /************************************************************************* */
};

export default TASK_ACTION_TYPES;
