const SUPPLIER_ACTION_TYPES = {
  /************************************************************************* */
  GET_ALL_SUPPLIER_REQUEST: "GET_ALL_SUPPLIER_REQUEST",
  GET_ALL_SUPPLIER_SUCCESS: "GET_ALL_SUPPLIER_SUCCESS",
  GET_ALL_SUPPLIER_FAILURE: "GET_ALL_SUPPLIER_FAILURE",

  /************************************************************************* */
  GET_BY_ID_SUPPLIER_REQUEST: "GET_BY_ID_SUPPLIER_REQUEST",
  GET_BY_ID_SUPPLIER_SUCCESS: "GET_BY_ID_SUPPLIER_SUCCESS",
  GET_BY_ID_SUPPLIER_FAILURE: "GET_BY_ID_SUPPLIER_FAILURE",

  /************************************************************************* */
  CREATE_SUPPLIER_REQUEST: "CREATE_SUPPLIER_REQUEST",
  CREATE_SUPPLIER_SUCCESS: "CREATE_SUPPLIER_SUCCESS",
  CREATE_SUPPLIER_FAILURE: "CREATE_SUPPLIER_FAILURE",

  /************************************************************************* */
  UPDATE_SUPPLIER_REQUEST: "UPDATE_SUPPLIER_REQUEST",
  UPDATE_SUPPLIER_SUCCESS: "UPDATE_SUPPLIER_SUCCESS",
  UPDATE_SUPPLIER_FAILURE: "UPDATE_SUPPLIER_FAILURE",

  /************************************************************************* */
  DELETE_SUPPLIER_REQUEST: "DELETE_SUPPLIER_REQUEST",
  DELETE_SUPPLIER_SUCCESS: "DELETE_SUPPLIER_SUCCESS",
  DELETE_SUPPLIER_FAILURE: "DELETE_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_SUPPLIER_BY_ID_REQUEST: "RECOVER_BY_ID_SUPPLIER_BY_ID_REQUEST",
  RECOVER_SUPPLIER_BY_ID_SUCCESS: "RECOVER_SUPPLIER_BY_ID_SUCCESS",
  RECOVER_SUPPLIER_BY_ID_FAILURE: "RECOVER_SUPPLIER_BY_ID_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_SUPPLIER_REQUEST: "RECOVER_ALL_SUPPLIER_REQUEST",
  RECOVER_ALL_SUPPLIER_SUCCESS: "RECOVER_ALL_SUPPLIER_SUCCESS",
  RECOVER_ALL_SUPPLIER_FAILURE: "RECOVER_ALL_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  COMPLETE_DELETE_SUPPLIER_REQUEST: "COMPLETE_DELETE_SUPPLIER_REQUEST",
  COMPLETE_DELETE_SUPPLIER_SUCCESS: "COMPLETE_DELETE_SUPPLIER_SUCCESS",
  COMPLETE_DELETE_SUPPLIER_FAILURE: "COMPLETE_DELETE_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  TRASH_ALL_SUPPLIER_REQUEST: "TRASH_ALL_SUPPLIER_REQUEST",
  TRASH_ALL_SUPPLIER_SUCCESS: "TRASH_ALL_SUPPLIER_SUCCESS",
  TRASH_ALL_SUPPLIER_FAILURE: "TRASH_ALL_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EMPTY_TRASH_SUPPLIER_REQUEST: "EMPTY_TRASH_SUPPLIER_REQUEST",
  EMPTY_TRASH_SUPPLIER_SUCCESS: "EMPTY_TRASH_SUPPLIER_SUCCESS",
  EMPTY_TRASH_SUPPLIER_FAILURE: "EMPTY_TRASH_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  EXPORT_SUPPLIER_REQUEST: "EXPORT_SUPPLIER_REQUEST",
  EXPORT_SUPPLIER_SUCCESS: "EXPORT_SUPPLIER_SUCCESS",
  EXPORT_SUPPLIER_FAILURE: "EXPORT_SUPPLIER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_TRASHED_SUPPLIER_REQUEST: "GET_ALL_TRASHED_SUPPLIER_REQUEST",
  GET_ALL_TRASHED_SUPPLIER_SUCCESS: "GET_ALL_TRASHED_SUPPLIER_SUCCESS",
  GET_ALL_TRASHED_SUPPLIER_FAILURE: "GET_ALL_TRASHED_SUPPLIER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  ARCHIVE_SUPPLIER_REQUEST: "ARCHIVE_SUPPLIER_REQUEST",
  ARCHIVE_SUPPLIER_SUCCESS: "ARCHIVE_SUPPLIER_SUCCESS",
  ARCHIVE_SUPPLIER_FAILURE: "ARCHIVE_SUPPLIER_FAILURE",
  /************************************************************************* */

  /************************************************************************* */
  GET_ALL_ARCHIVED_SUPPLIER_REQUEST: "GET_ALL_ARCHIVED_SUPPLIER_REQUEST",
  GET_ALL_ARCHIVED_SUPPLIER_SUCCESS: "GET_ALL_ARCHIVED_SUPPLIER_SUCCESS",
  GET_ALL_ARCHIVED_SUPPLIER_FAILURE: "GET_ALL_ARCHIVED_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ALL_ARCHIVED_SUPPLIER_REQUEST:
    "RECOVER_ALL_ARCHIVED_SUPPLIER_REQUEST",
  RECOVER_ALL_ARCHIVED_SUPPLIER_SUCCESS:
    "RECOVER_ALL_ARCHIVED_SUPPLIER_SUCCESS",
  RECOVER_ALL_ARCHIVED_SUPPLIER_FAILURE:
    "RECOVER_ALL_ARCHIVED_SUPPLIER_FAILURE",
  /************************************************************************* */
  /************************************************************************* */
  RECOVER_ARCHIVED_SUPPLIER_BY_ID_REQUEST:
    "RECOVER_ARCHIVED_SUPPLIER_BY_ID_REQUEST",
  RECOVER_ARCHIVED_SUPPLIER_BY_ID_SUCCESS:
    "RECOVER_ARCHIVED_SUPPLIER_BY_ID_SUCCESS",
  RECOVER_ARCHIVED_SUPPLIER_BY_ID_FAILURE:
    "RECOVER_ARCHIVED_SUPPLIER_BY_ID_FAILURE",
  /************************************************************************* */
};

export default SUPPLIER_ACTION_TYPES;
